import * as types from 'actions/action-types';

var initialState = {

    error_log_save_pending: false,

    activity_log: [],
    activity_log_pending: false,

    activity_log_user: [],
    activity_log_user_pending: false,

    lightbox_show: false,
    lightbox_sources: [],

    flutter_keyboard: false,
    flutter_platform: 'ios',

    dashboard: 0,
    dashboard_counts: {},

    help_show: false,
    help_url: 'https://docusaurus.io/docs/creating-pages'
};

const adminReducer = (state = initialState, action) => {

    switch (action.type) {
		case types.ACTIVITY_LOG + '_CLEAR': state = { ...state, activity_log: [] }; break;
		case types.ACTIVITY_LOG + '_PENDING': pending('activity_log'); break;
		case types.ACTIVITY_LOG + '_FULFILLED': fulfilled('activity_log'); break;

		case types.ACTIVITY_LOG_USER + '_CLEAR': state = { ...state, activity_log_user: [] }; break;
		case types.ACTIVITY_LOG_USER + '_PENDING': pending('activity_log_user'); break;
		case types.ACTIVITY_LOG_USER + '_FULFILLED': fulfilled('activity_log_user'); break;

        case types.ERROR_LOG + '_SAVE_PENDING': save_pending('error_log'); break;
        case types.ERROR_LOG + '_SAVE_FULFILLED': save_fulfilled('error_log'); break;

        case types.LIGHTBOX_SHOW: set('lightbox_show'); break;
        case types.LIGHTBOX_SOURCES: set('lightbox_sources'); break;

        case types.HELP + '_SHOW': set('help_show'); break;
        case types.HELP + '_URL': set('help_url'); break;

        case types.FLUTTER_KEYBOARD: set('flutter_keyboard'); break;
        case types.FLUTTER_PLATFORM: set('flutter_platform'); break;

        case types.DASHBOARD + '_SELECTED': selected('dashboard'); break;
        case types.DASHBOARD_COUNTS + '_FULFILLED': fulfilled('dashboard_counts'); break;

        default: break;
    }

    return state;
    
    function set(table) {
        state = {
            ...state,
			[table]: action.data,
		};
	}
    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function selected(table) {
        state = {
            ...state,
            [table]: action.data,
        };
    }
};
export default adminReducer;


