import * as actions_invoices from 'actions/invoices-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useState } from 'react';
import ReceivePaymentForm from 'components/payments/payment-form/receive-payment-form';
import SendEmailForm from 'components/quotes/quote/send-email-form/send-email-form';
import { ADDRESSES, CONTACTS, ROLES } from 'components/constants';
import { CloseX, Ibox } from 'enspire-manager-framework';
import { Dropdown } from 'react-bootstrap';
import { numPanels } from 'components/common/toolbox';
import { toProperCase } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

var _ = require('lodash');
const moment = require('moment');

const PaymentDashboard = (props) => {

    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
    
    const params = useParams();
    const dispatch = useDispatch();

    const company = useSelector(store => store.company.company);
    const invoices = useSelector(store => store.invoices);
    const profiles = useSelector(store => store.profiles);
    const users = useSelector(store => store.users);

    const [state, setState] = useState({
        customer: {},
        selected_item: null,
        showEditPayment: false,
        showEmailForm: false
    });

    /* Handlers/Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleAction = (action) => {
        if (action == 'print') {
            createReceipt(null);
        } else if (action == 'email') {
            setState(prev => ({ ...prev, showEmailForm: true }));
        }
    };
    const sendEmail = (email) => {
        setState(prev => ({ ...prev, showEmailForm: false }));
        createReceipt(email);
    };
    const createReceipt = (email) => {
        setState(prev => ({ ...prev, rendering: true }));

        dispatch(actions_invoices.createPaymentReceiptPdf({
            handle: params.handle,
            shadeColor: '#116a75',
            profileId: params.customer_id,
            paymentId: params.payment_id,
            email: (!email) ? null : (email?.to?.length) ? email.to.map((to) => to.value) : [], //email.to,
            subject: (!email) ? null : email.subject,
            body: (!email) ? null : email.body,
            from: (company.email) ? company.companyName + ' <' + company.email + '>' : 'Mobile Track Systems <no-reply@mobiletrack.systems>',
        }, (result) => {
            setState(prev => ({ ...prev, rendering: false }));
            if (result) {
                if (!email) {
                    window.open(result.data.downloadUrl, '_blank');
                } else {
                    window.toastr.success('This Payment has been generated and emailed to ' + email.to.map((to) => to.value).join(', '), 'Payment Emailed!');
                }
            } else {
                // TODO error producing 
            }
        }));
    };

    /* Constants ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    var payment = invoices?.payments?.find((p) => p.id == params.payment_id);

    if (payment) {
        var payment_items = payment.serviceItems;
        var customer = profiles.profile;
        var memo = payment.memo;

        // Invoice ID, Invoice Date, Invoice Due Date
        var payment_due_date = (payment.dueDate?.seconds) ? moment(payment.dueDate?.seconds, 'X').format('MM/DD/YY') : null;
        var payment_date_created = moment((payment.date.seconds), 'X').format('MM/DD/YY');

        /*------------------------------------
                TOTALS MATH
        -------------------------------------*/
        var sub_total = 0;

        var paid = 0;

        var tax = 0;
        var tax_total = (sub_total * tax);
        var grand_total = (tax_total + sub_total);

        var totals = {
            sub_total: sub_total.toFixed(2),
            tax_total: (sub_total * tax).toFixed(2),
            grand_total: (tax_total + sub_total).toFixed(2),
            paid: paid.toFixed(2),
            balance: (grand_total - paid).toFixed(2)
        };

        /*------------------------------------
                CUSTOMER INFO
        -------------------------------------*/
        var billingAddress = _.find(customer?.addresses, { addressTypeId: ADDRESSES.BILLING.id });
        if (!billingAddress) billingAddress = customer?.addresses[0];

        var serviceAddress = _.find(customer?.addresses, { addressTypeId: ADDRESSES.SERVICE.id });
        
        var billingContact = _.find(customer?.contacts, { contactTypeId: CONTACTS.BILLING.id });
        if (!billingContact) billingContact = customer?.contacts[0];
    }

    /* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (

        <>
            {payment &&
                <>
                    { numPanels(1) && <CloseX onClick={() => { props.history.goBack(); }} style={{ right: '30px', marginTop: '20px' }} />}
                    <Ibox className="mt-3" title={'PAYMENT #' + payment.id} no_fade={false}>

                        <div className="row">
                            <div className="col-6 title">
                                RECEIPT
                                {payment?.quickbooksId && <img className='ml-3 pb-2' src={'images/QuickBooks-Logo.png'} style={{ width: '30px', height: 'auto', }} />}
                            </div>
                            <div className="col-6 d-flex justify-content-end" >
                                <div className="text-right mr-3">
                                    <div><span className="r-titles">DATE:</span></div>
                                    <div><span className="r-titles">PAYMENT:</span></div>
                                    <div><span className="r-titles">&nbsp;</span></div>
                                </div>
                                <div>
                                    <div><span className="r-titles-sub">{payment_date_created}<br /></span></div>
                                    <div><span className="r-titles-sub">#{payment.id}</span></div>
                                    <div><span className="r-titles-sub">&nbsp;</span></div>
                                </div>
                            </div>
                        </div>
                        <Dropdown className="d-inline mr-2">
                            <Dropdown.Toggle variant="primary" size="sm">
                                Payment Actions
                                {state.rendering &&
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1" onClick={handleAction.bind(this, 'print')}>Print</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={handleAction.bind(this, 'email')} disabled>Email</Dropdown.Item>
                                {users.user_permissions?.PAYMENTS_EDIT &&
                                    <Dropdown.Item eventKey="3" onClick={() => setState(prev => ({ ...prev, showEditPayment: true }))} disabled>Edit</Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="row spacer">&nbsp;</div>
                        <div className="row">
                            <div className="col-6">
                                <div className="title-bar">BILL TO: </div>
                                {billingContact &&
                                    <div className="p-2">
                                        <div>{billingContact.firstName + ' ' + billingContact.lastName}</div>
                                        <div>{billingAddress.addressLine1}</div>
                                        <div>{billingAddress.addressLine2}</div>
                                    </div>
                                }
                            </div>
                            <div className="col-6">
                                <div className="title-bar">SERVICE TO:</div>
                                {serviceAddress &&
                                    <div className="p-2">
                                        <div>{serviceAddress.addressName}</div>
                                        <div>{serviceAddress.addressLine1}</div>
                                        <div>{serviceAddress.addressLine2}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row spacer">&nbsp;</div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <table width="100%">
                                    <thead>
                                        <tr className="title-bar">
                                            <th>PAYMENT ID</th>
                                            <th>DATE</th>
                                            <th>AMOUNT</th>
                                            <th>METHOD</th>
                                            <th>INVOICE IDS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={payment.id} style={{ backgroundColor: '#dddddd', padding: '15px', }}>
                                            <td className="p-1">#{payment.id}</td>
                                            <td className="p-1">{payment_date_created}</td>
                                            <td className="p-1">${payment.amount}</td>
                                            <td className="p-1">{toProperCase(payment.method)}</td>
                                            <td className="p-1">{(payment.invoices.length) ? payment.invoices.map((i) => '#' + i.id).join(', ') : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end mt-4">
                                <div className="p-1">
                                    <table className="subtotal">
                                        <tbody>
                                            <tr>
                                                <td align="right" className="font-weight-bold">PAYMENT RECEIVED: </td>
                                                <td width="5"></td>
                                                <td align="right">{ toolbox.formatNumber(payment.amount, 2, true) }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Ibox>

                    {state.showEditPayment &&
                        <ReceivePaymentForm edit={true} setState={setState} cancel_button_callback={() => setState(prev => ({ ...prev, showEditPayment: false }))} />
                    }
                    {state.showEmailForm &&
                        <SendEmailForm
                            contacts={profiles.profile.contacts}
                            cancel_button_callback={() => setState(prev => ({ ...prev, showEmailForm: false }))}
                            submit_callback={sendEmail}
                            type={'receipt'}
                        />
                    }

                </>
            }
        </>
    );
};

export default PaymentDashboard;