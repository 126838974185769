import React, { useEffect, useState } from 'react';

export function Expanding(props) {

	useEffect(() => {
		setOpen(props.open);
	}, [props.open]);

	useEffect(() => {
		if (props.open) {
			setRender(true);
		} else {
			setTimeout(() => {
				setRender(false)
			}, 500);
		}
	}, [props.open]);

	const [open, setOpen] = useState(false);
	const [render, setRender] = useState(false);

	return (
		<>
			<div className="col-12" style={{ overflow: 'hidden' }}>
				<div className={ `expanding ${(open) ? 'open' : ''}` }>
					{ render &&
						<div>
							{ props.children }
						</div>
					}
				</div>
			</div>
		</>
	);
};
