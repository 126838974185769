import React, { useEffect, useState, useRef } from 'react';
import { imageSize, numPanels } from 'components/common/toolbox';

const ExpandDetails = (props) => {

	const [height, setHeight] = useState(0)
	const [limit, setLimit] = useState(props.default !== 'open');
    //     (props.default == 'closed') 
    //         ? true : (props.default == 'open')
    //         ? false : numPanels(1)
    // );
	const ref = useRef(null);
	const limitHeight = (props.limitHeight) ? props.limitHeight: 150;
    const top = (props.top) ? props.top : -24
    // const top = (height <= limitHeight) ? -24 : height - 35;

	useEffect(() => {
		setTimeout(() => {
			setHeight(ref.current?.clientHeight)
		}, 600);
	});
    
	useEffect(() => {
		if (typeof props.callback === 'function') {
            props.callback(limit)
        }
	}, [limit]);

	return (
        <div className="position-relative">
            { height >= limitHeight &&
                <>
                    <button 
                        className="btn btn-default btn-md position-absolute" 
                        style={{ top: top, right: 5, zIndex: 1, borderRadius: '25px' }}
                        onClick={ () => setLimit(!limit) }
                    >
                        { height == limitHeight
                            ?	<>
                                    { (props.more) ? <span className="mr-1">{ props.more }</span> : '' }
                                    <i className={ `fas ${(props.more) ? 'fa-angle-down' : 'fa-chevron-down'} text-muted`}></i>
                                </>
                            :	<>
                                    { (props.less) ? <span className="mr-1">{ props.less }</span> : '' }
                                    <i className={ `fas ${(props.less) ? 'fa-angle-up' : 'fa-chevron-up'} text-muted`}></i>
                                </>
                        }
                    </button>
                    { limit && height <= limitHeight &&
                        <button 
                            className="btn btn-default btn-md position-absolute" 
                            style={{ top: height - 5, left: '50%', transform: 'translate(-50%, 0)', zIndex: 1, borderRadius: '25px' }}
                            onClick={ () => setLimit(!limit) }
                        >
                            <i className="fas fa-ellipsis-h fa-xl text-muted"></i>
                        </button>
                    }
                </>
            }
            <div ref={ref} className={(limit && height == limitHeight)?'fade-bottom':''} style={{ 
                maxHeight: (limit) ? `${limitHeight}px` : '999px', 
                overflow: 'hidden', 
                position: 'relative', 
                transition: 'max-height 0.5s', 
                marginBottom: '3px', 
                marginLeft: '3px'
            }}>
                { props.children }
            </div>
        </div>

	);
}

export default ExpandDetails;

