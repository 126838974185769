import React from 'react';
import ResultsServiceRequests from '../components/results-service-requests';
import { APPS, COUNTS } from 'components/constants';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Expanding } from 'components/common/expanding';
import { Widget } from 'components/dashboard/components/widget';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ServiceRequestsTasks(props) {

	const params = useParams();

    const counts = useSelector((store) => store.admin.dashboard_counts);

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'unassigned-sr' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_REQUESTS_UNASSIGNED.id] ?? 0 : counts?.[COUNTS.SERVICE_REQUESTS_UNASSIGNED.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-folder-xmark fa-3x"></i> }
					text={ 'Unassigned Service Requests' }
					value="unassigned-sr"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'inprogress-sr' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_REQUESTS_INPROGRESS.id] ?? 0 : counts?.[COUNTS.SERVICE_REQUESTS_INPROGRESS.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-folder-grid fa-3x"></i> }
					text={ 'Service Requests in Progress' }
					value="inprogress-sr"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'completed-sr' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_REQUESTS_COMPLETED.id] ?? 0 : counts?.[COUNTS.SERVICE_REQUESTS_COMPLETED.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-folder-check fa-3x"></i> }
					// icon={ <i className="fa-duotone fa-solid fa-file-invoice-dollar fa-3x"></i> }
					text={ 'Completed Ready to Invoice' }
					value="completed-sr"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'invoiced-sr' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_REQUESTS_INVOICED.id] ?? 0 : counts?.[COUNTS.SERVICE_REQUESTS_INVOICED.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-invoice-dollar fa-3x"></i> }
					text={ 'Invoiced Service Requests' }
					value="invoiced-sr"
					onClick={ props.handleClick }
				/>
			</div>

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'unassigned-sr' }>
				<ResultsServiceRequests />
			</Expanding>
			<Expanding open={ params.widget_id == 'inprogress-sr' }>
				<ResultsServiceRequests />
			</Expanding>
			<Expanding open={ params.widget_id == 'completed-sr' }>
				<ResultsServiceRequests />
			</Expanding>
			<Expanding open={ params.widget_id == 'invoiced-sr' }>
				<ResultsServiceRequests />
			</Expanding>
			{/* <Expanding open={ params.widget_id == 'unassigned-sr' }>
				<div className="col p-1">
					<Widget
						active={ params.widget_id == 'sr-unassigned' }
						backgroundColor={ 'darkorange' }
						icon={ <i className="fas fa-times-circle fa-3x"></i> }
						text={ 'Create Service Request' }
						title={ <i className="fas fa-arrow-right"></i> }
						value="unassigned-sr"
						onClick={ props.handleClick }
					/>
				</div>
			</Expanding> */}
			{/* <Expanding open={ params.widget_id == 'invoice-sr' }>
				<div className="col p-1">
					<Widget
						active={ params.widget_id == 'sr-unassigned' }
						backgroundColor={ 'darkorange' }
						icon={ <i className="fas fa-times-circle fa-3x"></i> }
						text={ 'Service Requests Unassigned' }
						title={ <i className="fas fa-arrow-right"></i> }
						value="invoice-sr"
						onClick={ props.handleClick }
					/>
				</div>
			</Expanding> */}
		</div>
	);
};
