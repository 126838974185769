import React, { Fragment } from 'react';
import _ from 'lodash';
const moment = require('moment');

export function quote_fields() {
	return (
		{
			effective: null,
			days_to_expiration: 30,
			expiration: null,
			description: '',
			estimatorId: '',
			notes: '',
		}
	);
}

export function quote_form_layout(estimators, user_permissions) {

	const employee_options = _.orderBy(estimators, ['lastName'], ['asc']).map((employee, index) => {
		return (
			<option key={index} value={employee?.id}>{employee?.contact?.firstName + ' ' + employee?.contact?.lastName}</option>
		);
	});

	return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Quote Information</h3>
					</Fragment>,
				layout: [
					{
						grid: 'col-6',
						label: 'Effective Date',
						field: 'effective',
						type: 'date',
						dateFormat: 'MM-DD-YYYY',
						valid: ['required'],
					}, {
						grid: 'col-6',
						label: 'Duration',
						field: 'days_to_expiration',
						type: 'select',
						options:
							<Fragment>
								<option key={'30'} value={'30'}>30 days</option>
								<option key={'45'} value={'45'}>45 days</option>
								<option key={'60'} value={'60'}>60 days</option>
								<option key={'90'} value={'90'}>90 days</option>
								<option key={'120'} value={'120'}>120 days</option>
								<option key={'6'} value={'180'}>6 months</option>
								<option key={'12'} value={'365'}>12 months</option>
							</Fragment>,
					}, {
						grid: 'col-12',
						label: 'Description',
						field: 'description',
						type: 'textarea',
						rows: 6,
						valid: ['required'],
					}, {
						grid: 'col-12',
						label: 'Estimator',
						field: 'estimatorId',
						type: 'select',
						options:
							<Fragment>
								<option key={'none'} value={null}>- Select -</option>
								{employee_options}
							</Fragment>,
						readOnly: !user_permissions.ADMINISTRATOR,
					}, {
						grid: 'col-12',
						label: 'Notes (Private)',
						field: 'notes',
						type: 'textarea',
						rows: 6,
					}],
			},]
		}]
	);
}