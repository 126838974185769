import React, { Fragment } from 'react';
import StateOptions from 'components/common/state_options';
import { APPS, ADDRESSES, CONTACTS, CUSTOMERS } from 'components/constants';

export function customer_fields(activeAppId) {

	return (
		{
			'app-1': (activeAppId == '1'),
			'app-2': (activeAppId == '2'),
			displayName: '',
			customerTypeId: null,
			companyName: '',
			phone: '',
			fax: '',
			notes: '',
			website: '',
			// Service Address Info
			addressTypeId: ADDRESSES.BILLING.id,
			addressName: '',
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
			// Main Contact Info(Billing)
			contactTypeId: CONTACTS.BILLING.id,
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			contactPhone: '',
		}
	);
}

// RESIDENTIAL CUSTOMERS -------------------------------------------------------------

export function customer_form_layout({ company, customerTypeId, existing }) {

	var customer_type_options = Object.values(CUSTOMERS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var address_type_options = Object.values(ADDRESSES).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);
	var contact_type_options = Object.values(CONTACTS).map((type, index) => <option key={index} value={type.id}>{type.name}</option>);

	const residential = (customerTypeId == CUSTOMERS.RESIDENTIAL.id);

	var layout = [];
	if (existing) {
		layout.push({
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="mt-3 m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: 'text',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Customer Type',
					field: 'customerTypeId',
					type: 'select',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">Choose...</option>
							{customer_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Company Name',
					field: 'companyName',
					type: 'text',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: 'tel',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: 'text',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: 'checkbox',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: 'checkbox',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: 'textarea',
				}],
			}],
		});
		
	} else if (!existing) {
		layout.push({
			column_class: 'col-md-12',
			body: [{
				layout: [{

					// ----------------------------------------------------------------------------------
					// RESIDENTIAL ---------------------------------------------------------
					// ----------------------------------------------------------------------------------

					grid: 'col-12',
					component: <h3 className="mt-3 m-b">Contact</h3>,
					type: (residential) ? 'jsx' : 'skip',
				}, {
					grid: 'col-6',
					label: 'First Name',
					field: 'firstName',
					type: (residential) ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-6',
					label: 'Last Name',
					field: 'lastName',
					type: (residential) ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Contact Type',
					field: 'contactTypeId',
					type: (residential) ? 'select' : 'skip',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">Choose...</option>
							{contact_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Email',
					field: 'email',
					valid: ['email'],
					inputMode: 'email',
					type: (residential) ? 'text' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Mobile',
					field: 'mobile',
					valid: ['phone'],
					type: (residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Phone',
					field: 'contactPhone',
					valid: ['phone'],
					type: (residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-12',
					component: <h3 className="mt-3 m-b">Address</h3>,
					type: (residential) ? 'jsx' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Address Type',
					field: 'addressTypeId',
					type: (residential) ? 'select' : 'skip',
					valid: ['required'],
					options:
						<Fragment>
							{address_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Address Name',
					field: 'addressName',
					type: (residential) ? 'text' : 'skip',
				}, {
					grid: 'col-8',
					label: 'Address',
					field: 'addressLine1',
					type: (residential) ? 'location-autocomplete' : 'skip',
					prepend: <i className="fas fa-map-marker-alt"></i>,
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Secondary Address',
					field: 'addressLine2',
					type: (residential) ? 'text' : 'skip',
				}, {
					grid: 'col-4',
					label: 'City',
					field: 'city',
					type: (residential) ? 'text' : 'skip',
				}, {
					grid: 'col-4',
					label: 'State',
					field: 'state',
					type: (residential) ? 'select' : 'skip',
					options:
						<Fragment>
							<option value="">- Select -</option>
							<StateOptions />
						</Fragment>,
				}, {
					grid: 'col-4',
					label: 'Zip',
					field: 'zip',
					type: (residential) ? 'text' : 'skip',
				}, {
					grid: 'col-12',
					component: <h3 className="mt-3 m-b">Customer Information</h3>,
					type: (residential) ? 'jsx' : 'skip',
				}, {
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: (residential) ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-12',
					label: 'Company Name',
					field: 'companyName',
					type: (residential) ? 'text' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: (residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: (residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: (residential) ? 'text' : 'skip',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: (residential) ? 'checkbox' : 'skip',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: (residential) ? 'checkbox' : 'skip',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: (residential) ? 'checkbox' : 'skip',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: (residential) ? 'textarea' : 'skip',
				}, {

					// ----------------------------------------------------------------------------------
					// COMMERCIAL / PROPERTY MANAGEMENT
					// ----------------------------------------------------------------------------------

					grid: 'col-12',
					component: <h3 className="mt-3 m-b">Customer Information</h3>,
					type: (!residential) ? 'jsx' : 'skip',
				}, {
					grid: 'col-12',
					label: 'Display Name',
					field: 'displayName',
					type: (!residential) ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-12',
					label: 'Company Name',
					field: 'companyName',
					type: (!residential) ? 'text' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Phone',
					field: 'phone',
					valid: ['phone'],
					type: (!residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Fax',
					field: 'fax',
					valid: ['phone'],
					type: (!residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Website',
					field: 'website',
					inputMode: 'url',
					type: (!residential) ? 'text' : 'skip',
				}, ...[(company.appIds.includes(APPS.SERVICE.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.SERVICE.name,
					field: 'app-' + APPS.SERVICE.id,
					type: (!residential) ? 'checkbox' : 'skip',
				} : {}], {
				}, ...[(company.appIds.includes(APPS.ASSETS.id)) ? {
					grid: 'col-6 col-md-3 pt-1',
					label: APPS.ASSETS.name,
					field: 'app-' + APPS.ASSETS.id,
					type: (!residential) ? 'checkbox' : 'skip',
				} : {}], {
					grid: 'col-6 col-md-3 pt-1',
					label: 'Tax Exempt',
					field: 'taxExempt',
					type: (!residential) ? 'checkbox' : 'skip',
				}, {
					grid: 'col-12',
					label: 'Notes',
					field: 'notes',
					type: (!residential) ? 'textarea' : 'skip',
				}, {
					grid: 'col-12',
					component: <h3 className="mt-3 m-b">Address</h3>,
					type: (!residential) ? 'jsx' : 'skip',
				}, {
					grid: 'col-4',
					label: 'Address Type',
					field: 'addressTypeId',
					type: (!residential) ? 'select' : 'skip',
					valid: ['required'],
					options:
						<Fragment>
							{address_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Address Name',
					field: 'addressName',
					type: (!residential) ? 'text' : 'skip',
				}, {
					grid: 'col-8',
					label: 'Address',
					field: 'addressLine1',
					type: (!residential) ? 'location-autocomplete' : 'skip',
					prepend: <i className="fas fa-map-marker-alt"></i>,
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Secondary Address',
					field: 'addressLine2',
					type: (!residential) ? 'text' : 'skip',
				}, {
					grid: 'col-4',
					label: 'City',
					field: 'city',
					type: (!residential) ? 'text' : 'skip',
				}, {
					grid: 'col-4',
					label: 'State',
					field: 'state',
					type: (!residential) ? 'select' : 'skip',
					options:
						<Fragment>
							<option value="">- Select -</option>
							<StateOptions />
						</Fragment>,
				}, {
					grid: 'col-4',
					label: 'Zip',
					field: 'zip',
					type: (!residential) ? 'text' : 'skip',
				}, {
					grid: 'col-12',
					component: <h3 className="mt-3 m-b">Contact</h3>,
					type: (!residential) ? 'jsx' : 'skip',
				}, {
					grid: 'col-6',
					label: 'First Name',
					field: 'firstName',
					type: (!residential) ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-6',
					label: 'Last Name',
					field: 'lastName',
					type: (!residential) ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Contact Type',
					field: 'contactTypeId',
					type: (!residential) ? 'select' : 'skip',
					valid: ['required'],
					options:
						<Fragment>
							{contact_type_options}
						</Fragment>,
				}, {
					grid: 'col-8',
					label: 'Email',
					field: 'email',
					valid: ['email'],
					inputMode: 'email',
					type: (!residential) ? 'text' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Mobile',
					field: 'mobile',
					valid: ['phone'],
					type: (!residential) ? 'tel' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Phone',
					field: 'contactPhone',
					valid: ['phone'],
					type: (!residential) ? 'tel' : 'skip',
				}],
			}],
		});
	}

	return layout;
}
