import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import DashboardDetail from 'components/common/dashboard-detail';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import { APPS, ACTION, LOG, ROLES, WORKORDERS } from 'components/constants';
import { Avatar, CloseX, Select, Spinner } from 'enspire-manager-framework';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { imageSize, numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const _ = require('lodash');
const moment = require('moment');

export default function WorkOrderDashboard(props) {
    
    /* Hooks -------------------------------------------------------------------------------------------------------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();
    
    const seg = 3;
    
	const admin = useSelector((store) => store.admin);
    const assets = useSelector((store) => store.assets.assets);
	const departments = useSelector((store) => store.settings.departments);
    const employees = useSelector((store) => store.employees.employees);
    const service_request = useSelector((state) => state.serviceRequests.service_request);
	const userPermissions = useSelector(store => store.users?.user_permissions);
    const workOrders = useSelector((store) => store.workOrders);
    const work_order = useSelector((store) => store.workOrders.work_order);
    
	const [editTech, setEditTech] = useState(false);
	const [technicianId, setTechnicianId] = useState(null);
    
    const asset = assets.find((asset) => asset.id == work_order?.assetId);
    const technician = employees.find((employee) => employee.id == work_order?.technicianId);
    const pathname = routeLocation.pathname;
	const segments = pathname.split('/').length;
    const department = departments.find((o) => o.id == work_order?.departmentId);

    /* Effects -----------------------------------------------------------------------------------------------------------------------*/
    
    useEffect(() => {
        dispatch(actions_work_orders.subWorkOrderById(params.handle, params.work_order_id));
        dispatch(actions_work_orders.subServiceItemsByWorkOrderID(params.handle, params.work_order_id));
        return () => {
            var unsubscribe = workOrders.work_order_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
            var unsubscribe = workOrders.work_order_items_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.work_order_id]);

    useEffect(() => {
		dispatch(actions_profiles.subProfile(params.handle, work_order?.technicianId, 'EMPLOYEE'));
		return () => {
			let unsubscribe = employees.employee_unsubscribe;
			if (typeof unsubscribe === "function") unsubscribe();
		};
	}, [work_order?.technicianId]);

    useEffect(() => {
        setTechnicianId(work_order?.technicianId);
    }, [work_order]);

    useEffect(() => {
        if (work_order?.statusId == WORKORDERS.UNASSIGNED.id) {
            setEditTech(true);
        }
    }, [work_order?.statusId]);
    
    /* Handlers ----------------------------------------------------------------------------------------------------------------------*/

    const toggleEditTech = () => {
        if (work_order?.statusId != WORKORDERS.UNASSIGNED.id) setEditTech(!editTech);
    }
    const handleTechUpdate = (event) => {
        dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { technicianId: event.target.value}));
        setEditTech(false);

        const oldTech = employees.find((employee) => employee.id == work_order?.technicianId);
        const oldTechName = (oldTech) ? `${oldTech?.contact?.firstName} ${oldTech?.contact?.lastName}` : 'UNASSIGNED';
        const newTech = employees.find((employee) => employee.id == event.target.value);
        const newTechName = (newTech) ? `${newTech?.contact?.firstName} ${newTech?.contact?.lastName}` : 'UNASSIGNED';

        dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.MODIFIED.key, params.work_order_id, 
            `${oldTechName} to ${newTechName}`, work_order));
    }
    const handleStatus = (statusTable, statusId) => {
        dispatch(actions_settings.statusModalOpen(statusTable, statusId, work_order.id, onChangeStatus));
    };
    
    /* Actions ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const onChangeStatus = (statusId, reason) => {
        switch (statusId) {
            case WORKORDERS.ENROUTE.id:
            case WORKORDERS.ONSITE.id: {
                dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { statusId, reason: '' }, () => {
                    // if (user.profileId == work_order.technicianId) dispatch(actions_work_orders.workOrderCheckin(params.handle, work_order, user, statusId));
                    dispatch(actions_settings.statusModalClose());
                }));
                break;
            }
            case WORKORDERS.ASSIGNED.id:
            case WORKORDERS.INPROGRESS.id:
            case WORKORDERS.COMPLETE.id:
            case WORKORDERS.CLOSED.id: {
                dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { statusId, reason: '' }, () => {
                    // if (user.profileId == work_order.technicianId) dispatch(actions_work_orders.workOrderCheckin(params.handle, work_order, user, (statusId == WORKORDERS.ASSIGNED.id) ? statusId : '0'));
                    dispatch(actions_settings.statusModalClose());
                }));
                break;
            }
            case WORKORDERS.UNASSIGNED.id: {
                dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { statusId, reason: '', technicianId: 'unassigned' }, () => {
                    // if (user.profileId == work_order.technicianId) dispatch(actions_work_orders.workOrderCheckin(params.handle, work_order, user, '0'));
                    dispatch(actions_settings.statusModalClose());
                }));
                break;
            }
            case WORKORDERS.INCOMPLETE.id: {
                dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { statusId, reason }, () => {
                    // if (user.profileId == work_order.technicianId) dispatch(actions_work_orders.workOrderCheckin(params.handle, work_order, user, '0'));
                    dispatch(actions_settings.statusModalClose());
                }));
                break;
            }
        }
    };
    const openHelpPanel = () => {
        if (window.flutter) {
            window.flutter.callHandler('helpPanel', '/servicing-assets.md/work-orders#add-a-locale-dropdown'); 
        } else {
            dispatch(actions_admin.showHelp(true, '/servicing-assets.md/work-orders#add-a-locale-dropdown'));
        }
	}
	const toggleLightbox = () => {
		let imagesAlt = work_order.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(work_order.imageUrl, 'lg') },
			...(work_order.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}
    const openForm = () => {
        let location = toolbox.modifyPath(pathname, seg+2, 'work-order-form/' + params.work_order_id);
        props.history.push({ pathname: location });
    };
    const viewSchedule = () => {
        let newLocation = toolbox.modifyPath(routeLocation.pathname, 3, 'schedule/' + moment(work_order.startDate.seconds, 'X').format('YYYY-MM-DD') + '/' + work_order.technicianId, 5);
        history.push({ pathname: newLocation });
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const name = (technician) ? technician.contact?.firstName + ' ' + technician.contact?.lastName : 'Unassigned';

    var technicians = [];
	for (let employee of Object.values(employees)) {
		if (employee.roles?.includes(ROLES.TECHNICIAN.id)) technicians.push({ ...employee });
	}

    const technician_options = _.orderBy(technicians, ['lastName'], ['asc']).map((tech, index) => {
		return (
			<option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
		);
	});

    const statusTypes = (work_order?.offsite)
        ?   _.filter(Object.values(WORKORDERS), (o) => { return o.id != WORKORDERS.INPROGRESS.id })
        :   _.filter(Object.values(WORKORDERS), (o) => { return o.id != WORKORDERS.ENROUTE.id && o.id != WORKORDERS.ONSITE.id });

    const custAvatar = <Avatar
        className="float-right"
        color="white"
        bgColor="saddleBrown"
        size={35}
        fontSize={'10px'}
        name={(params.appId == APPS.SERVICE.id) ? service_request?._displayName : asset?.name }
        border="3px solid white"
    />

    const serviceAvatar = <Avatar
        className="float-right"
        color="white"
        bgColor="darkseagreen"
        size={35}
        fontSize={'10px'}
        name={service_request?.customId}
        initials={false}
        image={imageSize(service_request?.imageUrl, 'sm')}
        border="3px solid white"
    />

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (

		<div className="row">
            {work_order
                ? <>
                    <div className="position-absolute animated fadeIn animation-delay-3" style={{ top: '67px', left: '18px', zIndex: 100 }}>
                        { custAvatar }
                    </div>
                    <div className="position-absolute animated fadeIn animation-delay-4" style={{ top: '67px', left: '50px', zIndex: 100 }}>
                        { serviceAvatar }
                    </div>
                    <div id="workorder-avatar" className={ 'col-12 mb-3' }>
                        <div className="profile-image">
							<div className="mb-2">
                                <Avatar
                                    color="white"
                                    bgColor="steelblue"
                                    size={90}
                                    name={name}
                                    image={technician?.contact.photoUrl ? imageSize(technician?.contact.photoUrl, 'sm') : null}
                                    border='4px solid white'
                                />
                            </div>
                        </div>
                        <div className="profile-info pt-1 pr-2">
                            <div className="d-flex">
                                <h1 className="heading-work-order align-self-start" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>#{work_order.customId} Work Order</h1>
                                {segments < seg+10 && !window.flutter &&
                                    <div className={ 'animated fadeIn mt-n2 ml-3' } style={{ cursor: 'pointer', height: '10px' }} onClick={() => { history.goBack(); }}>
                                        <i className="fas fa-times" style={{ fontSize: '38px', color: '#bbbbbb' }}></i>
                                    </div>
                                }
                            </div>
                            { !editTech
                                ?   <div className="ml-2">
                                        <h1 className="mt-1 mb-0">{name}</h1>
                                        <h4>{technician?.contact?.email}</h4>
                                        <span className={ 'badge' }>{department?.value}</span>

                                    </div>
                                :   <Select
                                        className="col-12 pl-0 mt-3"
                                        name={technicianId}
                                        noLabel={true}
                                        onChange={handleTechUpdate}
                                        onBlur={toggleEditTech}
                                        value={technicianId}
                                    >
                                        <option value="unassigned">UNASSIGNED</option>
                                        { technician_options }
                                    </Select>
                            }
                        </div>
                    </div>

                    <StatusSelect className="ml-4"
                        selectedStatusId={work_order.statusId}
                        statusTable={'WORKORDERS'}
                        statusTypes={statusTypes}
                        // onClick={handleStatus}
                        static={true}
                    />

					<div className="col-12">
                        <ExpandDetails more="Show More">
                            <div id="work-order-details" className="mt-3 mb-2">
                                <DashboardDetail field={ custAvatar } value={service_request?._displayName} />
                                <DashboardDetail field={ serviceAvatar } value={service_request?.description} />
                                <DashboardDetail field={ 'Start Date' } value={ moment(work_order.startDate?.seconds, 'X').format('MMM DD, YYYY h:mm A') } />
                                <DashboardDetail field={ 'Description' } value={ work_order.description } type="text" />
                                <DashboardDetail field={ 'End Date' } value={ moment(work_order.endDate?.seconds, 'X').format('MMM DD, YYYY h:mm A') } />
                                <DashboardDetail field={ 'Suggested' } value={ work_order.suggested } type="text" />
                                <DashboardDetail field={ 'Reason' } value={ work_order.reason } />
                                { !numPanels(1) &&
                                    <DashboardDetail field={ ' ' } value={<><button className="btn btn-info btn-sm" onClick={viewSchedule}>View on Schedule</button></>} />
                                }
                                <div className="clearfix"></div>
                            </div>
                        </ExpandDetails>

                        <div className="mt-3">
                            <DropdownButton 
                                title="Work Order Actions &nbsp;" 
                                variant="primary" size="sm" className="d-inline ml-2 mb-2"
                                onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                            >
                                <Dropdown.Item eventKey="1" onClick={ toggleEditTech } disabled={!userPermissions?.WORK_ORDERS_EDIT}>
                                    <><i className="fas fa-exchange-alt pr-1"></i> { `${((technician)?'Reassign':'Assign')} Technician` }</>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={ () => { onChangeStatus(WORKORDERS.COMPLETE.id) } }>
                                    <><span style={{ color: WORKORDERS.COMPLETE.color }}><i className="fa fa-circle"></i></span> &nbsp; {'Mark Complete'} </>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={ () => { handleStatus('WORKORDERS', WORKORDERS.INCOMPLETE.id) } }>
                                    <><span style={{ color: WORKORDERS.INCOMPLETE.color }}><i className="fa fa-circle"></i></span> &nbsp; {'Mark Incomplete'} </>
                                </Dropdown.Item>
                            </DropdownButton>
                            <div className="float-right">
                                <button type="button" 
                                    className={'btn btn-default btn-sm mr-2' }
                                    disabled={!userPermissions.WORK_ORDERS_EDIT}
                                    onClick={openForm}>Edit Work Order
                                </button>
                            </div>
                        </div>
					</div>
                </>
                : <Spinner center />
            }
        </div>
    );
}
