import * as toolbox from 'components/common/toolbox';
import ActivityLog from 'components/activity-log/activity-log';
import AttachmentsTab from 'components/attachments/attachments-tab';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import InvoicesTab from 'components/invoices/invoices-tab';
import PaymentsTab from 'components/payments/payments-tab';
import QuotesTab from 'components/quotes/quotes-tab';
import React, { useEffect } from 'react';
import WorkOrdersTab from 'components/work-orders/work-orders-tab';
import firebase from 'firebase/compat/app';
import { LOG, PERMISSIONS, SERVICE_REQUEST_TERMS, QUOTES } from 'components/constants';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams, useRouteMatch, Route, Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const firestore = firebase.firestore();

export default function ServiceRequestTabs(props) {
	
	/* Handlers --------------------------*/
	
	const seg = 9;
	const match = useRouteMatch();
    const params = useParams();
	const history = useHistory();
	const routeLocation = useLocation();

	const service_request = useSelector((store) => store.serviceRequests.service_request);

	// If tab is not specified, select the first appropriate.
	useEffect(() => {
		const checkQuotes = async () => {
			const quoteDocs = await firestore.collection(params.handle + '/quotes/quotes')
				.where('serviceRequestId', '==', service_request.id)
				.where('statusId', '==', QUOTES.PENDING.id)
				.where('deleted', '==', false).get();
			var pendingQuotes = [];
			quoteDocs.forEach((doc) => {
				pendingQuotes.push({ ...doc.data(), id: doc.id });
			});

			const tab = (pendingQuotes.length) ? 'quotes' : 'work_orders';

			let location = toolbox.modifyPath(routeLocation?.pathname, seg, tab, seg);
			history.replace({ pathname: location });	
		}
		
		if (params.tab == 'tab' && service_request?.id) checkQuotes();
	}, [params.tab, service_request]);

	function tab_active(value) {
		return (params.tab === value) ? ' active' : ''; // returns 'active' if value tab is active
	}
	function tab_url(value) {
		return toolbox.modifyPath(props.location.pathname, seg, value, seg);
	}

	return (
		<Route path={match.path} render={(route_props) =>

			<div className="tabs-container my-4 position-relative">
				<ul className={ `nav nav-tabs underline ${(numPanels(1))?'scroll-tabs':''}` } role="tablist">
					<Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW }>
						<li><Link className={'nav-link' + tab_active('work_orders')} to={tab_url('work_orders')} replace={true}>Work Orders</Link></li>
					</Authorize>
					<Authorize perm={ PERMISSIONS.QUOTES_VIEW }>
						<li><Link className={'nav-link' + tab_active('quotes')} to={tab_url('quotes')} replace={true}>Quotes</Link></li>
					</Authorize>
					<Authorize perm={ PERMISSIONS.INVOICES_VIEW }>
						<li><Link className={'nav-link' + tab_active('invoices')} to={tab_url('invoices')} replace={true}>Invoices</Link></li>
					</Authorize>
					<Authorize perm={ PERMISSIONS.PAYMENTS_VIEW }>
						<li><Link className={'nav-link' + tab_active('payments')} to={tab_url('payments')} replace={true}>Payments</Link></li>
					</Authorize>
					<Authorize perm={ PERMISSIONS.ATTACHMENT_VIEW }>
						<li><Link className={'nav-link' + tab_active('attachments')} to={tab_url('attachments')} replace={true}>Attachments</Link></li>
					</Authorize>
					<Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
						<li><Link className={'nav-link' + tab_active('activity')} to={tab_url('activity')} replace={true}>Activity</Link></li>
					</Authorize>
				</ul>

				<div className="tab-content">

					<Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW }>
						<div role="tabpanel" className={'tab-pane' + tab_active('work_orders')}>
							<div className="panel-body">
								<Route path={match.path} render={(route_props) =>
									<CatchErrors>
										<WorkOrdersTab {...route_props} source={ props.source} />
									</CatchErrors>
								} />
							</div>
						</div>
					</Authorize>
					<Authorize perm={ PERMISSIONS.QUOTES_VIEW }>
						<div role="tabpanel" className={'tab-pane' + tab_active('quotes')}>
							<div className="panel-body">
								<Route path={match.path} render={(route_props) =>
									<CatchErrors>
										<QuotesTab {...route_props} source="service-requests" />
									</CatchErrors>
								} />
							</div>
						</div>
					</Authorize>
					<Authorize perm={ PERMISSIONS.INVOICES_VIEW }>
						<div role="tabpanel" className={'tab-pane' + tab_active('invoices')}>
							<div className="panel-body">
								<Route path={match.path + '/:invoice_id?'} render={(route_props) =>
									<CatchErrors>
										<InvoicesTab {...route_props}
											prefix="SERVICE_REQUEST" 
											singular='service_request' 
											source='service-requests' 
                                            store="serviceRequests" 
										/>
									</CatchErrors>
								} />
							</div>
						</div>
					</Authorize>
					<Authorize perm={ PERMISSIONS.PAYMENTS_VIEW }>
						<div role="tabpanel" className={'tab-pane' + tab_active('payments')}>
							<div className="panel-body">
								<Route path={match.path + '/:payment_id?'} render={(route_props) =>
									<CatchErrors>
										<PaymentsTab {...route_props} source='service-requests' />
									</CatchErrors>
								} />
							</div>
						</div>
					</Authorize>
					<Authorize perm={ PERMISSIONS.ATTACHMENT_VIEW }>
						<div role="tabpanel" className={'tab-pane' + tab_active('attachments')}>
							<div className="panel-body">
								<Route path={match.path} render={(route_props) =>
									<CatchErrors>
										<AttachmentsTab {...route_props} 
											columnId={ (props.source == 'service')? "column2" : "column3"}
                                            id={ params.request_id } 
                                            prefix="SERVICE_REQUEST"
                                            singular="service_request" 
                                            source='service-requests' 
                                            store="serviceRequests" 
                                        />
									</CatchErrors>
								} />
							</div>
						</div>
					</Authorize>
					<Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
						<div role="tabpanel" className={'tab-pane' + tab_active('activity')}>
							<div className="panel-body">
								<Route path={props.match.path} render={(route_props) =>
									<CatchErrors>
										<ActivityLog {...route_props} 
											id="request_id" 
											prefix="SERVICE_REQUEST" 
											singular="service_request"
											store="serviceRequests" 
											type={ `${LOG.SERVICE_REQUEST.key}` } 
										/>
									</CatchErrors>
								} />
							</div>
						</div>
					</Authorize>
				</div>
			</div>
		} />
	);
};