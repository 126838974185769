import * as actions_archive from 'actions/archive-actions';
import React, { useEffect } from 'react';
import { Ibox, ModalAlert, Table } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function ArchiveSearch(props) {

    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const params = useParams();
    const dispatch = useDispatch();

    const archive = useSelector((store) => store.archive);
    const user_permissions = useSelector((store) => store.users?.user_permissions ?? {});

    const archivedAssetTypes = archive.archived_asset_types?.map((assetType) => {
        return ({ ...assetType, type: 'ASSET_TYPE', name: assetType.value })
    });
    const archivedAssets = archive.archived_assets?.map((asset) => {
        return ({ ...asset, type: 'ASSET', name: asset.name })
    });
    const archivedCustomers = archive.archived_profiles?.filter((o) => o.type == 'customer').map((profile) => {
        return ({ ...profile, type: 'CUSTOMER', name: profile.displayName })
    });
    const archivedEmployees = archive.archived_profiles?.filter((o) => o.type == 'employee').map((profile) => {
        let contact = (profile.contacts && profile.contacts != {}) ? Object.values(profile.contacts)?.[0] : {};	
        return ({ ...profile, type: 'EMPLOYEE', name: (contact?.firstName || contact?.lastName) ? `${contact?.firstName} ${contact?.lastName}` : 'Unknown' })
    });
    const archivedVendors = archive.archived_profiles?.filter((o) => o.type == 'vendor').map((profile) => {
        return ({ ...profile, type: 'VENDOR', name: profile.displayName })
    });
    const archivedStockParts = archive.archived_inventory?.map((stockPart) => {
        return ({ ...stockPart, type: 'STOCK_PART', name: stockPart.name })
    });
    const archivedServiceRequests = archive.archived_service_requests?.map((serviceRequest) => {
        return ({ ...serviceRequest, type: 'SERVICE_REQUEST', name: serviceRequest.description })
    });
    const archivedQuotes = archive.archived_quotes?.map((quote) => {
        return ({ ...quote, type: 'QUOTE', name: quote.description })
    });

    const archived = []
        .concat(archivedAssetTypes)
        .concat(archivedAssets)
        .concat(archivedCustomers)
        .concat(archivedEmployees)
        .concat(archivedStockParts)
        .concat(archivedServiceRequests)
        .concat(archivedVendors)
        .concat(archivedQuotes);

    /* Effects ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        dispatch(actions_archive.subArchivedItems(params.handle, 'ARCHIVED_ASSET_TYPES', '/settings/assetTypes'));
        dispatch(actions_archive.subArchivedItems(params.handle, 'ARCHIVED_ASSETS', '/assets/assets'));
        dispatch(actions_archive.subArchivedItems(params.handle, 'ARCHIVED_PROFILES', '/profiles/profiles'));
        dispatch(actions_archive.subArchivedItems(params.handle, 'ARCHIVED_INVENTORY', '/stock-parts/stock-parts'));
        dispatch(actions_archive.subArchivedItems(params.handle, 'ARCHIVED_SERVICE_REQUESTS', '/service-requests/service-requests'));
        dispatch(actions_archive.subArchivedItems(params.handle, 'ARCHIVED_QUOTES', '/quotes/quotes'));

        return () => {
            var unsubscribe = archive.archived_asset_types_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            var unsubscribe = archive.archived_assets_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            var unsubscribe = archive.archived_profiles_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            var unsubscribe = archive.archived_inventory_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            var unsubscribe = archive.archived_service_requests_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();

            var unsubscribe = archive.archived_quotes_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();
        };
    }, []);


    /* Handlers/Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const restoreItem = async (item) => {

        ModalAlert({
            title: 'Are you sure?',
            text: "This item will be restored and will appear in the appropriate Search",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, restore it!',
            callback_success: () => {

                const type = item.type;

                switch (type) {
                    case 'ASSET_TYPE': dispatch(actions_archive.restoreItem(params.handle, item, type, 'Asset Type', '/settings/assetTypes')); break;
                    case 'ASSET': dispatch(actions_archive.restoreItem(params.handle, item, type, 'Asset', '/assets/assets')); break;
                    case 'CUSTOMER': dispatch(actions_archive.restoreProfile(params.handle, item, type)); break;
                    case 'EMPLOYEE': dispatch(actions_archive.restoreProfile(params.handle, item, type)); break;
                    case 'STOCK_PART': dispatch(actions_archive.restoreItem(params.handle, item, type, 'Stock Part', '/stock-parts/stock-parts')); break;
                    case 'SERVICE_REQUEST': dispatch(actions_archive.restoreItem(params.handle, item, type, 'Service Request', '/service-requests/service-requests')); break;
                    case 'VENDOR': dispatch(actions_archive.restoreProfile(params.handle, item, type)); break;
                    case 'QUOTE': dispatch(actions_archive.restoreItem(params.handle, item, type, 'Quote', '/quotes/quotes')); break;
                }                    
            }
        });
    };
    const deleteItem = (type, name, item) => {

        // var text;
        // if (type == 'customer') text = "This Customer will be PERMANENTLY DELETED along with all associated SERVICE REQUESTS, WORK ORDERS, INVOICES and ATTACHMENTS!";
        // if (type == 'service-request') text = "This Service Request will be PERMANENTLY DELETED along with all associated WORK ORDERS, and ATTACHMENTS!";

        // ModalAlert({
        //     title: 'Are you sure?',
        //     text: text,
        //     icon: 'error',
        //     input: 'text',
        //     label: 'To confirm, type "PERMANENTLY DELETE"',
        //     validator: (value) => {
        //         if (value != 'PERMANENTLY DELETE') return 'Phrase Not Correct';
        //     },
        //     show_cancel: true,
        //     confirm_color: '#ED5565',
        //     confirm_text: 'Yes, PERMANENTLY DELETE!',
        //     callback_success: () => {
        //         if (type == 'customer') dispatch(actions_archive.deleteProfile(params.handle, item.id, 'CUSTOMER', null));
        //         if (type == 'employee') dispatch(actions_archive.deleteProfile(params.handle, item.id, 'EMPLOYEE', item));
        //         if (type == 'service-item') dispatch(actions_archive.deleteServiceItem(params.handle, item.id));
        //         if (type == 'work-order') dispatch(actions_archive.deleteWorkOrder(params.handle, item.id));
        //     }
        // });
    };

    /* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (

        <Ibox className="mt-3" title={'Archived Items'} no_fade={true}>
            <Table
                data={archived}
                show_limit={false}
                show_search={true}
                highlight_search={true}
                group_by={{ fields: ['type'], direction: ['asc'], display: ['type'], collapsible: true, }}
                columns={[
                    { name: 'Name', field: 'name', width: 45 },
                    { name: 'Archived', field: 'modified', type: 'date', format: 'MMM Do, YYYY h:mm a', width: 25 },
                    ...[ (user_permissions.ARCHIVES_RESTORE) 
                        ? { name: 'Restore', field: 'id', type: 'button', button: { name: 'Restore', className: 'btn-primary btn-xs' }, callback: restoreItem, width: 25 } : {}
                    ],
                    ...[ (user_permissions.ARCHIVES_DELETE) 
                        ? { name: '', field: 'id', type: 'button', button: { name: <i className="far fa-trash-alt"></i>, className: 'btn-default btn-xs' }, callback: deleteItem, width: 5 } : {}
                    ],
                ]}
            />
        </Ibox>
    );
}
