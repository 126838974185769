import React, { useEffect, useState, useRef } from 'react';

const moment = require('moment');

export default function DashboardDetail(props) {

	const [height, setHeight] = useState(0)
	const [limit, setLimit] = useState(true);
	const ref = useRef(null);
	const limitHeight = 95;

	useEffect(() => {
		setTimeout(() => {
			setHeight(ref.current?.clientHeight)
		}, 1000);
	})

	let role = (
		props.type == 'phone' || 
		props.type == 'email' || 
		props.type == 'url' ||
		props.type == 'link' ||
		props.type == 'edit'
	) ? 'button' : null;
	
	let onclick = (props.type == 'phone')
		? 	() => window.location.href='tel:' + props.value?.replace(/\D/g, "")
		:	(props.type == 'email') 
			? 	() => window.location.href='mailto:' + props.value
			:	(props.type == 'url') 
				? 	() => window.open(props.value?.replace(/^(https?:\/\/)?/i, (a)=>a || 'http://'))
				:	(props.type == 'link' || props.type == 'edit') 
					? 	props.callback
					: 	null;

	const formatNumber = (amount, decimalCount = 2, usd = false) => {

		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
	
		const negativeSign = amount < 0 ? "-" : "";
	
		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;
	
		return ((usd) ? '$':'') + negativeSign + (j ? i.substr(0, j) + ',' : '') + (i.substr(j).replace(/(\d{3})(?=\d)/g, "$1,")) + (decimalCount ? '.' + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	}
	
	var value;
	switch(props.type) {
		case 'date':
			var isValid = moment(props.value).isValid();
			value = (isValid) ? moment(props.value).format(props.format) : null;
			break;
		case 'moment':
			var isValid = props.value.isValid();
			value = (isValid) ? props.value.format(props.format) : null;
			break;
		case 'usd':
			value = formatNumber(props.value, 2, true);
			break;
		default:
			value = (typeof props.value == 'string') ? props.value?.trim() : props.value;
	}

	const DetailRow = 
		<div className="details-row align-items-stretch" role={ role } onClick={ onclick } style={ (props.type != 'text') ? { marginBottom: '3px', marginLeft: '3px' } : {}}>
			{ props.icon &&
				<div className="details-icon">{ <i className={ props.icon}></i> }</div>
			}
			{ props.field &&
				<div className="details-field">{ props.field }</div>
			}
			<div className="details-gap"></div>
			<div className={'details-value d-flex justify-content-between align-items-center ' + ((height > limitHeight) ? 'mb-3' : '')}>{value}
				{ props.type == 'phone' &&
					<i className="text-muted fas fa-phone"></i>
				}
				{ props.type == 'email' &&
					<i className="text-muted fas fa-envelope"></i>
				}
				{ props.type == 'url' &&
					<i className="text-muted fas fa-external-link-alt"></i>
				}
				{ props.type == 'link' &&
					<i className="text-muted fas fa-arrow-right"></i>
				}
				{ props.type == 'edit' &&
					<i className="text-muted fas fa-pencil-alt"></i>
				}
			</div>
		</div>

	return (

		<>
			{ value && 
				<>
					{ props.type == 'text'
						?	<div ref={ref} style={{ 
								maxHeight: (limit) ? `${limitHeight}px` : '9999px', 
								overflow: 'hidden', 
								position: 'relative', 
								transition: 'max-height .5s', 
								marginBottom: '3px', 
								marginLeft: '3px'
							}}>
								{ DetailRow }
								{ height >= limitHeight &&
									<button 
										className="btn btn-light btn-sm position-absolute" 
										style={{ top: height - 35, right: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
										onClick={ () => setLimit(!limit) }
									>
										{ height == limitHeight
											?	<i className="fas fa-chevron-down"></i>
											:	<i className="fas fa-chevron-up"></i>
										}
									</button>
								}
							</div>
						: 	<>{ DetailRow }</>
					}
				</>
			}
		</>
	);
}

