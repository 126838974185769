import * as actions_quotes from 'actions/quotes-actions';
import * as toolbox from 'components/common/toolbox';
import LineItemsAccordion from 'components/line-items/line-items-accordion';
import React, { useEffect, useState } from 'react';
import ServiceItemsOption from 'components/line-items/service-items-option';
import _ from 'lodash';
import { Ibox, ModalAlert, Spinner } from 'enspire-manager-framework';
import { QUOTES } from 'components/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';

export default function QuoteOptions(props) {

    /* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    var dispatch = useDispatch();
    const history = useHistory();

    const quotes = useSelector((state) => state.quotes);
    const quote = quotes?.quote;

    const validLink = (quote?.validateSignature == params.validateSignature && params.validateSignature > Date.now())

    /* Effects ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    // Subscribe to quote
    useEffect(() => {
        dispatch(actions_quotes.subQuoteById(params.handle, params.quote_id));

        return () => {
            var unsubscribe = quotes.quote_unsubscribe;
            if (typeof unsubscribe == 'function') unsubscribe();
        };
    }, []);

    /* Handlers/Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleApproval = (option) => {
        history.push({ pathname: toolbox.modifyPath(history.location.pathname, 3, `signature/quote/${params.quote_id}/${option}/${params.email}/${params.validateSignature}`) });
    };

    const handleDecline = () => {
        ModalAlert({
            title: 'Are you sure?',
            text: "Declining the Quote will halt further progress on your service",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Decline Quote',
            callback_success: () => { 
                dispatch(actions_quotes.updateQuote(params.handle, params.quote_id,  { statusId: QUOTES.REJECTED.id }));
            }
        });    
    };

    /* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        
        <div id="signature-body" >
            <div className="row">
                <div className="col-12">
                    
                    <Ibox className='container' title={`Quote #${quote?.id} ${(quote?.options?.length > 1) ? "Options" : ''}`}>
                        <div style={{ color: '#666666', maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>

                            { (quotes?.quote_pending || quotes?.quote_save_pending) && 
                                <Spinner />
                            }
                            { quote?.statusId == QUOTES.REJECTED.id && 
                                <h1 className="mt-5 text-center font-weight-bold mb-5">
                                    <i className="fa-regular fa-circle-xmark fa-3x mb-5 text-danger"></i><br/>
                                    Quote Declined!  <br/><br/><small className="font-weight-lighter">Please call our office if there's anything else we can do to serve you!</small>
                                </h1>
                            }
                            { (quote?.statusId != QUOTES.PENDING.id && quote?.statusId != QUOTES.REJECTED.id) &&
                                <h1 className="mt-5 text-center font-weight-bold mb-5">
                                    <i className="fa-regular fa-clock fa-3x mb-5 text-info"></i><br/>
                                    Not Ready for Approval <br/><br/><small className="font-weight-lighter">This Quote cannot be Approved / Rejected at this time</small>
                                </h1>
                            }
                            {/* { (quote.statusId === QUOTES.CHANGES_REQUESTED.id) &&
                                <h1>Thank you for your Requested Changes!  You will hear from us shortly</h1>
                            } */}
                            { quote?.statusId == QUOTES.PENDING.id && !validLink && 
                                <h1 className="mt-5 text-center font-weight-bold mb-5">
                                    <i className="fa-regular fa-circle-exclamation fa-3x mb-5 text-info"></i><br/>
                                    Invalid or Expired Link  <br/><br/><small className="font-weight-lighter">Please call our office to request an updated link!</small>
                                </h1>
                            }
                            { (quote?.statusId == QUOTES.PENDING.id && validLink && quote?.options?.length > 1) &&
                                <>
                                    <LineItemsAccordion options={quote?.options} handleApproval={handleApproval} />
                                    <div className='d-flex justify-content-end'>
                                        <button className={'mt-3 btn btn-danger'} onClick={handleDecline}>DECLINE ALL OPTIONS</button>
                                    </div>
                                </>
                            }
                            { (quote?.statusId == QUOTES.PENDING.id && validLink && (quote?.options?.length <= 1)) &&
                                <div className='row'>
                                    <div className='col-12'>
                                        <ServiceItemsOption option={quote?.options?.[0]} />
                                    </div>
                                    <div className='col-12'>
                                        <div className='d-flex mt-3'>
                                            <button className="btn btn-lg btn-danger col flex-grow-0" onClick={handleDecline}>DECLINE</button>
                                            <button className="btn btn-lg btn-success ml-2 col flex-grow-1" onClick={() => handleApproval(0)}>APPROVE QUOTE</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Ibox>

                </div>
            </div>
        </div>
    );
};
