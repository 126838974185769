import React, { Fragment } from 'react';
import { LocationAlpha, LocationNumeric } from 'components/common/location-dropdowns';
import { SERVICE_ITEMS, UNITS } from 'components/constants';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function add_item_fields() {

	return ({});
}
export function add_item_form_layout(typeId) {

	var layout = [{

		block: [{
			column_class: 'col-md-12 mt-3',
			body: [{
				layout: [{
					grid: 'col-6',
					label: 'Average Cost',
					field: 'averageCost',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					readOnly: true,
					type: (typeId != 'labor' && typeId != 'travel') ? 'text' : 'skip',
				}, {
					grid: 'col-3',
					label: 'Inventory',
					field: 'inventory',
					readOnly: true,
					type: (typeId != 'labor' && typeId != 'travel') ? 'text' : 'skip',
				}, {
					grid: 'col-3',
					label: 'Pending',
					field: 'pendingInventory',
					readOnly: true,
					type: (typeId != 'labor' && typeId != 'travel') ? 'text' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Price Override',
					field: 'priceOverride',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					type: (typeId != 'labor' && typeId != 'travel') ? 'usd' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Price',
					field: 'price',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					type: (typeId == 'labor' || typeId == 'travel') ? 'usd' : 'skip',
				}, {
					grid: 'col-6',
					label: 'Count',
					field: 'count',
					valid: ['numeric', 'required'],
					inputMode: 'decimal',
					type: 'text',
				}, {
					grid: 'col-12',
					label: 'Description',
					field: 'description',
					type: 'textarea',
				}],
			}],
		}]
	}];
	
	return layout;
}