import * as actions_invoices from 'actions/invoices-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import StatusKey from 'components/common/status-key';
import { Ibox, Table, ModalAlert } from 'enspire-manager-framework';
import { PAYMENTS } from 'components/constants';
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';

var _ = require('lodash');

export default function Payments(props) {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const history = useHistory();
    const routeLocation = useLocation();
    const dispatch = useDispatch();
    const params = useParams();

    const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
    const invoices = useSelector((store) => store.invoices);
    const quotes = useSelector((store) => store.quotes);
    const taxRates = useSelector((store) => store.settings.settings?.invoices?.tax_rates);
    const user_permissions = useSelector((store) => store.users?.user_permissions ?? {});
    
    const [timer, setTimer] = useState(null);
    const [canAuto, setCanAuto] = useState(true);
    
    const payments = invoices?.payments;
    const seg = (props?.source == 'customers') ? 7 : (props?.source == 'invoices') ? ((params.request_id) ? 12 : 10) : 9;
    const segments = props.location.pathname.split('/').length;

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    // Subscribe by profileId for Customer Dashboard, requestId for Service Request, invoiceId for Invoice
    useEffect(() => {
        dispatch(actions_invoices.subPaymentsByField(params.handle,
            (props?.source != 'service-requests')
                ? 'customerId'
                : 'requestId',
            (props?.source != 'service-requests')
                ? params.profile_id
                : params.request_id,
        ));

        if (props?.source == 'service-requests') {
            dispatch(actions_invoices.subInvoicesByField(params.handle, 'requestId', params.request_id));
        }

        return () => {
            dispatch(actions_invoices.clearPayments());

            var unsubscribe = invoices.payments_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();

            var unsubscribe = invoices.invoices_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.customer_id, params.request_id, params.invoice_id]);

    useEffect(() => { setCanAuto(true); }, [params.tab]);

    // Automatically advance to the first item
    useEffect(() => {
        if (numPanels(3) && canAuto && params.tab == 'payments' && payments?.length && segments < seg+2) {
            if (timer) clearTimeout(timer);
            setTimer(setTimeout(() => {
                let location = toolbox.modifyPath(props.location.pathname, seg, 'payments/preview/' + payments[0]?.id);
                props.history.push({ pathname: location });
            }, 300));
        }
        setTimeout(() => { setCanAuto(false) }, 2000);
    }, [params.tab, payments]);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleButton = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, 5, 'payment-form/0');
        props.history.push({ pathname: newLocation });
    };
    const handleClick = (invoice) => {
        const changeHistory = (routeLocation.pathname.includes('preview'))
            ? history.replace
            : history.push;
        let location = toolbox.modifyPath(routeLocation.pathname, seg+1, 'preview/' + invoice.id);
        changeHistory({ pathname: location });

    };

    const confirmDelete = (row) => {
        ModalAlert({
            title: 'Are you sure you want to archive this Payment?',
            text: "This Payment will be Archived! It can be restored or deleted permanently by visiting the Archive page.",
            icon: 'question',
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes',
            deny_text: 'No, take me back',
            show_deny: true,
            callback_success: () => deletePayment(row)
        });
    };
    const deletePayment = (row) => {
        dispatch(actions_invoices.deletePayment(params.handle, row.id, taxRates, () => {
            dispatch(actions_quickbooks.updateQuickbooks({
                handle: params.handle,
                type: 'payment',
            }, quickbooksSettings));
        }));
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const columns = [
        { name: 'ID', field: 'id', width: 15 },
        { name: 'Date', field: 'date', type: 'date', format: 'MMM Do, YYYY', width: 25 },
        { name: 'Amount', field: 'amount', width: 30 },
        { name: 'Status', field: 'status', width: 30 },
    ];

    var data = payments?.map((payment) => ({ ...payment, status: (payment.unapplied) ? `Paid: $${parseFloat(payment.unapplied).toFixed(2)} credit` : 'Closed', amount: '$' + parseFloat(payment.amount)?.toFixed(2), _stripe_color: Object.values(PAYMENTS).find((item) => item.id == payment.statusId)?.color }));
    if (props.source == 'invoices') data = data?.filter((payment) => payment.invoices.find((invoice) => invoice.id == params.invoice_id));
    if (props.source == 'service-requests') data = data?.filter((payment) => payment.requestId == params.request_id);

    const statusStyle = { position: 'absolute', top: '-36px', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' };

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <>
            <Ibox className="" title={'Payments'} show_spinner={quotes.quotes_pending} no_fade={false}>
                <StatusKey
                    style={statusStyle}
                    statusTypes={Object.values(PAYMENTS)}
                />
                <Table
                    button={user_permissions?.PAYMENTS_RECEIVE && '+ Payment'}
                    button_in_ibox={true}
                    button_callback={handleButton}
                    data={data}
                    delete={(user_permissions.PAYMENTS_DELETE) && true}
                    on_delete={confirmDelete}
                    show_limit={false}
                    show_search={true}
                    active_id={params.payment_id}
                    active_field={'id'}
                    highlight_search={true}
                    max_height={'135px'}
                    click_callback={handleClick}
                    columns={columns}
                />
            </Ibox>
        </>

    );
};