import * as actions_settings from 'actions/settings-actions';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import _ from 'lodash';
import { ModalForm, Textarea } from 'enspire-manager-framework';
import { INVOICES, PURCHASE_ORDERS, REQUESTS, WORKORDERS, QUOTES } from 'components/constants';
import { STATUS_TABLES } from 'components/constants';
import { useDispatch, useSelector } from 'react-redux';

export default function StatusModal(props) {

    const dispatch = useDispatch();

    const confirmCallback = useSelector((store) => store.settings.confirmCallback);
    const currentId = useSelector((store) => store.settings.currentId); // id of current record
    const serviceRequests = useSelector((store) => store.serviceRequests.service_requests);;
    const statusId = useSelector((store) => store.settings.statusId); // status id of current tab
    const statusTable = useSelector((store) => store.settings.statusTable);
    const workOrders = useSelector((store) => store.workOrders.work_orders);
    const invoices = useSelector((store) => store.invoices.invoices);
    const quotes = useSelector((store) => store.quotes.quotes);
    const purchaseOrders = useSelector((store) => store.inventory.purchase_orders);

    const [reason, setReason] = useState('');
    const [error, setError] = useState([]);

    useEffect(() => {
        if (statusTable == 'WORKORDERS') {
            record = _.find(workOrders, { id: currentId });
            setReason(record.reason);
        }
    }, [currentId]);

    const closeStatusModal = () => {
        dispatch(actions_settings.statusModalClose());
    };
    const handleChange = (event) => {
        setReason(event.target.value);
        setError([]);
    };

    const tableName = STATUS_TABLES[statusTable].singular;

    var statusTypes;
    var record;
    var recordStatusId;

    if (statusTable == 'REQUESTS') {
        statusTypes = Object.values(REQUESTS);
        record = _.find(serviceRequests, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'WORKORDERS') {
        statusTypes = Object.values(WORKORDERS);
        record = _.find(workOrders, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'PURCHASE_ORDERS') {
        statusTypes = Object.values(PURCHASE_ORDERS);
        record = _.find(purchaseOrders, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'INVOICES') {
        statusTypes = Object.values(INVOICES);
        record = _.find(invoices, { id: currentId });
        recordStatusId = record?.statusId;
    }
    if (statusTable == 'QUOTES') {
        statusTypes = Object.values(QUOTES);
        record = _.find(quotes, { id: currentId });
        recordStatusId = record?.statusId;
    }

    const status = _.find(statusTypes, { id: statusId });

    return (
        <ModalForm {...props}
            style={1}
            size={'md'}
            modal_header={tableName + ' Status'}
            cancel_button_title="Cancel"
            cancel_button_callback={closeStatusModal}
            confirm_button_title={
                ((statusTable == 'WORKORDERS' || statusTable == 'INVOICES') && statusId != recordStatusId) 
                ? 'Confirm ' + status.name 
                : (statusId == recordStatusId && status.updatable) 
                    ? "Update" 
                    : 'Finished'}
            confirm_button_level={'primary'}
            confirm_button_callback={() => {
                if (statusTable == 'WORKORDERS' && status.id == WORKORDERS.INCOMPLETE.id && !reason) {
                    setError([{ field: 'reason', type: 'required' }]);
                } else {
                    if (typeof confirmCallback === 'function') confirmCallback(statusId, reason);
                }
            }}
        >

            <span className="float-right pt-3 pb-3">
                <span className="h6">Current: &nbsp; </span>
                <StatusSelect
                    selectedStatusId={recordStatusId}
                    statusTable={statusTable}
                    statusTypes={statusTypes}
                    static={true}
                />
            </span>

            <h1 className="font-weight-bold mt-3">
                <span style={{ color: status.color }}><i className="fa fa-circle"></i></span>&nbsp; {status.name}
            </h1>
            <p className="pt-3" style={{ fontSize: '18px', lineHeight: '26px' }}>{status.description}</p>

            {statusTable == 'WORKORDERS' && status.id == WORKORDERS.INCOMPLETE.id && status.id == WORKORDERS.INCOMPLETE.id &&
                <>
                    <p className="text-muted mb-2"><smaller>Reason for Incomplete and Steps to Resolve</smaller></p>
                    <div className={'d-flex'} style={{ flexDirection: 'column', alignContent: 'stretch' }}>
                        <Textarea
                            name={'reason'}
                            onChange={handleChange}
                            rows={2}
                            value={reason}
                            form_error={error}
                        />
                    </div>
                </>
            }

            <hr width="100%" className="mt-4 mb-4" />

            {status.change &&
                <>
                    <h4 className="text-muted">{(status.caution) ? '** CAUTION **' : 'NOTE'}</h4>
                    <p style={{ fontSize: '14px', lineHeight: '22px' }}>{status.change}</p>
                </>
            }
            {statusTable == 'REQUESTS' &&
                <p className="text-muted mb-2"><smaller>Service Request Statuses are updated automatically</smaller></p>
            }
            
            <div style={{ clear: 'both' }}></div>

        </ModalForm>
    );
};
