import * as actions_admin from 'actions/admin-actions';
import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { getFirestore, collection, query, where, getDocs, onSnapshot  } from "firebase/firestore";
import { toastr } from 'react-redux-toastr';
import { LOG, ACTION } from 'components/constants';

const firestore = firebase.firestore();
const db = getFirestore();

const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  ARCHIVE
/*-----------------------------------------------*/

export function subArchivedItems(handle, itemType, path) {

	return async dispatch => {

		dispatch({ type: types[itemType] + '_PENDING' });

		const query_ = query(collection(db, handle + path), where('deleted', '==', true));
		const unsubscribe = onSnapshot(query_, (querySnapshot) => {
			var items = [];
			querySnapshot.forEach((doc) => {
				items.push({ ...doc.data(), id: doc.id });
			});
			dispatch({ type: types[itemType] + '_FULFILLED', data: items, unsubscribe });
		});
	};
}

/*-----------------------------------------------*/
/*  RESTORE
/*-----------------------------------------------*/

export function restoreItem(handle, item, itemType, itemName, path, callback) {

	return async dispatch => {

		dispatch({ type: types[itemType] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const docRef = firestore.collection(handle + path);
		batch.set(docRef.doc(item.id), { deleted: false, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types[itemType] + '_SAVE_FULFILLED' });
			dispatch(actions_admin.saveActivity(handle, LOG[itemType].key, ACTION.RESTORED.key, item.id, item.name ));
			window.toastr.success(`The ${itemName} record has been Restored`, `${itemName} Restored!`);
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, `${itemName} NOT Restored!`);
		});
	};
}
export function restoreProfile(handle, profile, profile_type, callback) {

	return async dispatch => {

		dispatch({ type: types[profile_type] + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const docRef = firestore.collection(handle + '/profiles/profiles');
		batch.set(docRef.doc(profile.id), { deleted: false, modified: new Date() }, { merge: true });
	
		if (profile_type == 'EMPLOYEE' && profile.contact?.email) {
			batch.set(firestore.collection(handle + '/users/users').doc(profile.contact?.email), { deleted: false, modified: new Date() }, { merge: true });
		}
		
		batch.commit().then(() => {
			dispatch({ type: types[profile_type] + '_SAVE_FULFILLED' });
			dispatch(actions_admin.saveActivity(handle, LOG[profile_type].key, ACTION.RESTORED.key, profile.id, 
				(profile_type == 'EMPLOYEE') ? `${profile.contact?.firstName} ${profile.contact?.lastName}` : profile.displayName 
			));
			window.toastr.success(`The ${toolbox.toProperCase(profile_type)} record has been Restored`, `${toolbox.toProperCase(profile_type)} Restored!`);
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, `${toolbox.toProperCase(profile_type)} NOT Restored!`);
		});
	};
}

// export function restoreAssetType(handle, assetTypeId, profile_type, data, callback) {

// 	return async dispatch => {

// 		dispatch({ type: types[profile_type] + '_SAVE_PENDING' });

// 		var batch = firestore.batch();

// 		const customerRef = firestore.collection(handle + '/profiles/profiles');
// 		batch.set(customerRef.doc(profileId), { deleted: false, modified: new Date() }, { merge: true });

// 		// if (profile_type == 'EMPLOYEE') batch.set(firestore.collection(handle + '/users/users').doc(data.contacts[Object.keys(data.contacts)[0]].email), { deleted: false, modified: new Date() }, { merge: true });

// 		batch.commit().then(() => {
// 			dispatch({ type: types[profile_type] + '_SAVE_FULFILLED' });
// 			window.toastr.success(`The ${toolbox.toProperCase(profile_type)} record has been Restored`, `${toolbox.toProperCase(profile_type)} Restored!`);
// 			if (typeof callback === 'function') callback();
// 		}).catch((error) => {
// 			toolbox.process_error(error, `${toolbox.toProperCase(profile_type)} NOT Restored!`);
// 		});
// 	};
// }

/*-----------------------------------------------*/
/*  PERMANENTLY DELETE
/*-----------------------------------------------*/

export function deleteProfile(handle, profileId, profile_type, data, callback) {

	return async dispatch => {

		dispatch({ type: types[profile_type] + '_DELETE' });

		var snapshot, snapshot2;
		const batch = firestore.batch();

		if (profile_type == "CUSTOMER") {
			snapshot = await firestore.collection(handle + '/addresses/addresses').where('profileId', '==', profileId).get();
			snapshot.docs?.forEach((doc) => {
				batch.delete(doc.ref);
			});
			snapshot = await firestore.collection(handle + '/invoices/invoices').where('profileId', '==', profileId).get();
			snapshot.docs?.forEach((doc) => {
				batch.delete(doc.ref);
			});

			snapshot = await firestore.collection(handle + '/quotes/quotes').where('profileId', '==', profileId).get();
			snapshot.docs?.forEach((doc) => {
				batch.delete(firestore.collection(handle + '/quotes/quotes/' + doc.id + '/attachments').doc(doc.id));
				batch.delete(firestore.collection(handle + '/quotes/quotes/' + doc.id + '/serviceItems').doc(doc.id));
				batch.delete(doc.ref);
			});

			snapshot = await firestore.collection(handle + '/service-requests/service-requests').where('profileId', '==', profileId).get();
			for (var doc of snapshot.docs) {

				snapshot2 = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', doc.id).get();
				snapshot2.docs?.forEach((doc2) => {
					batch.delete(firestore.collection(handle + '/work-orders/work-orders/' + doc2.id + '/attachments').doc(doc2.id));
					batch.delete(firestore.collection(handle + '/work-orders/work-orders/' + doc2.id + '/serviceItems').doc(doc2.id));
					batch.delete(doc2.ref);
				});

				batch.delete(doc.ref);
			}
		}
		if (profile_type == "EMPLOYEE") batch.delete(firestore.collection(handle + '/users/users').doc(data.contacts[Object.keys(data.contacts)[0]].email));

		batch.delete(firestore.collection(handle + '/profiles/profiles/' + profileId + '/attachments').doc(profileId));
		batch.delete(firestore.collection(handle + '/profiles/profiles').doc(profileId));

		await batch.commit().then(() => {
			dispatch({ type: types[profile_type] + '_FULFILLED' });
			window.toastr.success(`The ${toolbox.toProperCase(profile_type)} record has been PERMANENTLY DELETED`, `${toolbox.toProperCase(profile_type)} Deleted!`);
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, `${toolbox.toProperCase(profile_type)} NOT Deleted!`);
		});
	};
}
