import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseConfigDevelopment = {
  // apiKey: "AIzaSyD3abFbwbvCnv80WDT27qksS-xkW1NH00g",
  // authDomain: "mobile-track-maint-develop.firebaseapp.com",
  // projectId: "mobile-track-maint-develop",
  // storageBucket: "mobile-track-maint-develop.appspot.com",
  // messagingSenderId: "918413548289",
  // appId: "1:918413548289:web:493fd69b056a3c6bdf5900",
};

const firebaseConfigProduction = {
  apiKey: "AIzaSyDKink11TyJopyA0tTfSj9Kyr3pBGpTeo8",
  authDomain: "mobiletrack-enterprise.firebaseapp.com",
  projectId: "mobiletrack-enterprise",
  storageBucket: "mobiletrack-enterprise.appspot.com",
  messagingSenderId: "321802982548",
  appId: "1:321802982548:web:ab5a47efac3b50beb75081",
  measurementId: "G-Q777FHEYQG"
};

firebase.initializeApp(
  process.env.REACT_APP_FIREBASE_ENV === "development"
    ? firebaseConfigDevelopment
    : firebaseConfigProduction
);

const secondaryAppConfig = {
  apiKey: "AIzaSyAg3ux6KbFPIFXQbcbu-r1bUi4MEvGENnI",
  authDomain: "assetmanager.mobiletrack.systems",
  projectId: "mobile-track-maintenance",
  storageBucket: "mobile-track-maintenance.appspot.com",
  messagingSenderId: "927498414469",
  appId: "1:927498414469:web:8748a56b5885c70ec8fcec",
  measurementId: "G-YZJ48HX83N",
};

firebase.initializeApp(secondaryAppConfig, "secondary");

export default firebase;
