import * as actions_work_orders from 'actions/work-orders-actions';
import CheckinWorkOrders from '../../work-orders/check-in-work-orders';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ModalForm, Table } from 'enspire-manager-framework';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

var _ = require('lodash');

const CheckinModal = (props) => {

	const dispatch = useDispatch();
	const params = useParams();

    const tasks = useSelector((store) => store.settings.settings_tasks);
    const user = useSelector((store) => store.users.user);
    const workOrders = useSelector((store) => store.workOrders);

	const [type, setType] = useState(1);

	const userTasks = _.filter(tasks, (task) => {
		const isEmployee = _.find(task.employees, (emp) => { return emp.value == user.profileId });
		return !task.employees?.length || isEmployee;
	});

	/* Handlers ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const handleCheckin = (task) => {
		// TODO - task Checkin
	};

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const tasksColumns = [
        { name: 'name', field: 'name', width: 80 },
        { name: 'Check-in', field: 'id', type: 'button', button: { name: 'Check In', className: 'btn-info btn-xs', callback: handleCheckin}, width: 20 },
    ];

	return (

		<ModalForm {...props}
			size={'md'}
			modal_header={'Check-In'}
			cancel_button_title="Cancel"
			save_button_title={'FINISHED'}
			submitFormHandler={ props.closeCheckinForm }
			cancel_button_callback={ props.closeCheckinForm }
		>
			<ButtonGroup className="d-flex mt-0 mb-3">
                <Button variant={ (type == 1) ? 'primary' : 'default' } onClick={ () => setType(1) }>Work Order</Button>
                <Button variant={ (type == 2) ? 'primary' : 'default' } onClick={ () => setType(2) }>Task</Button>
            </ButtonGroup>

            { type == 1 &&
				<CheckinWorkOrders closeCheckinForm={ props.closeCheckinForm } souce="timeClock" />
			}
			{ type == 2 &&
				<Table
					data={userTasks}
					columns={tasksColumns}
					order_by={{ fields: ['name'], direction: ['asc'] }}
					show_search={true}
				>
				</Table>
			}

		</ModalForm>
	);
};

export default CheckinModal;
