import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { ROLES, WORKORDERS, WORKORDERS_CHECKED_IN } from 'components/constants';
import { collection, getCountFromServer, getFirestore, query, where, deleteField } from 'firebase/firestore';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  TIME-CLOCK
/*-----------------------------------------------*/

export function subTimeSheets(handle, userId) {

	return async dispatch => {

		
		if (userId) {
			dispatch({ type: types.TIME_CARDS + '_PENDING' });
			var unsubscribe = firestore.collection(handle + '/timesheets/timecards').where('userId', '==', userId).orderBy('startDate', 'desc').limit(12).onSnapshot((querySnapshot) => {
				var time_cards = [];
				querySnapshot.forEach((doc) => {
					time_cards.push({ ...doc.data(), id: doc.id });
				});
	
				dispatch({ type: types.TIME_CARDS + '_FULFILLED', data: time_cards, unsubscribe });
			});
			
		} else {
			dispatch({ type: types.TIME_SHEETS + '_PENDING' });
			var unsubscribe = firestore.collection(handle + '/timesheets/timesheets').orderBy('startDate', 'desc').limit(12).onSnapshot((querySnapshot) => {
				var time_sheets = [];
				querySnapshot.forEach((doc) => {
					time_sheets.push({ ...doc.data(), id: doc.id });
				});
				
				dispatch({ type: types.TIME_SHEETS + '_FULFILLED', data: time_sheets, unsubscribe });
			});
		}

	};
}

export function subTimeClock(handle, userId) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_PENDING' });

		const id = moment().format('YY-MM-DD'); // '24-19-24'
		var unsubscribe = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).onSnapshot((doc) => {
			if (doc.exists) {
				var time_clock_data = { ...doc.data(), id: doc.id }

				var clockedIn = false;
				var secondsAccumulated = 1;
				var lastTimeIn = null;
				var lastTimeOut = null;

				time_clock_data.entries.forEach((entry, index) => {
					if (entry['time-out']) {
						secondsAccumulated += moment(entry['time-out'].toDate()).diff(moment(entry['time-in'].toDate()), 'seconds');
						lastTimeOut = entry['time-out'];
					} else {
						clockedIn = true;
						lastTimeIn = entry['time-in'];
					}
				});
			}

			var time_clock = { clockedIn, secondsAccumulated, lastTimeIn, lastTimeOut }

			dispatch({ type: types.TIME_CLOCK + '_FULFILLED', data: time_clock, unsubscribe });
		});
	};
}
export function getTimeClockRaw(handle, userId, startdate, enddate) {

	return async dispatch => {

		dispatch({ type: types.TIME_ENTRIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users/' + userId + '/time-clock')
			.where('date', '>=', moment(startdate, 'X').toDate())
			.where('date', '<=', moment(enddate, 'X').toDate())
			.orderBy('date', 'desc')
			.onSnapshot((querySnapshot) => {
				var time_clock = [];
				querySnapshot.forEach((doc) => {
					time_clock.push({ ...doc.data(), id: doc.id });
				});

				dispatch({ type: types.TIME_ENTRIES + '_FULFILLED', data: time_clock, unsubscribe });
			}
		);
	};
}
export function getTimeSheet(handle, dateRange) {

	return async dispatch => {

		dispatch({ type: types.TIME_SHEET + '_PENDING' });

		// Time Clock
		const timeSnapshot = await firestore.collectionGroup('time-clock').where('handle', '==', handle).where('date', '>=', dateRange[0]).where('date', '<', dateRange[1]).get();	
		var entries = [];
		timeSnapshot.forEach((doc) => { entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); });
		const timeClock = Object.groupBy(entries, ({ id }) => id);

		// Checked In
		const checkedInSnapshot = await firestore.collectionGroup('check-in').where('handle', '==', handle).where('date', '>=', dateRange[0]).where('date', '<', dateRange[1]).get();
		var entries = [];
		checkedInSnapshot.forEach((doc) => { entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); });
		const checkedIn = Object.groupBy(entries, ({ userId }) => userId);

		const values = [timeClock, checkedIn]
		dispatch({ type: types.TIME_SHEET + '_FULFILLED', data: values });
	};
}
// export function createTimesheetPdf(options, callback) {
// 	return async dispatch => {
// 		try {
// 			firebase.functions().useEmulator("127.0.0.1", 5001);
// 			firebase.functions().httpsCallable('createTimesheetPdf')(options).then((result) => { // change for timesheet
// 				if (typeof callback === 'function') callback(result);
// 			}).catch((error) => {
// 				console.log(error);
// 			});
// 		} catch (error) {
// 			window.toastr.error(error, "Error Creating Timesheet");
// 		}
// 	};
// }
export function clockIn(handle, users) {

	return dispatch => {

		if (!users.time_clock?.clockedIn) {
			dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

			const id = moment().format('YY-MM-DD');
			const docRef = firestore.collection(handle + '/users/users/' + users.user.id + '/time-clock').doc(id);

			docRef.get().then(async(doc) => {
				var entries = (doc.exists) ? doc.data().entries : [];
				if (!entries.length || entries[entries.length-1]['time-out']) {
					entries.push({ 'time-in': new Date() });
					await docRef.set({ handle, date: moment().startOf('day').toDate(), entries });
					window.toastr.info('You have been successfully Clocked In', 'Clocked In!');
				} else {
					// already Clocked in
				}

				dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
			});
		}
	};
}
export function clockOut(handle, users) {

	return async dispatch => {

		if (users.time_clock?.clockedIn) {
			dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

			const id = moment().format('YY-MM-DD');
			const timeClockRef = firestore.collection(`${handle}/users/users/${users.user.id}/time-clock`).doc(id);
			const timeClockDoc = await timeClockRef.get();

			if (timeClockDoc.exists) {
				const batch = firestore.batch();
				const currentDate = moment().format('YY-MM-DD');

				var entries = timeClockDoc.data().entries;
				entries[entries.length - 1]['time-out'] = new Date();
				batch.set(timeClockRef, { entries }, { merge: true });

				// ensure "check-out" if currently "checked-in" to Work Order
				if (users.user.lastCheckInWorkOrderId) {
					const previousWorkOrderDoc = await firestore.collection(handle + '/work-orders/work-orders/').doc(users.user.lastCheckInWorkOrderId).get();
					const prevWorkOrder = { ...previousWorkOrderDoc.data(), id: previousWorkOrderDoc.id };	
					
					// if previous still checked-in, check-out!
					if (
						prevWorkOrder['statusId'] == WORKORDERS.ENROUTE.id || 
						prevWorkOrder['statusId'] == WORKORDERS.ONSITE.id ||
						prevWorkOrder['statusId'] == WORKORDERS.INPROGRESS.id
					) {
						batch.update(firestore.collection(`${handle}/work-orders/work-orders`).doc(prevWorkOrder.id), { statusId: WORKORDERS.ASSIGNED.id });
						batch.set(firestore.collection(`${handle}/work-orders/work-orders/${prevWorkOrder.id}/check-in`).doc(currentDate), { 
							handle,
							userId: users.user.id,
							date: moment().startOf('day').toDate(),
							entries: firebase.firestore.FieldValue.arrayUnion({'time': new Date(), 'type': 'checkout'})
						}, { merge: true });
					}
				}

				batch.commit().then(() => {
					dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
					dispatch({ type: types.WORK_ORDER_CHECKED_IN, data: null });
					window.toastr.info('You have been successfully Clocked Out', 'Clocked Out!');
				}).catch((error) => {
					console.error("Error updating document: ", error);
				});	
			}
		}
	};
}
export function saveNewClockEntry(handle, userId, newEntry) {

	return dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = moment(newEntry.date).format('YY-MM-DD');
		const docRef = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id);

		docRef.get().then(async(doc) => {
			var entries = (doc.exists) ? doc.data().entries : [];
			entries.push({ 
				['time-in']: (newEntry.timeIn) ? moment(id + ' ' + newEntry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
				['time-out']: (newEntry.timeOut) ? moment(id + ' ' + newEntry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
			});

			let record = { 
				date: moment(newEntry.date).toDate(),
				entries,
				handle: handle,
			};
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set(record, { merge: true });
	
			dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
		});
	};
}
export function deleteClockEntry(handle, userId, entry, callback) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = moment(entry.date.seconds, 'X').format('YY-MM-DD');
		const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
		var day = { ...doc.data(), id: doc.id };

		// remove existing day entry
		var entries = [];
		day.entries.forEach((db_entry) => {

			let dbIn = (moment(db_entry['time-in']?.seconds, 'X').isValid()) ? moment(db_entry['time-in']?.seconds, 'X').format('h:mm a') : '';
			let dbOut = (moment(db_entry['time-out']?.seconds, 'X').isValid()) ? moment(db_entry['time-out']?.seconds, 'X').format('h:mm a') : '';

			if ((entry.timeIn != dbIn) || (entry.timeOut != dbOut)) {
				entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
			}
		});
		await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });

		dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED' });
	};
}
export function updateClockEntry(handle, userId, field, entry, newValue, callback) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		if (field == 'date') {		
			const id = moment(entry.date).format('YY-MM-DD');
			const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
			var day = { ...doc.data(), id: doc.id };
	
			// remove existing day entry
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbIn = (moment(db_entry['time-in']?.seconds, 'X').isValid()) ? moment(db_entry['time-in']?.seconds, 'X').format('h:mm a') : '';
				let dbOut = (moment(db_entry['time-out']?.seconds, 'X').isValid()) ? moment(db_entry['time-out']?.seconds, 'X').format('h:mm a') : '';
	
				if ((entry.timeIn != dbIn) || (entry.timeOut != dbOut)) {
					entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
				}
			});
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });
			
			// add new day entry
			const id2 = moment(newValue).format('YY-MM-DD');
			const doc2 = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).get();
			if (doc2.exists) {
				var day2 = { ...doc2.data(), id: doc2.id };
				let entries2 = [ ...day2.entries, { 
					['time-in']: (entry.timeIn) ? moment(id2 + ' ' + entry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
					['time-out']: (entry.timeOut) ? moment(id2 + ' ' + entry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
				}];

				await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).set({ entries: entries2 }, { merge: true });

			} else {
				let record = { 
					date: moment(newValue).toDate(),
					entries: [{ 
						['time-in']: (entry.timeIn) ? moment(id2 + ' ' + entry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
						['time-out']: (entry.timeOut) ? moment(id2 + ' ' + entry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
					}],
					handle: handle,
				};
				await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).set(record, { merge: true });
			}

		} else {
			const id = moment(entry.date.seconds, 'X').format('YY-MM-DD');
			const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
			var day = { ...doc.data(), id: doc.id };
	
			// Cycle through day entries and update changed
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbIn = (moment(db_entry['time-in']?.seconds, 'X').isValid()) ? moment(db_entry['time-in']?.seconds, 'X').format('h:mm a') : '';
				let dbOut = (moment(db_entry['time-out']?.seconds, 'X').isValid()) ? moment(db_entry['time-out']?.seconds, 'X').format('h:mm a') : '';
	
				if ((entry.timeIn == dbIn) && (entry.timeOut == dbOut)) {
	
					let timeIn = (field == 'timeIn') ? newValue : entry.timeIn;
					let timeOut = (field == 'timeOut') ? newValue : entry.timeOut;
					
					entries.push({
						['time-in']: (timeIn) ? moment(id + ' ' + ((field == 'timeIn') ? newValue : entry.timeIn), 'YY-MM-DD h:mm a').toDate() : '',
						['time-out']: (timeOut) ? moment(id + ' ' + ((field == 'timeOut') ? newValue : entry.timeOut), 'YY-MM-DD h:mm a').toDate() : '',
					})
				} else {
					entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
				}
			});
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });
		}
		window.toastr.success('Your time entries have been successfully Modified', 'Time Clock Updated!');

		dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED' });
	};
}
