import * as actions_authentication from 'actions/authentication-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useRef, useState } from 'react';
import { Avatar } from 'enspire-manager-framework';
import { Dropdown, Overlay } from 'react-bootstrap';
import { ROLES } from 'components/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const _ = require('lodash');

export default function UserProfile(props) {
    
    const seg = 3;
    const ref = useRef(null);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();

    const users = useSelector((store) => store.users);
    const company = useSelector((store) => store.company.company);
    
    const [preview, setPreview] = useState(null);
    const [show, setShow] = useState(false);
    
    const clockedIn = users.time_clock.clockedIn;

    var user = props.user;
    var email = props.email;
    var name = user?.firstName + ' ' + user?.lastName;

    var roles = [];
    if (user?.roles) {
        user.roles.forEach((roleId) => {
            let role = _.findKey(ROLES, (role) => { return role.id == roleId; });
            roles.push(toolbox.toProperCase(role));
        });
    }

    const openUserPreferences = () => {
        setShow(false);
        let location = toolbox.modifyPath(routeLocation.pathname, seg, "settings/user_preferences", seg+1);
        history.push({ pathname: location });
    };

    const logOut = (event) => {
        event.preventDefault();
        setShow(false);
        dispatch(actions_authentication.logout());
    }

    const handlePreview = (roleId) => {
        setShow(false);
        setPreview(roleId);
        if (roleId) {
            dispatch(actions_users.subUserPermissions(params.handle, null, roleId));
        } else {
            dispatch(actions_users.subUserPermissions(params.handle, email));
        }
    }

    var previewRoles = _.filter(Object.values(ROLES), (o) => o.id != '99');
    previewRoles = _.orderBy(previewRoles, ['order'], ['asc']);
    const rolesMenu = previewRoles.map((role) => <li key={role.id}>
        <a className={ 'dropdown-item ' + ((role.id == preview)?'active':'') } onClick={ () => handlePreview(role.id) }>{ role.name }</a>
    </li>);
    const badges = roles.map((role) => <span key={role} className="text-capitalize mt-1 ml-1 badge">{role}</span>);

    return (
        <div className="mt-4 text-center mb-3 px-3" style={{ width: '100%', color: '#a7b1c2' }}>
            {user &&
                props.collapsed ?
                <div className="avatar-container">
                    <Avatar className="mx-auto mb-3 my-auto" size={50} fontSize={'20px'} name={name} image={user.photoUrl} border={'2px solid ' + ((preview)?'red':'white')} />
                    { clockedIn &&
                        <span className="fa-stack animated infinite pulse mt-4">
                            <i className="fas fa-circle fa-stack-2x text-success"></i>
                            <i className="far fa-clock fa-stack-1x fa-inverse"></i>
                        </span>
                    }
                </div>
                : <div className="animated fadeIn" style={{ width: '207px' }}>
                    <div ref={ref} className="dropdown-toggle no-caret" style={{ cursor: 'pointer' }} onClick={ () => { setShow(!show) }}>
                        <Avatar className="mx-auto" size={70} fontSize={30} name={name} image={user.photoUrl} border={'2px solid ' + ((preview)?'red':'white')} />
                        <span className="fa-stack fa-xl position-absolute ml-2" style={{ top: 70, fontSize: '14px' }}>
                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                            <i className="fa-solid fa-cog fa-stack-1x fa-inverse"></i>
                        </span>
                        {/* <i className="fa-light fa-user-gear fa-lg mr-auto mt-auto mb-1 ml-2" aria-hidden="true"></i> */}
                    </div>

                    <Overlay
                        show={show}
                        target={ref.current}
                        placement="bottom"
                        container={props.container?.current}
                        containerPadding={20}
                        >   
                        <ul className="dropdown-menu mt-2">
                            <li><a className="dropdown-item" onClick={ openUserPreferences }>User Preferences</a></li>
                            <li><a className="dropdown-item" onClick={ logOut }>Log Out</a></li>
                            <li className="dropdown-divider"></li>
                            { (user.roles?.includes(ROLES.ADMINISTRATOR.id) || user.roles?.includes(ROLES.OWNER.id)) &&
                                <>
                                    { preview
                                        ? <li><a className="dropdown-item text-warning" onClick={ () => handlePreview(null) }>REMOVE PREVIEW</a></li>
                                        : <li className="dropdown-header text-info">PREVIEW PERMISSIONS</li>
                                    }
                                    {rolesMenu}
                                </>
                            }
                            <li className="dropdown-divider"></li>
                            <li><a className="dropdown-item text-danger" href={`/#/${params.handle}/${params.appId}/account_delete`}>Delete Account</a></li>
                        </ul>
                    </Overlay>

                    <h2 className="m-2">{ company?.companyName }</h2>
                    <h3 className='mb-1 mt-3 text-white'>{name}</h3>
                    <p className="mb-2">{email}</p>
                    {badges}
                </div>
            }
        </div>
    );
}
