import React, { Fragment } from 'react';
import _ from 'lodash';
import { ADDRESSES } from 'components/constants';
import { Button, ButtonGroup } from 'react-bootstrap';
import { URGENCY, } from 'components/constants';
import { sortByField } from 'components/common/toolbox';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function service_request_fields() {

	return (
		{
			customerId: [],
			description: '',
			imageUrl: '',
			imagesAlt: [],
			requestDate: new Date(),
			serviceDescription: 'See Attached Invoice',
			suggested: '',
			technicianId: null,
			urgencyId: URGENCY.MEDIUM.id,
			vendorId: [],
		}
	);
};

export function service_request_form_layout({
	firebase, 
	fromSchedule, 
	handle, 
	handlePromote,
	profile, 
	service_request,
	technicians,
	typeButtons, 
}) {

	const technician_options = _.orderBy(technicians, ['contact.lastName'], ['asc']).map((tech, index) => {
		return (
			<option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
		);
	});

	if (profile?.addresses) {
		var address_options = profile.addresses.map((address, index) => {
			let addressType = _.find(Object.values(ADDRESSES), { id: address.addressTypeId });
			return (
				<option key={'address' + address.addressId} value={address.id}>
					{`${(address.addressName) ? address.addressName + ' ' : ''}${(addressType) ? '(' + addressType?.name + ') - ' : ''}${address.addressLine1}${(address.addressLine2) ? address.addressLine2 : ''}`}
				</option>
			);
		});
	}

	var altImages = [];
	for (let index = 0; index < 6; index++) {
		if (service_request['imageUrlAlt' + index]) {
			altImages.push({
				grid: 'col-sm-6 col-md-12 col-xl-6 mt-3',
				label: '',
				field: 'imageUrlAlt' + index,
				type: 'dropzone',
				disabled: (!service_request.imageUrl),
				dropzone: {
					camera: true,
					storage: 'firebase',
					storageRef: firebase.storage().ref(),
					bin: handle,
					directory: 'service-requests',
					className: 'mb-3',
					height: "180px",
					multiple: false,
					resultsArray: true,
					onPromote: handlePromote,
				}
			});
		}
	}

	return (
		[{
			column_class: 'col-md-7',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-md-12',
					label: 'Customer',
					field: 'customerId',
					placeholder: 'Search for Customer',
					type: 'typeahead',
					valid: ['required'],
					options: {
						allow_new: true,
						fields: ["displayName", "addresses", "contacts"],
						id: 'id',
						minLength: 0,
						sort: [{ "displayName": "asc" }],
						table: 'mt-' + handle + (isDev ? '-dev' : '') + '-profiles',
						target: 'displayName',
						type_filter: 'customer',
					},
				}, {
					grid: 'col-12',
					label: 'Service Address',
					field: 'addressId',
					type: 'select',
					valid: ['required'],
					disabled: false,
					options:
						<Fragment>
							<option key={'none'} value={null}>- Select -</option>
							{address_options}
						</Fragment>,
			}]
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">{`Service Request Details`}</h3>
						{ typeButtons }
					</Fragment>,
				layout: [{
					grid: 'col-6',
					label: `Request Date`,
					field: 'requestDate',
					type: 'date',
					dateFormat: 'MM-DD-YYYY',
					showTimeSelect: true,
					valid: ['required'],
					selectsStart: true,
					// readOnly: (isComplete) ? true : false,
				}, {
					grid: 'col-6',
					label: 'Urgency',
					field: 'urgencyId',
					type: 'select',
					valid: ['required'],
					options: <>
						<option value="">Choose...</option>
						{Object.keys(URGENCY).map((type) => (<option key={URGENCY[type].name} value={URGENCY[type].id}>{URGENCY[type].name}</option>))}
					</>
				}, {
					grid: 'col-sm-12',
					label: 'Technician',
					field: 'technicianId',
					type: 'select',
					options:
						<Fragment>
							<option key={'none'} value={''}>- Select -</option>
							{technician_options}
						</Fragment>,
				}, {
					grid: 'col-12',
					label: 'Description of Issue',
					field: 'description',
					type: 'textarea',
					valid: ['required'],
				}],
			}]
		}, {
			column_class: 'col-md-5',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Main Service Request Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Service Request Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'service-requests',
						className: 'mb-3',
						height: "280px",
						multiple: true
					}
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none mb-0">Additional Images</h3>
					</Fragment>,
				layout: [ ...(altImages) ],
			}],
		}]
	);
};