import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Spinner } from 'enspire-manager-framework';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const firestore = firebase.firestore();

export function VerifyHandle(props) {

	const history = useHistory();

	const user_permissions = useSelector(store => store.users?.user_permissions);

	const [showRegistration, setShowRegistration] = useState(false);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		handle: '',
		verifying: false,
	});

	// Check for a stored handle and set it if it exists
	useEffect(() => {
		const storedHandle = localStorage.getItem('mobileTrackHandle');

		if (storedHandle && !showRegistration) setState(prev => ({ ...prev, handle: storedHandle }));
		else if (showRegistration) setState(prev => ({ ...prev, handle: '' }));
	}, [showRegistration]);


	const handleChange = (event) => {
		setState(prev => ({ ...prev, [event.target.name]: sanitizeInput(event.target.value) }));
	};
	const submitForm = async (event) => {
		event.preventDefault();
		if (state.handle.length > 0) {
			setState(prev => ({ ...prev, verifying: true }));

			try {
				const companyDoc = await firestore.collection('_companies').doc(state.handle).get();
				setState(prev => ({ ...prev, verifying: false }));

				if (companyDoc.exists) {
					var company = { ...companyDoc.data() }

					if (company.appIds || company.appIds?.length) {
						history.push(`/${company.handle}/${company.appIds[0]}/login`);
					} else {
						window.toastr.info('This Company is not authorized with any Apps.  Please contact customer Service.', 'Not Authorized');
					}
				} else {
					window.toastr.error('This Company ID cannot be found.  Please check for errors and try again.', 'Not Found');
				}

			} catch(error) {
				toolbox.process_error(error, 'Verify Handle: ' + error.message);
			}
		}
	};

	const handleRegister = async (event) => {
		event.preventDefault();
		if (state.handle.length < 5) {
			window.toastr.error('Company ID must be at least 5 characters long.', 'Invalid Company ID');
			return;
		}

		setLoading(true);
		const companyDoc = await firebase.firestore().collection('_companies').doc(state.handle).get();
		
		if (companyDoc.exists) {
			setLoading(false);
			window.toastr.error('Company ID already exists.  Please try another.', 'Company ID Exists');
			return;
		}

		props.history.push(`/${state.handle}/register_company`);
	};

	return (
		<div id="wrapper" className="gray-bg" style={{ paddingBottom: '300px' }}>

			<div className="middle-box text-center loginscreen animated fadeInDown">
				<div>
					<div style={{ margin: '20px 0' }}>
						<img src={'images/logo.png'} width="100%" alt="Mobile Track Logo" />
					</div>

					<form className="register-company" style={{ marginTop: '40px' }} onSubmit={(showRegistration) ? handleRegister : submitForm}>
						<div className="form-group">
							{ showRegistration
								? 	<>
										<h2>Register New Company ID</h2>
										<div className="my-3 text-muted">Lowercase with no spaces up to 30 characters long.<br/>Special characters are limited to the hyphen ("-").</div>
									</>
								: 	<h2>Company ID</h2>
							}
							<input type="text" autoCapitalize="none" name="handle" className="form-control" placeholder="Company ID" value={state.handle} onChange={handleChange.bind(this)} />
						</div>
						{!state.verifying
							?	<button type="submit" className={`btn btn-${(showRegistration)?'warning':'primary'} block full-width m-b mt-4`} disabled={ loading }>
									{showRegistration ? 'REGISTER NEW COMPANY' : 'CONTINUE TO LOGIN'}&nbsp;{loading && (<i className={"fa fa-lg fa-spinner fa-spin"} aria-hidden="true"></i>)}
								</button>
							: 	<div style={{ margin: '15px 0' }}><Spinner /></div>
						}

						{/* { user_permissions?.SUPERADMIN &&
							<>
								{ showRegistration
									? <a onClick={() => { setShowRegistration(!showRegistration); }}>Back to Existing Company ID</a>
									: <a onClick={() => { setShowRegistration(!showRegistration); }} className="btn btn-default block m-b mt-5" disabled={ state.handle_pending }>REGISTER NEW COMPANY</a>
								}
							</>
						} */}

					</form>
				</div>
			</div>
		</div>
	);
};

function sanitizeInput(input) {
	// Convert to lowercase
	let formattedString = input.toLowerCase();

	// Remove special characters (except hyphens)
	formattedString = formattedString.replace(/[^a-z0-9 -]/g, '');

	// Replace spaces with hyphens
	formattedString = formattedString.replace(/\s+/g, '-');

	// Remove consecutive hyphens
	formattedString = formattedString.replace(/-+/g, '-');

	// Trim to meet the length requirements
	formattedString = formattedString.slice(0, 30);

	return formattedString;
}