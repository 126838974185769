import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { APPS } from 'components/constants';
import { roundDateTime } from 'components/common/toolbox';

export function work_order_fields(default_duration) {

	return (
		{
			startDate: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
			endDate: roundDateTime(moment(), moment.duration(15, "minutes")).add(default_duration, 'hours').toDate(),
			duration: (parseFloat(default_duration ?? '2') * 60).toString(),
			technicianId: '',
			description: ''
		}
	);
};

export function work_order_form_layout({
	departments,
	firebase, 
	handle, 
	handlePromote,
	technicians, 
	appId,
	work_order, 
}) {

	const department_options = departments.map((department) => {
		return <option key={ department.id } value={ department.id }>{ department.value }</option>
	}); 

	const technician_options = _.orderBy(technicians, ['contact.lastName'], ['asc']).map((tech, index) => {
		return <option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
	});

	var altImages = [];
	
	for (let index = 0; index < 6; index++) {
		if (work_order?.['imageUrlAlt' + index]) {
			altImages.push({
				grid: 'col-sm-6 col-md-12 col-xl-6 mt-3',
				label: '',
				field: 'imageUrlAlt' + index,
				type: 'dropzone',
				disabled: (!work_order.imageUrl),
				dropzone: {
					camera: true,
					storage: 'firebase',
					storageRef: firebase.storage().ref(),
					bin: handle,
					directory: 'work-orders',
					className: 'mb-3',
					height: "180px",
					multiple: false,
					resultsArray: true,
					onPromote: handlePromote,
				}
			});
		}
	}

	return (
		[{
			column_class: 'col-lg-7',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Work Order Details</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Department',
					field: 'departmentId',
					type: 'select',
					options:
						<Fragment>
							<option value="">Choose...</option>
							{department_options}
						</Fragment>,
				}, {
					grid: 'col-6',
					label: 'Start Date',
					field: 'startDate',
					type: 'date',
					dateFormat: 'MM-DD-YYYY hh:mm A',
					timePicker: true,
					valid: ['required'],
					// clearButton: false,
					// maxDate: (work_order?.endDate) ? work_order?.endDate : null,
					minutesStep: 15,
				}, {
					grid: 'col-6',
					label: 'Duration',
					field: 'duration',
					valid: ['required'],
					type: 'select',
					options:
						<Fragment>
							<option key={'30'} value={'30'}>00:30 min</option>
							<option key={'60'} value={'60'}>01:00 min</option>
							<option key={'90'} value={'90'}>01:30 min</option>
							<option key={'120'} value={'120'}>02:00 min</option>
							<option key={'150'} value={'150'}>02:30 min</option>
							<option key={'180'} value={'180'}>03:00 min</option>
							<option key={'210'} value={'210'}>03:30 min</option>
							<option key={'240'} value={'240'}>04:00 min</option>
							<option key={'270'} value={'270'}>04:30 min</option>
							<option key={'300'} value={'300'}>05:00 min</option>
							<option key={'330'} value={'330'}>05:30 min</option>
							<option key={'360'} value={'360'}>06:00 min</option>
							<option key={'390'} value={'390'}>06:30 min</option>
							<option key={'420'} value={'420'}>07:00 min</option>
							<option key={'450'} value={'450'}>07:30 min</option>
							<option key={'480'} value={'480'}>08:00 min</option>
						</Fragment>,
				}, {
					grid: 'col-sm-' + ((appId == APPS.SERVICE.id) ? '12' : '6'),
					label: 'Technician',
					field: 'technicianId',
					type: 'select',
					options:
						<Fragment>
							<option key={'none'} value={''}>- Select -</option>
							{technician_options}
						</Fragment>,
				}, {
					grid: 'col-sm-6 mt-3 pt-3',
					label: 'Asset Offsite',
					field: 'offsite',
					type: (appId == APPS.SERVICE.id) ? 'skip' : 'checkbox',
				}, {
					grid: 'col-12',
					label: 'Description',
					field: 'description',
					type: 'textarea',
					rows: 6,
					valid: ['required'],
				}],
			}],
		}, {
			column_class: 'col-lg-5',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Main Work Order Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Work Order Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'work-orders',
						className: 'mb-3',
						height: "280px",
						multiple: true
					}
				}],
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none mb-0">Additional Images</h3>
					</Fragment>,
				layout: [ ...(altImages) ],
			}],
		}]
	);
};