import React, { useState } from 'react';
import { Table, ModalAlert } from 'enspire-manager-framework';
import * as toolbox from 'components/common/toolbox';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { SERVICE_ITEMS } from 'components/constants';
import * as actions_quotes from 'actions/quotes-actions';

const ServiceItemsOption = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    const dispatch = useDispatch();

    const quotes = useSelector((state) => state.quotes);

    const option = props?.option;
    const quote = quotes?.quote;
    
    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    // Format Items
    let items = (quote?.changeOrder)
        ? quote?.quoteItems?.concat(quote?.changedItems.map((item) => { return { ...item, _accent: { backgroundColor: (item.changeOrder == 'added') ? "lightgreen" : 'pink' } }; }))
        : quote?.serviceItems;

    items = items?.map((item) => ({ ...item,
        serviceItemTypePosition: _.find(Object.values(SERVICE_ITEMS), { id: item.serviceItemTypeId })?.position.toString(),
        serviceItemType: _.find(Object.values(SERVICE_ITEMS), { id: item.serviceItemTypeId })?.name
    }));
    items = items?.filter((item) => item.option == option.index);

    // Get total
    var sub_total = 0;
    var tax_total = 0;
    items?.forEach((item, index) => {
        sub_total += items[index]?.calculatedPrice;
        if (item.taxable && quote.taxRate) tax_total += item.calculatedPrice * quote.taxRate / 100;
    });
    var grand_total = (tax_total + sub_total);

    const columns = [
        { name: 'Name', field: 'name', width: 30 },
        { name: 'Qty', field: 'count', width: 20 },
        { name: 'Price', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: 15 },
        { name: 'Total', field: 'calculatedPrice', type: 'number', format: 'usd', text_align: 'right', width: 20 },
        { name: 'Tax', field: 'taxable', type: 'checkbox', format: 'usd', width: 10},
    ];

    const groupByOptions = {
        fields: ['serviceItemTypePosition'],
        display: ['serviceItemType'],
        direction: ['asc'],
        collapsible: true,
        defaultOpen: true,
    };

    const orderByOptions = {
        fields: ['serviceItemId'],
        direction: ['asc'],
        collapsible: true,
        defaultOpen: true,
    };

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <>
            <h2>Description</h2>
            <p>{ quote?.description }</p>
            <Table
                data={items}
                show_limit={false}
                show_search={true}
                active_field={'id'}
                highlight_search={true}
                columns={columns}
                order_by={orderByOptions}
                group_by={groupByOptions}
            />

            <h4 className="text-right mr-2">SUBTOTAL: &nbsp; ${toolbox.formatNumber(sub_total)}</h4>
            <h4 className="text-right mr-2">TAX (${quote?.taxRate}%): &nbsp; ${toolbox.formatNumber(tax_total)}</h4>
            <h4 className="text-right mr-2">GRAND TOTAL: &nbsp; ${toolbox.formatNumber(grand_total)}</h4>

            {quote?.options?.length > 1 && <div>
                <button className="btn btn-sm btn-success float-left mr-2 my-2 mb-3" onClick={() => props.handleApproval(option.index)}>APPROVE THIS OPTION</button>
            </div>}
        </>
    );
};

export default ServiceItemsOption;