import * as actions_users from 'actions/users-actions';
import * as actions_timeclock from 'actions/timeclock-actions';
import * as toolbox from 'components/common/toolbox';
import DatePicker from "react-multi-date-picker"
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { CloseX, Select } from 'enspire-manager-framework';
import { Dropdown } from 'react-bootstrap';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

export default function Timesheets(props) {
	
	const seg = 4;
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

    const employees = useSelector((store) => store.employees.employees);
    const users = useSelector(store => store.users);
    const user_permissions = useSelector(store => store.users?.user_permissions);
	const settings = useSelector(store => store.settings.settings);
	const timeSheet = useSelector(store => store.users?.time_sheet?.[0]) ?? {};
    const checkedIn = useSelector(store => store.users?.time_sheet?.[1]) ?? {};

	const [showAnalytics, setShowAnalytics] = useState(false);
	const [startDate, setStartDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));

	/* Effects -----------------------------------------------------------------------------------------------------------------*/
	
	useEffect(() => {
		dispatch(actions_timeclock.subTimeSheets(params.handle, (props.source == 'timeClock') ? users.user.id : null));

        return () => {
            var unsubscribe = users.time_sheets_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
	}, []);

	useEffect(() => {
		if (startDate) dispatch(actions_timeclock.getTimeSheet(params.handle, [moment(startDate).toDate(), moment(startDate).add(6, 'days').toDate()]));
	}, [startDate]);

	useEffect(() => {
		setShowAnalytics(false);
		setTimeout(() => {
			setShowAnalytics(true);
		}, 150);
	}, []);

	/* Handlers --------------------------*/

	const handleSelect = (event) => {
		setStartDate(event.target.value);
	}
	const openEditTimeClock = (id) => {
		let location = toolbox.modifyPath(routeLocation.pathname, seg, id + '/time-clock/' + moment(startDate).format('X') + '/' + moment(startDate).add(6, 'days').format('X')); 
		history.push({ pathname: location });
	}
	const openEditCheckin = (id) => {
		let location = toolbox.modifyPath(routeLocation.pathname, seg, id + '/check-in'); 
		history.push({ pathname: location });
	}
	const openActivityLog = () => {
	};

	/* Constants -----------------------------------------------------------------------------------------------------------------*/
	
	var totalTime = 0;

	const days = 7;

	const timecards = Object.entries(timeSheet).map(([userId, entriesArray]) => {
		var entries = [];
		entriesArray.forEach((date) => entries = entries.concat(date.entries));
		entries = _.orderBy(entries, ['time-in'], ['asc']);

		var secondsAccumulated = 0;
		var daily = {};
		var missingClockout = [];

		if (!entries.length) return null;

		// calculate totals
		entries.forEach((entry) => {
			if (entry?.['time-out'] && entry?.['time-in']) {
				const momentIn = moment(entry['time-in'].toDate());
				const momentOut = moment(entry['time-out'].toDate());
				const secondsEntry = momentOut.diff(momentIn, 'seconds');

				var current = daily[momentIn.format('YYYY-MM-DD')] ?? 0;

				daily[momentIn.format('YYYY-MM-DD')] = current + secondsEntry;
				secondsAccumulated += secondsEntry;
				totalTime += secondsEntry;
			} else if (!entry?.['time-out'] && entry?.['time-in']) {
				missingClockout.push(moment(entry['time-in'].toDate()).toDate());
			}
		});

		var secondsCheckedIn = {
			enroute: 0,
			onsite: 0,
		};
		var accrue = null;
		var prevTime = null;
		var missingCheckout = [];

		checkedIn[userId]?.forEach((checkin) => {
			let entries = _.orderBy(checkin.entries, ['time'], ['asc']);
			entries.forEach((entry) => {

				const momentIn = (moment(prevTime?.seconds, 'X').isValid()) ? moment(prevTime?.seconds, 'X') : null;
				const momentOut = (moment(entry.time?.seconds, 'X').isValid()) ? moment(entry.time?.seconds, 'X') : null;
				const secondsEntry = (momentIn && momentOut) ? momentOut.diff(momentIn, 'seconds') : 0;

				switch (entry.type) {
	
					case 'enroute':
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = entry.time;
						accrue = 'enroute';
					break;
					case 'onsite':
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = entry.time;
						accrue = 'onsite';
					break;
					case 'checkout':
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = null;
						accrue = null;
					break;
				}
			});
			if (accrue) missingCheckout.push(moment(prevTime?.seconds, 'X').toDate());
		});;

		const percentEnroute = secondsCheckedIn.enroute / secondsAccumulated * 100;
		const percentOnsite = secondsCheckedIn.onsite / secondsAccumulated * 100;

		const employee = _.find(employees, (o) => { return o.contact?.email == userId });

		return (
			<li key={ userId } className="success-element ui-sortable-handle animated fadeIn animation-delay-2">
				<span className="float-right" style={{ fontSize: '28px' }}>
					{ moment.duration(secondsAccumulated, 'seconds').format("hh:mm", { trim: false }) } <small>hrs</small>
					<Dropdown title="More Options" className={'text-muted float-right ml-2' + ((numPanels(1)) ? 'mr-n4':'')} role="button" drop="left">
						<Dropdown.Toggle className="no-caret" as="span"><span className="material-icons">more_vert</span></Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item className="py-2" onClick={ openEditTimeClock.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
								<i className="fas fa-clock pr-1"></i> Edit Time Clock
							</Dropdown.Item>
							<Dropdown.Item className="py-2" onClick={ openEditCheckin.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
								<i className="fas fa-calendar-check pr-1"></i> Edit Check In/Out
							</Dropdown.Item>
							{/* <Dropdown.Item className="py-2" onClick={ openActivityLog } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
								<i className="fas fa-history pr-1"></i> Activity Log
							</Dropdown.Item> */}
						</Dropdown.Menu>
					</Dropdown>
				</span>
				<p className="font-weight-bold m-0">{`${employee.contact.firstName} ${employee.contact.lastName}`}</p>
				<p>{ userId }</p>
				<div className="agile-detail">
					<div className="progress">
						<div className="progress-bar" role="progressbar" style={{ width: `${percentOnsite}%` }}></div>
						<div className="progress-bar bg-info" role="progressbar" style={{ width: `${percentEnroute}%` }}></div>
					</div>
					{
						Object.entries(daily)?.map((line, index) => {
							return (
								<div key={index} className="d-flex justify-content-center mt-2">
									<span className="pr-2">{ moment(line[0]).format('dddd, MMM D, YYYY') }</span>
									<div style={{ height: '1.2rem', borderBottom: '2px dotted #aaaaaa', flexGrow: 2 }}></div>
									<span className="pl-2">{ moment.duration(line[1], 'seconds')?.format('hh:mm', { trim: false }) }</span>
								</div>
							);
						})
					}
					{ missingClockout.map((clockout) => 
						<p className="btn btn-warning btn-sm mt-2" onClick={ openEditTimeClock.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
							Resolve Missing "Clock Out": &nbsp; { moment(clockout).format('dddd, MMM D, YYYY') }
						</p>) 
					}
					{ missingCheckout.map((checkout) => 
						<p className="btn btn-warning btn-sm mt-2" onClick={ openEditCheckin.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
							Missing Work Order "Check Out": { moment(checkout).format('dddd, MMM D, YYYY') }
						</p>) 
					}
					<p className="float-right mt-3 mb-0">
						<span className="font-weight-bold">Onsite:</span> { moment.utc((secondsCheckedIn.onsite + 1) * 1000)?.format('H:mm') } |&nbsp; 
						<span className="font-weight-bold">Enroute:</span> { moment.utc((secondsCheckedIn.enroute + 1) * 1000)?.format('H:mm') } |&nbsp;
						<span className="font-weight-bold">Internal:</span> { moment.utc((0 + 1) * 1000)?.format('H:mm') }
					</p>
					<p className="mt-3 mb-0">Period: {days + 1} days</p>
				</div>
			</li>
		);
	});

	/* Constants --------------------------*/

	const timesheet = _.find(users.time_sheets, { id: startDate });

	let startDayOffset = parseInt(settings.timeclock?.start_work_week ?? '0');
	let current = moment().startOf('week').add(startDayOffset, 'days');
	let periodOptions = [];

	if (props.source == 'timeClock') {
		for (let card of users.time_cards) {
			let date = moment(card.id);
			 periodOptions.push(
				<option value={ date.format('YYYY-MM-DD') }>{ `${date.format('ddd MMM Do, YYYY')} - Total Time: ${moment.duration(card.totalTime, 'seconds').format("hh:mm", { trim: false })} hrs` }</option>
			)
		}

	} else {
		for (let sheet of users.time_sheets) {
			let date = moment(sheet.id);
			 periodOptions.push(
				<option value={ date.format('YYYY-MM-DD') }>{ `${date.format('ddd MMM Do, YYYY')} - Total Time: ${moment.duration(sheet.totalTime, 'seconds').format("hh:mm", { trim: false })} hrs` }</option>
			)
		}
	}

	// for (let i = 0; i < 24; i++) {
	// 	let date = current.clone().subtract(i, 'week');
	// 	let timesheetEntry = _.find(users.time_sheets, { id: date.format('YYYY-MM-DD') });
	// 	if (timesheetEntry) periodOptions.push(
	// 		<option value={ date.format('YYYY-MM-DD') }>{ date.format('ddd MMM Do, YYYY') + ((timesheetEntry) ? ` - Total Time: ${moment.duration(timesheetEntry.totalTime, 'seconds').format("hh:mm")} hrs` : '') }</option>
	// 	)
	// 	moment.duration(totalTime, 'seconds').format("hh:mm");
	// }

	return (
		<>
			{ showAnalytics &&
				<>
					{ props.source == 'employees' &&
						<div id="employee-analytics" className="col-12 mb-3 animated fadeIn">
							{ numPanels(1) && <CloseX onClick={() => history.goBack()} className="mr-3 mt-0" color={ '#aaaaaa' } /> }
							<h1 className="analytics-title">Weekly Time Sheet</h1>
							<div className="middleOfLine text-muted mt-4">
								<span className="fa-stack fa-2x">
									<i className="fas fa-circle fa-stack-2x"></i>
									<i className="far fa-clock fa-stack-1x fa-inverse"></i>
								</span>
							</div>
						</div>
					}
					<Select
						className="mt-2 mb-3"
						label="Time Period Starting"
						noLabel={false} 
						name={'assetTypeId'}
						// value={selectedTypeId}
						onChange={handleSelect}
					>
						<option value={ moment().startOf('week').format('YYYY-MM-DD') }>{ moment().startOf('week').format('ddd MMM Do, YYYY') + ' - Current' }</option>
						{ periodOptions }
					</Select>

					{ timesheet &&
						<a className="btn btn-secondary float-right" href={ timesheet.downloadUrl } target="_blank">
							<i className="fas fa-print mr-1"></i> Print Timesheet
						</a>
					}
					<div style={{ clear: 'both' }}></div>
					<ul className="sortable-list agile-list mt-3" id="todo">
						{timecards}
						<li className="warning-element ui-sortable-handle animated fadeIn animation-delay-4">
							<div className="agile-detail">
								<span className="float-right" style={{ fontSize: '18px' }}>
									{ moment.duration(totalTime, 'seconds').format("hh:mm", { trim: false }) } <small>hrs</small>
								</span>
								<h2 className="m-0">Total</h2>
							</div>
						</li>
					</ul>
				</>
			}
		</>
	);
};

