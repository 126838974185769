import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_schedule from 'actions/schedule-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { Avatar, FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { Button, ButtonGroup } from 'react-bootstrap';
import { APPS, LOG, ACTION, ROLES } from 'components/constants';
import { confirmAdd, confirmArchive, getProfileName } from 'components/common/toolbox';
import { imageSize } from 'components/common/toolbox';
import { service_request_fields, service_request_form_layout } from './asset-request-form-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const _ = require('lodash');

export default function ServiceRequestForm(props) {
	
	/* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const seg = 5;
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();

	const admin = useSelector((store) => store.admin);
	const assets = useSelector((store) => store.assets.assets);
	const employees = useSelector((store) => store.employees.employees);
	const selectedAssetTypeId = useSelector((store) => store.assets.selected_asset_type_id);
	const selectedCustomerId = useSelector((store) => store.assets.selected_customer_id);
	const serviceRequests = useSelector((store) => store.serviceRequests);
	const serviceRequest = useSelector((store) => store.serviceRequests.service_request);
	const settings = useSelector((store) => store.settings);
    const user_permissions = useSelector(store => store.users?.user_permissions);
	const workOrders = useSelector((store) => store.workOrders);

	const [serviceFormType, setServiceFormType] = useState('asset_request');
	const [focusedInput, setFocused] = useState(null);
	const [formError, setFormError] = useState(null);
	const [original, setOriginal] = useState({});
	const [request, setRequest] = useState(service_request_fields(
		(settings.settings?.work_orders?.default_technician_id) ? settings.settings.work_orders.default_technician_id : ''
	));
	
	const asset = _.find(assets, { id: params.asset_id });
	// const serviceRequest = serviceRequests.find((request) => request.id == params.request_id);
	const fromSchedule = props?.fromSchedule;
    const segments = props.location.pathname.split('/').length;

	/* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		if (parseInt(params.request_id)) {
			populateForm();
		} else {
			setRequest(prev => ({ ...prev, customerId: selectedCustomerId, assetTypeId: selectedAssetTypeId, assetId: params.asset_id }));
			handleDate('requestDate', request.requestDate);
		}
	}, [serviceRequest, selectedCustomerId, selectedAssetTypeId]);

	// If from schedule, set the start and end date to the dragged event
	useEffect(() => {
		if (fromSchedule) {
			setRequest(prev => ({ ...prev, requesttDate: workOrders.new_service_dragging.humanStartDate.toDate() }));
		}
	}, [serviceRequest]);

	/* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

	const handleChange = (e, value) => {
		let newValue = (value) ? value : e.target.value;
		let form_error = _.filter(formError, (o) => { return o.field !== e.target.name; });
		setFormError(form_error);
		setRequest(prev => ({ ...prev, [e.target.name]: newValue }));
	};
	const handleDate = (field, date) => {
		var form_error = _.filter(formError, (o) => { return o.field !== field; });
		setFormError(form_error);
		setRequest(prev => ({ ...prev, requestDate: date, endDate: moment(date).add(settings.settings?.work_orders?.default_duration ?? 2, 'hours').toDate() }));
	};
	const handlePromote = (field) => {
		var newRequest = { ...request };
		let currentFav = newRequest.imageUrl;
		let elementIndex = parseInt(field.split('imageUrlAlt')[1]);
		
		newRequest.imageUrl = newRequest['imageUrlAlt' + elementIndex];
		newRequest['imageUrlAlt' + elementIndex] = currentFav;

		setRequest(newRequest);
	}
	const handleUpload = (results) => {
		var newRequest = { ...request };

		if (Array.isArray(results)) { // Upload new image
			for (var result of results) {
				var [field, filename] = result;

				if (!newRequest.imageUrl) {
					newRequest.imageUrl = filename;
				} else {
					for (let index = 0; index < 6; index++) {
						if (!newRequest['imageUrlAlt' + index]) {
							newRequest['imageUrlAlt' + index] = filename;
							break;
						}
					}
				}
			}
		} else if (results == 'imageUrl') { // delete main image
			if (newRequest['imageUrlAlt0']) {
				newRequest.imageUrl = newRequest['imageUrlAlt0'];
				for (let index = 0; index < 6; index++) {
					newRequest['imageUrlAlt' + index] = newRequest['imageUrlAlt' + (index + 1)] ?? null;
				}
			} else {
				newRequest.imageUrl = null;
			}

		} else { // Delete alt image
			let elementIndex = parseInt(results.split('imageUrlAlt')[1]);
			for (let index = elementIndex; index < 6; index++) {
				newRequest['imageUrlAlt' + index] = newRequest['imageUrlAlt' + (index + 1)] ?? null;
			}
		}
		setRequest(newRequest);
	};

	/* Typeahead Handlers --------------------------*/

	const handleTypeahead = (field, result) => {
		var form_error = _.filter(formError, (o) => { return o.field !== field; });
		setFormError(form_error);
		if (result?.[0]?.customOption) {
			confirmAdd("Vendor", () => {
				dispatch(actions_profiles.saveProfile({
					handle: params.handle, 
					profileId: '0', 
					profileForm: { displayName: result?.[0]?.target }, 
					profileType: 'VENDOR',
					callback: (profileId) => {
						setRequest(prev => ({ ...prev, [field]: [{ target: result?.[0]?.target, id: profileId }] }));
						dispatch(actions_admin.saveActivity(params.handle, LOG.VENDOR.key, ACTION.CREATED.key, profileId, result?.[0]?.target ));
					}
				}));	
			});
		} else if (result?.[0]?.id) {
			setRequest(prev => ({ ...prev, [field]: [{ target: result?.[0]?.target, id: result?.[0]?.id }] }));
		} else {
			setRequest(prev => ({ ...prev, [field]: [] }));
		}
	};
	
	/* Actions ------------------------------------------------------------------------------------------------------------------------------------*/

	const populateForm = async () => {
		if (serviceRequest) {
			let vendorName = await getProfileName(params.handle, serviceRequest.vendorId);
			let myServiceRequest = { 
				...serviceRequest, 
				requestDate: serviceRequest.requestDate.toDate(),
				vendorId: (serviceRequest.vendorId) ? [{ target: vendorName, id: serviceRequest.vendorId }] : [],
			}
			const images = serviceRequest.imagesAlt;
			images?.forEach((image, index) => {
				myServiceRequest['imageUrlAlt' + index] = image;
			});
			setRequest(myServiceRequest);
			setOriginal(myServiceRequest);
		};
	};
	const toggleLightbox = () => {
		let imagesAlt = asset.imagesAlt?.map((imageUrl) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(asset.imageUrl, 'lg') },
			...(asset.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}
	const archiveRequest = () => {
		dispatch(actions_service_requests.archiveServiceRequest(params.handle, request, (serviceRequestId) => { 
			dispatch(actions_admin.saveActivity(params.handle, LOG.SERVICE_REQUEST.key, ACTION.ARCHIVED.key, params.request_id, request.description ));
			history.go((segments > seg+5) ? (-3) : (-2)); // extra back if Work Order column showing
		}));
	};
	const submitForm = () => {
		var form_error = ValidateForm(request, form_layout);
		setFormError(form_error);

		let newServiceRequest = { ...request,
			vendorId: (request.vendorId?.[0]?.id) ? request.vendorId[0].id : null,
			type: serviceFormType,
		};

		if (serviceFormType == 'request' && params.appId == APPS.ASSETS.id) newServiceRequest.type = 'asset_request';
		if (serviceFormType == 'record' && params.appId == APPS.ASSETS.id) newServiceRequest.type = 'asset_record';
		if (serviceFormType == 'record' && params.appId == APPS.ASSETS.id) newServiceRequest.type = 'asset_record';

		let imagesAlt = [];
		for (let index = 0; index < 5; index++) {
			if (request['imageUrlAlt' + index]) imagesAlt.push(request['imageUrlAlt' + index]);
			delete newServiceRequest['imageUrlAlt' + index];
		}
		newServiceRequest.imagesAlt = imagesAlt;
		
		if (fromSchedule) newServiceRequest.technicianId = workOrders.new_service_dragging.technicianId;

		if (!form_error?.length) {
			dispatch(actions_service_requests.saveServiceRequest(params.handle, newServiceRequest, (serviceRequestId) => {
				if (fromSchedule) dispatch(actions_schedule.cancelNewEvent());
				let action = (parseInt(params.request_id)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
				dispatch(actions_admin.saveActivity(params.handle, LOG.SERVICE_REQUEST.key, action, serviceRequestId, request.description, request, original ));

				if (parseInt(serviceRequestId)) {
					let location = toolbox.modifyPath(props.location.pathname, seg, `form/0/service_requests/${serviceRequestId}/work_orders`, seg+4);
					history.goBack();
					setTimeout(() => { history.push(location); }, 500);
				} else {
					history.goBack();
				}
			}));
		}
	};

	/* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

	const technicians = _.filter(employees, (o) => { return o.roles?.includes(ROLES.TECHNICIAN.id) });
	const classification = _.find(settings.settings_asset_classifications, { id: asset?.classificationId });
	const existing = (params.request_id > 0);
	const serviceType = (serviceFormType == 'asset_record') 
		? 'Service Record' 
		: (serviceFormType == 'asset_request')
			? 'Service Request'
			: 'Recurring Maintenance';

	const assetAvatar = (asset) ? 
		<div className="col-12">
			<div className="profile-image" onClick={ (asset.imageUrl) ? toggleLightbox : null }>
				{ !!asset.imagesAlt?.length &&
					<Avatar
						style={{ position: 'absolute', left: '70px', top: '55px' }}
						color="white"
						bgColor="black"
						size={35}
						fontSize={ '10px' }
						name={`+${asset.imagesAlt.length}`}
						initials={ false }
						border="3px solid white"
						role="button"
					/>
				}
				<Avatar className="mx-auto"
					color="white"
					bgColor="saddleBrown"
					size={90}
					name={asset.name}
					image={imageSize(asset.imageUrl, 'sm')}
					border="4px solid white"
					role={ (asset.imageUrl) ? "button" : null }
				/>
			</div>
			<div className="profile-info pt-1">
				<h1>{asset.name}</h1>
				<h4>
					<span className="mr-3">{`#${asset.unitNumber}`}</span>
					<span className={ 'mb-3 badge badge-secondary' }>{classification?.name}</span>
				</h4>
			</div>
		</div> : null;

	const typeButtons = 
		<ButtonGroup className="d-flex my-3">
			<Button variant={ (serviceFormType == 'asset_request') ? 'primary' : 'default' } onClick={ () => setServiceFormType('asset_request') }>Service Request</Button>
			<Button variant={ (serviceFormType == 'asset_record') ? 'primary' : 'default' } onClick={ () => setServiceFormType('asset_record') }>Service Record</Button>
			<Button variant={ (serviceFormType == 'asset_recurring') ? 'primary' : 'default' } onClick={ () => window.toastr.warning(`This feature will be Released Soon!`) }>Recurring Maintenance</Button>
		</ButtonGroup>

	const form_layout = service_request_form_layout({
		assetAvatar,
		firebase, 
		handle: params.handle, 
		handlePromote,
		serviceFormType,
		serviceRequest: request,
		technicians,
		typeButtons,
		user_permissions,
	});

	/* Render ------------------------------------------------------------------------------------------------------------------------------------*/

	return (

		<ModalForm {...props}
			history={history}
			style={1}
			size={'xl'}
			modal_header={serviceType}
			cancel_button_title="Cancel"
			save_button_title={`Save ${serviceType}`}
			submitFormHandler={submitForm}
			delete_button_title={'Archive'}
			delete_button_callback={(!existing) ? null : () => confirmArchive('Service Request', archiveRequest)}
			show_spinner={serviceRequests?.service_request_save_pending}
			focusedInput={(window.flutter) ? focusedInput : null}
			platform={admin.flutter_platform}
			keyboardPadding={admin.flutter_keyboard}
		>
			{request &&
				<FormBuilder
					callbacks={{
						focus: (event) => setFocused(event.target),
						text: handleChange,
						select: handleChange,
						typeahead: handleTypeahead,
						date: handleDate,
						dropzone: handleUpload,
					}}
					form_error={formError}
					form={form_layout}
					record={request}
				/>
			}
		</ModalForm>
	);
}