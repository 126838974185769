import * as types from 'actions/action-types';

var initialState = {
	settings: null,
	settings_pending: false,
	settings_save_pending: false,
	settings_unsubscribe: null,

	settings_global: null,
	settings_global_pending: false,
	settings_global_save_pending: false,
	settings_global_unsubscribe: null,

	settings_company: null,
	settings_company_pending: false,
	settings_company_save_pending: false,
	settings_company_unsubscribe: null,

	departments: [],
	departments_pending: false,
	departments_save_pending: false,
	departments_unsubscribe: null,

	job_categories: [],
	job_categories_pending: false,
	job_categories_save_pending: false,
	job_categories_unsubscribe: null,

	department_tags: [],
	department_tags_pending: false,
	department_tags_save_pending: false,
	department_tags_unsubscribe: null,

	settings_parts_categories: [],
	settings_parts_categories_pending: false,
	settings_parts_categories_save_pending: false,
	settings_parts_categories_unsubscribe: null,

	settings_asset_classifications: [],
	settings_asset_classifications_pending: false,
	settings_asset_classifications_save_pending: false,
	settings_asset_classifications_unsubscribe: null,

	settings_tasks: [],
	settings_tasks_pending: false,
	settings_tasks_save_pending: false,
	settings_tasks_unsubscribe: null,

	statusModal: false,
	statusTable: null,
	statusId: null,
	currentId: null,
	confirmCallback: null,

	schedulePanelButton: 'open',

	collapsed: false,
};

var final;

const settingsReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.SETTINGS + '_PENDING': pending('settings'); break;
		case types.SETTINGS + '_SAVE_PENDING': save_pending('settings'); break;
		case types.SETTINGS + '_SAVE_FULFILLED': save_fulfilled('settings'); break;
		case types.SETTINGS + '_FULFILLED': fulfilled('settings'); break;

		case types.SETTINGS_COMPANY + '_PENDING': pending('settings_company'); break;
		case types.SETTINGS_COMPANY + '_SAVE_PENDING': save_pending('settings_company'); break;
		case types.SETTINGS_COMPANY + '_FULFILLED': fulfilled('settings_company'); break;

		case types.SETTINGS_GLOBAL + '_PENDING': pending('settings_global'); break;
		case types.SETTINGS_GLOBAL + '_SAVE_PENDING': save_pending('settings_global'); break;
		case types.SETTINGS_GLOBAL + '_FULFILLED': fulfilled('settings_global'); break;

		case types.DEPARTMENTS + '_PENDING': pending('departments'); break;
		case types.DEPARTMENTS + '_SAVE_PENDING': save_pending('departments'); break;
		case types.DEPARTMENTS + '_FULFILLED': fulfilled('departments'); break;

		case types.JOB_CATEGORIES + '_PENDING': pending('job_categories'); break;
		case types.JOB_CATEGORIES + '_SAVE_PENDING': save_pending('job_categories'); break;
		case types.JOB_CATEGORIES + '_FULFILLED': fulfilled('job_categories'); break;

		case types.DEPARTMENT_TAGS + '_PENDING': pending('department_tags'); break;
		case types.DEPARTMENT_TAGS + '_SAVE_PENDING': save_pending('department_tags'); break;
		case types.DEPARTMENT_TAGS + '_FULFILLED': fulfilled('department_tags'); break;

		case types.SETTINGS_ASSET_TYPES + '_PENDING': pending('settings_asset_types'); break;
		case types.SETTINGS_ASSET_TYPES + '_FULFILLED': fulfilled('settings_asset_types'); break;
		case types.SETTINGS_ASSET_TYPES + '_SAVE_PENDING': save_pending('settings_asset_types'); break;
		case types.SETTINGS_ASSET_TYPES + '_SAVE_FULFILLED': save_fulfilled('settings_asset_types'); break;

		case types.SETTINGS_ASSET_CLASSIFICATIONS + '_PENDING': pending('settings_asset_classifications'); break;
		case types.SETTINGS_ASSET_CLASSIFICATIONS + '_FULFILLED': fulfilled('settings_asset_classifications'); break;
		case types.SETTINGS_ASSET_CLASSIFICATIONS + '_SAVE_PENDING': save_pending('settings_asset_classifications'); break;
		case types.SETTINGS_ASSET_CLASSIFICATIONS + '_SAVE_FULFILLED': save_fulfilled('settings_asset_classifications'); break;

		case types.SETTINGS_TASKS + '_PENDING': pending('settings_tasks'); break;
		case types.SETTINGS_TASKS + '_FULFILLED': fulfilled('settings_tasks'); break;
		case types.SETTINGS_TASKS + '_SAVE_PENDING': save_pending('settings_tasks'); break;
		case types.SETTINGS_TASKS + '_SAVE_FULFILLED': save_fulfilled('settings_tasks'); break;

		case types.SETTINGS_PARTS_CATEGORIES + '_PENDING': pending('settings_parts_categories'); break;
		case types.SETTINGS_PARTS_CATEGORIES + '_FULFILLED': fulfilled('settings_parts_categories'); break;
		case types.SETTINGS_PARTS_CATEGORIES + '_SAVE_PENDING': save_pending('settings_parts_categories'); break;
		case types.SETTINGS_PARTS_CATEGORIES + '_SAVE_FULFILLED': save_fulfilled('settings_parts_categories'); break;

		case types.SETTINGS_INVENTORY + '_PENDING': pending('settings_inventory'); break;
		case types.SETTINGS_INVENTORY + '_FULFILLED': fulfilled('settings_inventory'); break;
		case types.SETTINGS_INVENTORY + '_SAVE_PENDING': save_pending('settings_inventory'); break;
		case types.SETTINGS_INVENTORY + '_SAVE_FULFILLED': save_fulfilled('settings_inventory'); break;

		case types.UPDATE_BUTTON: updateButton(); break;

		case types.SETTINGS_TOGGLE_COLLAPSED:
			state = { ...state, collapsed: !state.collapsed };
			break;
		case types.SETTINGS_STATUS_MODAL_OPEN:
			state = { ...state,
				statusModal: !state.statusModal,
				statusTable: action.statusTable,
				statusId: action.statusId,
				currentId: action.currentId,
				confirmCallback: action.confirmCallback,
			};
			break;
		case types.SETTINGS_STATUS_MODAL_CLOSE:
			state = { ...state,
				statusModal: false,
			};
			break;

		default:
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = {
			...state,
			[table + '_save_pending']: false,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function updateButton() {
		state = {
			...state,
			[action.button]: action.update,
		};
	}
};
export default settingsReducer;


