import React from 'react';
import ResultsServiceRequests from '../components/results-service-requests';
import { APPS, COUNTS } from 'components/constants';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Expanding } from 'components/common/expanding';
import { Widget } from 'components/dashboard/components/widget';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ResultsQuotes from '../components/results-quotes';

export default function QuotesTasks(props) {

	const params = useParams();

    const counts = useSelector((store) => store.admin.dashboard_counts);

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'quote-drafts' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_QUOTES_DRAFTS.id] ?? 0 : counts?.[COUNTS.SERVICE_QUOTES_DRAFTS.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-message-pen fa-3x"></i> }
					text={ 'Quote Drafts' }
					value="quote-drafts"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'quote-pending' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_QUOTES_PENDING.id] ?? 0 : counts?.[COUNTS.SERVICE_QUOTES_PENDING.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-message-question fa-3x"></i> }
					text={ 'Pending Quotes' }
					value="quote-pending"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'quote-declined' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_QUOTES_PENDING.id] ?? 0 : counts?.[COUNTS.SERVICE_QUOTES_PENDING.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-message-xmark fa-3x"></i> }
					text={ 'Declined Quotes' }
					value="quote-declined"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'quote-accepted' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_QUOTES_ACCEPTED.id] ?? 0 : counts?.[COUNTS.SERVICE_QUOTES_ACCEPTED.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-message-check fa-3x"></i> }
					text={ 'Accepted Quotes' }
					value="quote-accepted"
					onClick={ props.handleClick }
				/>
			</div>

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'quote-drafts' }>
				<ResultsQuotes />
			</Expanding>
			<Expanding open={ params.widget_id == 'quote-pending' }>
				<ResultsQuotes />
			</Expanding>
			<Expanding open={ params.widget_id == 'quote-declined' }>
				<ResultsQuotes />
			</Expanding>
			<Expanding open={ params.widget_id == 'quote-accepted' }>
				<ResultsQuotes />
			</Expanding>
		</div>
	);
};
