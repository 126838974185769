import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { toastr } from 'react-redux-toastr';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  ATTACHMENTS
/*-----------------------------------------------*/

export function subAttachments(handle, id, source, prefix, { field } = {}) {

	return dispatch => {

		dispatch({ type: prefix + '_' + field.toUpperCase() + '_CLEAR' });
		dispatch({ type: prefix + '_' + field.toUpperCase() + '_PENDING' });

		var unsubscribe = firestore.collection(`${handle}/${source}/${source}/${id}/attachments/`).doc(id).onSnapshot((doc) => {

			if (doc.exists) {
				var attachments = doc.data()[field];
				dispatch({ type: prefix + '_' + field.toUpperCase() + '_FULFILLED', data: attachments, unsubscribe });
			} else {
				dispatch({ type: prefix + '_' + field.toUpperCase() + '_FULFILLED', data: [], unsubscribe });
			}
		});
	};
}

export function saveAttachment(handle, id, attachments, source, prefix, callback, { field } = {}) {

	return async dispatch => {

		dispatch({ type: prefix + '_' + field.toUpperCase() + "_SAVE_PENDING" });

		const batch = firestore.batch();

		batch.set(firestore.collection(`${handle}/${source}/${source}/${id}/attachments`).doc(id), { [field]: attachments }, { merge: true });
		// Update serviceItems so it will reload with updated external invoices
		if (field == 'external_invoices') {
			batch.set(firestore.collection(`${handle}/${source}/${source}/${id}/serviceItems`).doc(id), { updated: new Date() }, { merge: true });
		}
		batch.commit().then(() => {
			window.toastr.success('The Attachment has been successfully saved/updated');
			dispatch({ type: prefix + '_' + field.toUpperCase() + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(id);
		}).catch((error) => {
			toolbox.process_error(error, 'Attachment NOT Saved');
		});
	};
}
// export function deleteAttachment(handle, source, sourceId, attachment, callback) {

// 	return async dispatch => {

// 		dispatch({ type: types['ATTACHMENT'] + "_SAVE_PENDING" });

// 		var storageRef = firebase.storage().ref().child('/' + handle + '/' + attachment.fileName);
// 		const batch = firestore.batch();

// 		batch.set(firestore.collection(handle + '/' + source + '/' + source + '/' + sourceId + '/attachments').doc(sourceId),
// 			{ attachments: { [attachment.id]: firebase.firestore.FieldValue.delete() } }, { merge: true });

// 		batch.commit().then(() => {
// 			storageRef.delete().then(() => {
// 				dispatch({ type: types['ATTACHMENT'] + '_SAVE_FULFILLED' });

// 				window.toastr.success('The Attachment has been deleted');
// 				if (typeof callback === 'function') callback();
// 			}).catch((error) => {
// 				dispatch({ type: types['ATTACHMENT'] + '_SAVE_FULFILLED' });
// 				console.error(error);
// 			});

// 		}).catch((error) => {
// 			toolbox.process_error(error, 'Attachment NOT Deleted');
// 		});
// 	};
// }

/*-----------------------------------------------*/
/*  FUNCTIONS
/*-----------------------------------------------*/

async function nextAttachmentId(handle, batch) {
	const table = 'attachments';
	const field = 'nextAttachmentId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

