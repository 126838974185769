import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DashboardTasks from './dashboard-tasks';

export default function DashboardLayout(props) {

	const seg = 3;
	const params = useParams();
	const history = useHistory();
	const routeLocation = useLocation();

	const [currenWidget, setCurrentWidget] = useState(false);

	useEffect(() => {
        setCurrentWidget(params.widget_id);
    }, []);

	const handleClick = (widget_id) => {
		let segments = routeLocation.pathname.split('/').length;
        let num = (segments >= 12) ? -2 : (segments >= 10) ? -1 : 0;
		if (num) history.go(num);

		setTimeout(() => {
			let id = (widget_id !== currenWidget) ? widget_id : 'dashboard';
			var location = toolbox.modifyPath(routeLocation?.pathname, seg, `${id}`, seg);
			history.replace({ pathname: location });
			setCurrentWidget(id);
			// setLoading(false);
		}, 50);
	}


    return (
		<>
			<DashboardTasks handleClick={ handleClick } currenWidget={ currenWidget } />
			<div className="mb-5"></div>

			{/* Service Requests ----------------------------------------------------------------------- */}

			{/* <div className="row mb-2 no-gutters" >
				<h2 className="col-12 ml-2">Service Requests</h2>
				<div className="col p-1">
					<Widget 
						active={ params.widget_id == 'sr-unassigned' }
						backgroundColor={ WORKORDERS.UNASSIGNED.color }
						icon={ 'fas fa-times-circle fa-3x' }
						text={ 'Service Requests Unassigned' }
						title={ (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						// title={ '$' + (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						value="sr-unassigned"
						onClick={ handleClick }
					/>
				</div>
				<Expanding open={ params.widget_id == 'sr-unassigned' }>
					<OpenWorkOrders source="technician" />
				</Expanding>
			</div> */}

			{/* Work Orders ----------------------------------------------------------------------- */}

			{/* <div className="row mb-2 no-gutters" >
				<h2 className="col-12 ml-2">Work Orders</h2>
				<div className="col p-1">
					<Widget 
						active={ params.widget_id == 'my-workorders' }
						color_number={ 3 } 
						icon={ 'fas fa-file-contract fa-3x' }
						text={ 'My Work Orders' }
						title={ (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						value="my-workorders"
						onClick={ handleClick }
					/>
				</div>
				<div className="col p-1">
					<Widget 
						active={ params.widget_id == 'wo-unassigned' }
						backgroundColor={ WORKORDERS.UNASSIGNED.color }
						icon={ 'fas fa-exclamation-circle fa-3x' }
						text={ 'Work Orders Unassigned' }
						title={ (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						// title={ '$' + (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						value="wo-unassigned"
						onClick={ handleClick }
					/>
				</div>

				<Expanding open={ params.widget_id == 'my-workorders' }>
					<CheckinWorkOrders source="dashboard" />
				</Expanding>

				<Expanding open={ params.widget_id == 'wo-unassigned' }>
					<OpenWorkOrders source="technician" />
				</Expanding>

				<div className="col-6 p-1">
					<Widget 
						active={ params.widget_id == 'wo-inprogress' }
						backgroundColor={ WORKORDERS.INPROGRESS.color }
						icon={ 'fas fa-spinner fa-3x' }
						text={ 'Work Orders "In Progress"' }
						title={ (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						// title={ '$' + (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
						value="wo-inprogress"
						onClick={ handleClick }
						/>
				</div>

				<Expanding open={ params.widget_id == 'wo-inprogress' }>
					<OpenWorkOrders source="technician" />
				</Expanding>
			</div> */}
		</>
	);
};
