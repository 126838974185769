import React from 'react';
import { useParams } from 'react-router-dom';
import WorkOrdersTasks from './tasks-work-orders';
import ServiceRequestsTasks from './tasks-service-requests';
import ExpandDetails from 'components/common/expand-details';
import QuotesTasks from './tasks-quotes';

export default function DashboardTasks(props) {

    return (
		<>
		{/* <ExpandDetails limitHeight={ (props.currenWidget == 'dashboard') ? 147 : 260 }> */}
			<h2 className="col-12 ml-2">Work Orders</h2>
			{/* <ExpandDetails limitHeight={ (props.currenWidget == 'dashboard') ? 147 : 260 }> */}
				<WorkOrdersTasks handleClick={ props.handleClick } />
			{/* </ExpandDetails> */}

			<h2 className="col-12 ml-2">Service Requests</h2>
			<ServiceRequestsTasks handleClick={ props.handleClick } />

			<h2 className="col-12 ml-2">Quotes</h2>
			<QuotesTasks handleClick={ props.handleClick } />

		{/* </ExpandDetails> */}
		</>
	);
};
