import * as actions_quotes from 'actions/quotes-actions';
import React, { useState } from 'react';
import SignaturePad from 'signature_pad';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { ModalAlert } from 'enspire-manager-framework';
import { QUOTES } from 'components/constants';
import { UCFirst } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const storage = firebase.storage();
const storageRef = storage.ref();

export default function Signature(props) {
    
    const params = useParams();
    const dispatch = useDispatch();

    const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
    const quote = useSelector((state) => state.quotes.quote);

    const [ declined, setDeclined ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ saved, setSaved ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ signaturePad, setSignaturePad ] = useState(null);

    const validLink = (quote?.validateSignature == params.validateSignature && params.validateSignature > Date.now())

    React.useEffect(() => {
        
        const canvas = window.document.querySelector("canvas");
        if (canvas) {
            const signaturePad = new SignaturePad(canvas);
            setSignaturePad(signaturePad);
            window.onresize = resizeCanvas;
            resizeCanvas();
            
            function resizeCanvas() {
                const ratio =  Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext("2d").scale(ratio, ratio);
                signaturePad.clear();
            }
    
            return () => window.onresize = null;
        }
    }, []);

    const handleAccept = () => {
        
        if (!signaturePad.isEmpty()) {
            
            var dataUrl = signaturePad.toDataURL();
            var ref = storageRef.child(`${params.handle}/signatures/${params.quote_id}`);
            var uploadTask = ref.putString(dataUrl, 'data_url');
    
            setSaving(true);
    
            uploadTask.on('state_changed', (snapshot) => {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, (error) => { 
                console.error(error);
            }, async () => {
    
                let signatureUrl = await uploadTask.snapshot.ref.getDownloadURL();
    
                try {
                    if (params.item == 'quote') {
                        // Cloud function is called so that updates can be made in an authorized environment
                        const response = await axios({
							method: 'post',
                            url: 'https://us-central1-mobiletrack-enterprise.cloudfunctions.net/customerSignsQuote',
							// url: 'http://127.0.0.1:5001/mobiletrack-enterprise/us-central1/customerSignsQuote',
							data: {
                                handle: params.handle,
                                quoteId: params.id,
                                option: params.option,
                                email: params.email,
                                validateSignature: params.validateSignature,
                                action: 'accept',
                                signatureUrl,
                            }
						});
                    }
                } catch(err) {
                    console.log(err);
                }
    
                setTimeout(() => {
                    setSaving(false);
                    setSaved(true);
                }, 500);
            });	

        } else {
            window.toastr.warning(`Please use your mouse or finger (on touch devices) to enter a signature.`, 'Signature Required!');
        }
    }

    const handleClear = () => { signaturePad.clear(); }
    
    const handleDecline = () => {
        ModalAlert({
            title: 'Are you sure?',
            text: "Declining the Quote will halt further progress on your service",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Decline Quote',
            callback_success: () => {
                dispatch(actions_quotes.updateQuote(params.handle, params.id, { statusId: QUOTES.REJECTED.id }));
            }
        });

    }

    return (
        <div id="signature-body">

            <div id="signature-pad" className="signature-pad">
                { quote.statusId == QUOTES.ACCEPTED.id && 
                    <h1 className="mt-5 text-center font-weight-bold">
                        <i className="fa-regular fa-circle-check fa-3x mb-5 text-success"></i><br/>
                        Quote Approved!  Thank You!<br/><br/><small className="font-weight-lighter">You will hear from us shortly.</small>
                    </h1>
                }
                { quote.statusId == QUOTES.REJECTED.id && 
                    <h1 className="mt-5 text-center font-weight-bold">
                        <i className="fa-regular fa-circle-xmark fa-3x mb-5 text-danger"></i><br/>
                        Quote Declined!  <br/><br/><small className="font-weight-lighter">Please call our office if there's anything else we can do to serve you!</small>
                    </h1>
                }
                { quote.statusId == QUOTES.PENDING.id && !validLink && 
                    <h1 className="mt-5 text-center font-weight-bold">
                        <i className="fa-regular fa-circle-exclamation fa-3x mb-5 text-info"></i><br/>
                        Invalid or Expired Link  <br/><br/><small className="font-weight-lighter">Please call our office to request an updated link!</small>
                    </h1>
                    // <h2 className="text-center mt-5">Invalid or Expired Link</h2>
                }
                { quote.statusId == QUOTES.PENDING.id && validLink &&
                    <>
                        <div className="signature-pad--body">
                            <canvas></canvas>
                        </div>
                        <div className="signature-pad--footer">
                            <div className="description mb-2">Sign above</div>

                            <div style={{ color: '#666666' }}>
                                { errorMessage
                                    ?   <h2 style={{ color: 'red' }}>{ errorMessage }</h2>
                                    :   <p style={{ fontSize: '16px' }}>I have read <strong>{ UCFirst(params.item) } #{ params.id }</strong> and agree to the terms set forth within:</p>
                                }
                            </div>

                            { !saved &&
                                <div className="row">
                                    <div className="col-3">
                                        <button className="btn btn-danger btn-lg mt-3" onClick={ handleDecline }>DECLINE</button>
                                    </div>
                                    <div className="col-9">
                                        <button className="btn btn-success btn-lg float-right mt-3" onClick={ handleAccept }>
                                            { saving && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                                            SIGN & ACCEPT TERMS
                                        </button>
                                        <button className="btn btn-default btn-lg float-right mt-3 mr-3" onClick={ handleClear }>CLEAR SIGNATURE PAD</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
};
