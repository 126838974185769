import AssetRequestForm from 'components/service-requests/service-request/asset-request-form/asset-request-form';
import Authorize from 'components/common/authorize';
import Breadcrumbs from 'components/common/breadcrumbs';
import CatchErrors from 'components/common/catch-errors';
import DashboardLayout from './layout/dashboard-layout';
import GenerateInvoiceForm from "components/invoices/generate-invoice-form/generate-invoice-form";
import InvoiceDashboard from 'components/invoices/invoice/invoice-dashboard';
import PaymentDashboard from "components/payments/payment-dashboard";
import QuoteDashboard from 'components/quotes/quote/quote-dashboard';
import QuoteForm from 'components/quotes/quote/quote-form/quote-form';
import QuoteTabs from 'components/quotes/quote/quote-tabs';
import React, { useState } from 'react';
import ReceivePaymentForm from 'components/payments/payment-form/receive-payment-form';
import ServiceRequestDashboard from 'components/service-requests/service-request/service-request-dashboard';
import ServiceRequestForm from 'components/service-requests/service-request/service-request-form/service-request-form';
import ServiceRequestTabs from 'components/service-requests/service-request/service-request-tabs';
import SlidingColumn from 'components/common/sliding-column';
import SlidingColumns from 'components/common/sliding-columns';
import TimeClock from 'components/time-clock/time-clock';
import WorkOrderDashboard from 'components/work-orders/work-order/work-order-dashboard';
import WorkOrderForm from 'components/work-orders/work-order/work-order-form/work-order-form';
import WorkOrderTabs from 'components/work-orders/work-order/work-order-tabs';
import { APPS, PERMISSIONS } from 'components/constants';
import { Route, useParams, useRouteMatch } from 'react-router-dom';


export default function Dashboard(props) {
	
	const match = useRouteMatch();
    const params = useParams();

	const [columnState, setColumnState] = useState(); // set by SlidingColumns

	// Depending on how many panels are showing (1-3) the array indicates # of segments at which a shift occurs up to 3 shifts
	const panelBP = {
		panels3: [99, 99, 99],
		panels2: [11, 99, 99],
		panels1: [9, 11, 99],
	}

	// Show name in breadcrumb string if number of segments is at least "segments" and path contains optional "includes"
	const breadcrumbBP = [
		{ name: 'DASHBOARD' },
		{ name: 'Details', segments: 5 },
		{ name: 'Service Request', segments: 9, includes: 'service_requests' },
		{ name: 'Work Order', segments: 11, includes: 'work_orders' },
		{ name: 'Quote', segments: 11, includes: 'quotes' },
		{ name: 'Invoice', segments: 11, includes: 'invoices' },
	];

	const entity_id = (params.appId == APPS.SERVICE.id) ? 'customer_id' : 'asset_id';

	return (
		<>
			<Breadcrumbs breakpoints={breadcrumbBP} columnState={columnState} />

			<SlidingColumns breakpoints={panelBP} setColumnState={setColumnState}>

				{/* --------------------------------------------------------------------------
					COLUMN 1
				--------------------------------------------------------------------------- */}

				<Route path={`${match.path}/:widget_id/:${entity_id}?/:form?/:form_id?/:tab1?/:tab1_id?/:tab2?/:tab2_id?`} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.CUSTOMERS_VIEW } show403>
						<SlidingColumn index={ 1 } columnState={ columnState } { ...route_props }>
							<CatchErrors>
								<TimeClock />
							</CatchErrors>
							<CatchErrors>
								<DashboardLayout />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				)} />

				{/* --------------------------------------------------------------------------
					COLUMN 2
				-------------------------------------------------------------------------- */}

				<Route path={`${match.path}/:widget_id/:${entity_id}/:form/:form_id/service_requests/:request_id/:tab?/:tab_id?`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW } show403>
						<SlidingColumn index={ 2 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<ServiceRequestDashboard {...route_props} />
							</CatchErrors>
							<CatchErrors className={'m-t-lg'}>
								<ServiceRequestTabs {...route_props} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>			
				} />

				<div id="column2-rel" className="col-lg-6 col-xl-4"></div>

				{/* --------------------------------------------------------------------------
					COLUMN 3 
				-------------------------------------------------------------------------- */}

                <Route path={`${match.path}/:widget_id/:${entity_id}/:form/:form_id/service_requests/:request_id/payments/preview/:payment_id`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.PAYMENTS_VIEW } show403>
						<SlidingColumn index={ 3 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<PaymentDashboard {...route_props} isPayment={true} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				} />
                <Route path={`${match.path}/:widget_id/:${entity_id}/:form/:form_id/service_requests/:request_id/work_orders/:work_order_id/:tab/:action?/:action_id?`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW } show403>
						<SlidingColumn index={ 3 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<WorkOrderDashboard {...route_props} />
							</CatchErrors>
							<CatchErrors className={'m-t-lg'}>
								<WorkOrderTabs {...route_props} source="service_requests" />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				} />
                <Route path={`${match.path}/:widget_id/:${entity_id}/:form/:form_id/service_requests/:request_id/quotes/:quote_id/:tab/:action?/:action_id?`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.QUOTES_VIEW } show403>
						<SlidingColumn index={ 3 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<QuoteDashboard {...route_props} />
							</CatchErrors>
							<CatchErrors className={'m-t-lg'}>
								<QuoteTabs {...route_props} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				} />
				<Route path={`${match.path}/:widget_id/:${entity_id}/:form/:form_id/:tab/:request_id/invoices/preview/:invoice_id/:invoice_tab?`} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.INVOICES_VIEW } show403>
						<SlidingColumn index={ 3 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<InvoiceDashboard {...route_props} />
							</CatchErrors>
							{/* <CatchErrors>
								<InvoiceTabs {...route_props} />
							</CatchErrors> */}
						</SlidingColumn>
					</Authorize>
				)} />

				{/* ----------------------------------------------------------------------------------------
					COLUMN 4
				----------------------------------------------------------------------------------------- */}

                {/* <Route path={match.path + '/:customer_id/:form/:form_id/service_requests/:request_id/payments/preview/:payment_id'} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.PAYMENTS_VIEW } show403>
						<SlidingColumn index={ 4 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<PaymentDashboard {...route_props} isPayment={true} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				} />
                <Route path={match.path + '/:customer_id/:form/:form_id/service_requests/:request_id/work_orders/:work_order_id/:tab/:action?/:action_id?'} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW } show403>
						<SlidingColumn index={ 4 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<WorkOrderDashboard {...route_props} />
							</CatchErrors>
							<CatchErrors className={'m-t-lg'}>
								<WorkOrderTabs {...route_props} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				} />
                <Route path={match.path + '/:customer_id/:form/:form_id/service_requests/:request_id/quotes/:quote_id/:tab/:action?/:action_id?'} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.QUOTES_VIEW } show403>
						<SlidingColumn index={ 4 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<QuoteDashboard {...route_props} />
							</CatchErrors>
							<CatchErrors className={'m-t-lg'}>
								<QuoteTabs {...route_props} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				} />
				<Route path={match.path + '/:customer_id/:form/:form_id/:tab/:request_id/invoices/preview/:invoice_id/:invoice_tab?'} render={(route_props) => (
					<Authorize perm={ PERMISSIONS.INVOICES_VIEW } show403>
						<SlidingColumn index={ 4 } columnState={ columnState }>
							<CatchErrors className={'m-t-lg'}>
								<InvoiceDashboard {...route_props} />
							</CatchErrors>
						</SlidingColumn>
					</Authorize>
				)} /> */}


				{/* ----------------------------------------------------------------------
					MODALS
				---------------------------------------------------------------------- */}

                <Route path={`${match.path}/:widget_id/:${entity_id}/request-form/:request_id/:tab/:action?`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_EDIT }>
						<CatchErrors modal={true}>
							{ (params.appId == APPS.SERVICE.id)
								?	<ServiceRequestForm {...route_props} />
								:	<AssetRequestForm {...route_props} />
							}
						</CatchErrors>
					</Authorize>
                } />
                <Route path={`${match.path}/:widget_id/:${entity_id}/work-order-form/:work_order_id/service_requests/:request_id`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.WORK_ORDERS_EDIT }>
						<CatchErrors modal={true}>
							<WorkOrderForm {...route_props} />
						</CatchErrors>
					</Authorize>
                } />
				<Route path={`${match.path}/:widget_id/:${entity_id}/invoice-form/:invoice_id/service_requests/:request_id`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.INVOICES_EDIT }>
						<CatchErrors modal={true}>
							<GenerateInvoiceForm {...route_props} />
						</CatchErrors>
					</Authorize>
				} />
                <Route path={`${match.path}/:widget_id/:${entity_id}/payment-form/:payment_id/:tab?/:request_id?/:request_tab/:preview?/:invoice_id?`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.PAYMENTS_RECEIVE }>
						<CatchErrors modal={true}>
							<ReceivePaymentForm {...route_props} />
						</CatchErrors>
					</Authorize>
                } />
                <Route path={`${match.path}/:widget_id/:${entity_id}/quote-form/:quote_id/:tab?/:request_id?`} render={(route_props) =>
					<Authorize perm={ PERMISSIONS.QUOTES_EDIT }>
						<CatchErrors modal={true}>
							<QuoteForm />
						</CatchErrors>
					</Authorize>
                } />

			</SlidingColumns>
		</>
	);
};
