import React, { Fragment } from 'react';
import { LocationAlpha, LocationNumeric } from 'components/common/location-dropdowns';
import { SERVICE_ITEMS, UNITS } from 'components/constants';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function inventory_fields() {

	return (
		{
			name: '',
			assetTypeIds: [],
			categoryIds: [],
			partNumber: '',
			manufacturer: '',
			description: '',
			imageUrl: '',
			
			averageCost: '',
			unitId: '0',
			
			primaryVendorId: [],
			primaryPrice: '',
			secondaryVendorId: [],
			secondaryPrice: '',

			currentInventory: '',
			minimumInventory: '',
			barcode: '',
			location: '',
			locationSection: '',
			locationRow: '',
			locationBin: '',
		}
	);
}
export function inventory_form_layout({
	handle, 
	stockPart, 
	partTypeId, 
	firebase, 
	assetTypes, 
	categoriesByType, 
	settings, 
	editInventory
}) {

	const enablePOs = settings?.purchase_orders?.enablePOs ?? false;
	const unit_options = Object.values(UNITS)?.map((unit) => <option key={unit.id} value={`${unit.id}`}>{`${unit.name}`}</option>);
	const partType = Object.values(SERVICE_ITEMS).find((p) => p.id == partTypeId);

	var layout = [{

		block: [{
			column_class: 'col-xl-6',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">{partType?.name} Details</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Name',
					field: 'name',
					type: 'text',
					valid: ['required']
				}, {
					grid: 'col-12',
					label: '+ Asset Types',
					field: 'assetTypeIds',
					type: (partTypeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) ? 'multiselect' : 'skip',
					placeholder: '(all that apply)',
					options: assetTypes?.map((assetType) => ({ value: assetType.id, label: assetType.value }))
				}, {
					grid: 'col-12',
					label: '+ Categories',
					field: 'categoryIds',
					type: 'multiselect',
					placeholder: '(all that apply)',
					options: categoriesByType?.map((category) => ({ value: category.id, label: category.name }))
				}, {
					grid: 'col-6',
					label: 'Part Number',
					field: 'partNumber',
					type: 'text',
					prepend: '#',
					valid: ['required']
				}, {
					grid: 'col-6',
					label: 'Manufacturer',
					field: 'manufacturer',
					type: 'text',
				}, {
					grid: 'col-6',
					label: 'Price Override',
					field: 'priceOverride',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					type: 'usd',
				}, {
					grid: 'col-6',
					component: <div className="alert alert-success">
						Omit Price Override to determine price by Average Cost and Markup percentage (Inventory Settings)
					</div>,
					type: (partTypeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) ? 'skip' : 'jsx',
				}, {
					grid: 'col-12',
					label: 'Description',
					field: 'description',
					type: 'textarea'
				}],
			}],
		}, {
			column_class: 'col-xl-6',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">{partType?.name} Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Stock Part Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'stockParts',
						className: 'mb-3',
						height: "290px",
						multiple: false
					}
				}],
			}],
		}]
	}];
	
	if (partTypeId != SERVICE_ITEMS.NON_STOCK_PARTS.id) layout.push({
	
		block: [{
			column_class: 'col-xl-6',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Supply</h3>
					</Fragment>,
				layout: [{
					grid: 'col-8',
					label: 'Primary Vendor',
					field: 'primaryVendorId',
					placeholder: 'Search for Vendor',
					type: 'typeahead',
					options: {
						allow_new: true,
						fields: ['displayName', 'addresses', 'contacts'],
						id: 'id',
						sort: [{ "displayName": "asc" }],
						table: 'mt-' + handle + (isDev ? '-dev' : '') + '-profiles',
						target: 'displayName',
						type_filter: 'vendor',
					},
				}, {
					grid: 'col-4',
					label: 'Price',
					field: 'primaryPrice',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					type: 'usd',
				}, {
					grid: 'col-8',
					label: 'Secondary Vendor',
					field: 'secondaryVendorId',
					placeholder: 'Search for Vendor',
					type: 'typeahead',
					options: {
						allow_new: true,
						fields: ['displayName', 'addresses', 'contacts'],
						id: 'id',
						sort: [{ "displayName": "asc" }],
						table: 'mt-' + handle + (isDev ? '-dev' : '') + '-profiles',
						target: 'displayName',
						type_filter: 'vendor',
					},
				}, {
					grid: 'col-4',
					label: 'Price',
					field: 'secondaryPrice',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					type: 'usd',
				}],
			}],
		}, {
			column_class: 'col-xl-6',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Inventory / Cost</h3>
					</Fragment>,
				layout: [{
					grid: 'col-xl-6 order-1',
					label: 'Barcode',
					field: 'barcode',
					position: 'start',
					buttonText: <i className="fas fa-barcode"></i>,
					buttonClass: ((window.flutter) ? 'btn-primary btn-sm' : 'btn-default btn-sm'),
					type: 'combo'
				}, {
					grid: 'col-xl-6 order-2 order-xl-4',
					label: 'Location',
					field: 'location',
					type: (settings.inventory?.enableCustom) ? 'text' : 'skip',
				}, {
					grid: 'col-4 col-xl-2 order-2 order-xl-4',
					label: 'Section',
					field: 'locationSection',
					type: (settings.inventory?.enableSection) ? 'select' : 'skip',
					options:
						<Fragment>
							<option>-</option>
							{ settings.inventory?.section == '1' ? <LocationAlpha /> : <LocationNumeric /> }
						</Fragment>,
				}, {
					grid: 'col-4 col-xl-2 order-3 order-xl-5',
					label: 'Row',
					field: 'locationRow',
					type: (settings.inventory?.enableRow) ? 'select' : 'skip',
					options:
						<Fragment>
							<option>-</option>
							{ settings.inventory?.row == '1' ? <LocationAlpha /> : <LocationNumeric /> }
						</Fragment>,
				}, {
					grid: 'col-4 col-xl-2 order-4 order-xl-6',
					label: 'Bin',
					field: 'locationBin',
					type: (settings.inventory?.enableBin) ? 'select' : 'skip',
					options:
						<Fragment>
							<option>-</option>
							{ settings.inventory?.bin == '1' ? <LocationAlpha /> : <LocationNumeric /> }
						</Fragment>,
				}, {
					grid: 'col-6 col-xl-3 order-5 order-xl-2',
					label: 'Current Inventory',
					field: 'currentInventory',
					append: 'units',
					readOnly: (enablePOs && !editInventory),
					valid: ['numeric'],
					inputMode: 'numeric',
					type: 'text'
				}, {
					grid: 'col-6 col-xl-3 order-6 order-xl-3',
					label: 'Per Unit',
					field: 'unitId',
					type: 'select',
					options:
						<Fragment>
							<option value="">Choose ...</option>
							{unit_options}
						</Fragment>,
					disabled: (enablePOs && !editInventory),
					// }, {
						// 	grid: 'col-3',
						// 	label: 'Pending Inventory',
						// 	field: 'pendingInventory',
						// 	append: 'units',
						// 	readOnly: true,
						// 	type: 'number'
				}, {
					grid: 'col-6 col-xl-3 order-7',
					label: 'Minimum Inventory',
					field: 'minimumInventory',
					append: 'units',
					valid: ['numeric'],
					inputMode: 'numeric',
					type: 'text'
				}, {
					grid: 'col-6 col-xl-3 order-8',
					label: 'Average Cost',
					field: 'averageCost',
					prepend: '$',
					readOnly: (enablePOs && !editInventory),
					valid: ['numeric'],
					inputMode: 'decimal',
					type: 'usd',
				}, {
					grid: 'col-6 col-xl-9 mt-2 text-muted order-9',
					text: 'Pending Inventory in Open Work Orders: ( ' + stockPart.pendingInventory + ' )',
					type: (enablePOs && stockPart.pendingInventory) ? 'plaintext' : 'skip',
				}, {
					grid: 'col-12 order-10',
					className: `btn-${(editInventory)?'info':'default'} btn-sm`,
					name: 'editInventory', 
					label: ((editInventory) ? 'Lock' : 'Edit') + ' Inventory',
					noLabel: true,
					type: (enablePOs && stockPart.pendingInventory) ? 'button' : 'skip',
				}],
			}],
		}, {
			column_class: 'col-xl-6',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Price</h3>
					</Fragment>,
				layout: [{
					grid: 'col-6 col-xl-3 order-8',
					label: 'Override Price',
					field: 'priceOverride',
					prepend: '$',
					valid: ['numeric'],
					inputMode: 'decimal',
					type: 'usd',
				}],
			}],
		}]
	});

	return layout;
}