import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_inventory from 'actions/stock-parts-actions';
import React, { forwardRef, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { FormBuilder, ValidateForm, ModalAlert, ModalForm } from 'enspire-manager-framework';
import { APPS, LOG, ACTION, SERVICE_ITEMS } from 'components/constants';
import { confirmAdd, getProfileName, removeMark } from 'components/common/toolbox';
import { confirmArchive } from 'components/common/toolbox';
import { inventory_fields, inventory_form_layout } from './inventory-form-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

var _ = require('lodash');

const InventoryForm = (props) => {

	/* Hooks --------------------------*/
	
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();
	
	const admin = useSelector((store) => store.admin);
	const assetTypes = useSelector((store) => store.settings.settings_asset_types);
	const assetType = assetTypes?.find((assetType) => assetType.id == params.asset_type_id);
	const inventory = useSelector((store) => store.inventory);
	const partsCategories = useSelector((store) => store.settings.settings_parts_categories);
	const settings = useSelector((store) => store.settings.settings);
	const user_permissions = useSelector(store => store.users?.user_permissions);
	
	const [editInventory, setEditInventory] = useState(!parseInt((params.action_id) ? params.action_id : params.form_id));
	const [focusedInput, setFocused] = useState(null);
	const [original, setOriginal] = useState({});
	const [state, setState] = useState({
		search: '',
		form_error: null,
		stockPart: inventory_fields(),
	});
	
	const partId = (params.action_id) ? params.action_id : params.form_id;
	const categoriesByType = (params.appId == APPS.SERVICE.id)
		?	_.filter(partsCategories, { type: 'service' })
		:	_.filter(partsCategories, { type: 'stock' });

	const isNew = (parseInt(partId)) ? false : true;
	const typeId = (props.source == 'inventory')
		? 	(params.appId == APPS.SERVICE.id) ? 'service' : 'stock'
		:	props.typeId;

	/* Effects --------------------------*/

	// Subcribe to part if referred by Line Builder
	useEffect(() => {
		if (params.action_id) {
			dispatch(actions_inventory.subStockPart(params.handle, params.action_id));
			return () => {
				let unsubscribe = inventory.inventory_item_unsubscribe;
				if (typeof unsubscribe === "function") unsubscribe();
			};
		}	
	}, [params.action_id]);
	
	useEffect(() => {
		if (!isNew) {
			populateForm();
		} else {
			setState(prev => ({ ...prev, stockPart: { ...prev.stockPart, assetTypeIds: [
				{ value: params.asset_type_id, label: assetTypes.find((at) => at.id == params.asset_type_id)?.value }
			]}}));
		}
	}, [inventory.inventory_item]);
		
	useEffect(() => {
		window.addEventListener("barcodeScanned", handleBarcodeEvent);
		return () => { window.removeEventListener("barcodeScanned", handleBarcodeEvent)};
	}, []);

	/* Handlers --------------------------*/

	const handleChange = (e, value) => {
		let newValue = (value) ? value : e.target.value;
		let form_error = _.filter(state.form_error, (o) => { return o.field !== e.target.name; });
		
		if (isNew && e.target.name == 'primaryPrice') {
			setState(prev => ({ ...prev, form_error, stockPart: { ...prev.stockPart, 
				[e.target.name]: removeMark(newValue), // remove highlighting when result is edited
				averageCost: newValue,
			}}));
		} else {
			setState(prev => ({ ...prev, form_error, stockPart: { ...prev.stockPart, [e.target.name]: newValue } }));
		}
	};
	const handleButtons = (name) => {
		if (name == 'editInventory') setEditInventory(!editInventory);
	}
	const handleUpload = (field, filename) => {
		setState(prev => ({ ...prev, stockPart: { ...prev.stockPart, [field]: filename } }));
	};
	const handleMultiSelect = (values, target) => {
		let form_error = _.filter(state.form_error, (o) => { return o.field !== target.name; });
		
		if (values.length > 10) return window.toastr.error('You can only tag up to 10 Categories.', 'Too many Categories!');	
		if (target.action == 'create-option') {
			return confirmCreate(values, target);
		} else {
			setState(prev => ({ ...prev, form_error, stockPart: { ...prev.stockPart, [target.name]: values } }));
		}
	};	
	
	/* Typeaheade Handlers --------------------------*/
	
	const handleTypeahead = (field, result) => {
		var form_error = _.filter(state.form_error, (o) => { return o.field !== field; });
		if (result?.[0]?.customOption) {
			confirmAdd("Vendor", () => {
				dispatch(actions_profiles.saveProfile({
					handle: params.handle, 
					profileId: '0', 
					profileForm: { displayName: result?.[0]?.target }, 
					profileType: 'VENDOR',
					callback: (profileId) => {
						setState(prev => ({ ...prev, form_error, stockPart: { ...prev.stockPart, [field]: [{ target: result?.[0]?.target, id: profileId }] }}));
						dispatch(actions_admin.saveActivity(params.handle, LOG.VENDOR.key, ACTION.CREATED.key, profileId, result?.[0]?.target ));
					}
				}));	
			});
		} else if (result?.[0]?.id) {
			setState(prev => ({ ...prev, form_error, stockPart: { ...prev.stockPart, [field]: [{ target: result?.[0]?.target, id: result?.[0]?.id }] }}));
		} else {
			setState(prev => ({ ...prev, form_error, stockPart: { ...prev.stockPart, [field]: [] }}));
		}
	};

	/* Barcode Handlers -----------------------------*/

	const handleScanButton = () => {
		if (window.flutter) {
			window.flutter.callHandler('scanBarcode'); 
		} else {
			window.toastr.warning(`Barcode Scanner not installed`);
		}
	}
	const handleBarcodeEvent = (event) => {
		console.log(event);
		setState(prev => ({ ...prev, stockPart: { ...prev.stockPart, barcode: event.detail.barcode }}));
	}

	/* Actions --------------------------*/

	// TODO: setup permissions for adding Categories here.
	const confirmCreate = (values, target) => {
		ModalAlert({
			title: 'Are you sure?',
			text: "This Category will be created and made available for selection to other Stock Parts.",
			icon: 'warning',
			show_cancel: true,
			confirm_color: '#8FBC8B',
			confirm_text: 'Yes, create it!',
			callback_success: () => handleAddCategory(values, target)
		});
	};
	const handleAddCategory = (values, target) => {
		let update = { name: target.option.value.trim(), assetTypeId: assetType.id };

        dispatch(actions_settings.saveCategory(params.handle, update, null, (categoryId) => {
            dispatch(actions_admin.saveActivity(params.handle, LOG.SETTINGS.key, ACTION.CREATED.key, categoryId, `Parts Category: ${update.name}`));
			setState(prevState => ({ ...prevState, stockPart: { ...prevState.stockPart, [target.name]: values } }));
		}));
	};
	const populateForm = async () => {
		if (inventory.inventory_item) {
			let primaryVendorName = await getProfileName(params.handle, inventory.inventory_item?.primaryVendorId);
			let secondaryVendorName = await getProfileName(params.handle, inventory.inventory_item?.secondaryVendorId);
			let stockPart = {
				...state.stockPart,
				...inventory.inventory_item,
				assetTypeIds: inventory.inventory_item.assetTypeIds?.map((at) => ({ value: at, label: assetTypes.find((at2) => at2.id == at)?.value })),
				categoryIds: inventory.inventory_item.categoryIds?.map((pc) => ({ value: pc, label: partsCategories.find((pc2) => pc2.id == pc)?.name })),
				primaryVendorId: (inventory.inventory_item.primaryVendorId) ? [{ target: primaryVendorName, id: inventory.inventory_item.primaryVendorId }] : [],
				secondaryVendorId: (inventory.inventory_item.secondaryVendorId) ? [{ target: secondaryVendorName, id: inventory.inventory_item.secondaryVendorId }] : [],
			};
			setState(prevState => ({ ...prevState, stockPart }));
			setOriginal(stockPart);
		};
	};
	const archiveStockPart = () => {
		dispatch(actions_inventory.saveStockPart(params.handle, { ...state.stockPart, modified: new Date(), deleted: true }, partId, () => {
			dispatch(actions_admin.saveActivity(params.handle, LOG.INVENTORY.key, ACTION.ARCHIVED.key, partId, state.stockPart.name ));
			history.replace(`/${params.handle}/${params.appId}/inventory/${params.asset_type_id}`);
		}));
	};
	const submitForm = () => {
		var form_error = ValidateForm(state.stockPart, form_layout);
		setState(prevState => ({ ...prevState, form_error }));

		if (!form_error.length) {
			var newStockPart = {
				...state.stockPart,
				imageUrl: state.stockPart.imageUrl,
				type: typeId,
				categoryIds: state.stockPart.categoryIds?.map((pc) => pc.value) ?? [],
				assetTypeIds: state.stockPart.assetTypeIds?.map((pc) => pc.value) ?? [],
				primaryVendorId: (state.stockPart.primaryVendorId?.[0]?.id) ? state.stockPart.primaryVendorId[0].id : null,
				secondaryVendorId: (state.stockPart.secondaryVendorId?.[0]?.id) ? state.stockPart.secondaryVendorId[0].id : null,
			};

			if (typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) delete newStockPart.assetTypeIds;

			dispatch(actions_inventory.saveStockPart(params.handle, newStockPart, partId, (stockPartId) => {
				let action = (parseInt(partId)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
				dispatch(actions_admin.saveActivity(params.handle, LOG.INVENTORY.key, action, stockPartId, state.stockPart.name, newStockPart, original ));

				if (props.source == 'line-items') {
					if (isNew) props.addItem({ ...newStockPart, id: stockPartId, count: 1 });
					history.goBack();
				} else if (stockPartId != state.stockPart.id) {
					history.replace(`/${params.handle}/${params.appId}/inventory/${params.asset_type_id}/${stockPartId}/form/0/assets`);
				} else {
					history.goBack();
				}
			}));
		}
	};

	/* Constants --------------------------*/

	var form_layout = inventory_form_layout({
		handle: params.handle, 
		stockPart: state.stockPart, 
		partTypeId: typeId, 
		firebase, 
		assetTypes, 
		categoriesByType, 
		settings, 
		editInventory
	});

	return (

		<ModalForm {...props}
			history={history}
			style={1}
			size={'xl'}
			modal_header={`${Object.values(SERVICE_ITEMS).find((type) => type.id == typeId)?.singular}`}
			cancel_button_title="Cancel"
			save_button_title={'Save Details'}
			submitFormHandler={() => submitForm()}
			delete_button_title={'Archive'}
			delete_button_disabled={ !user_permissions.INVENTORY_DELETE }
			delete_button_callback={(isNew) ? null : () => confirmArchive('StockPart', archiveStockPart)}
			show_spinner={inventory?.inventory_item_save_pending}
			focusedInput={(window.flutter) ? focusedInput : null}
			platform={admin.flutter_platform}
			keyboardPadding={admin.flutter_keyboard}
		>
			<FormBuilder
				callbacks={{
					focus: (event) => setFocused(event.target),
					text: handleChange,
					combo: handleScanButton,
					button: handleButtons,
					select: handleChange,
					typeahead: handleTypeahead,
					dropzone: handleUpload,
					multiselect: handleMultiSelect,
				}}
				form_error={state.form_error}
				form={form_layout}
				record={state.stockPart}
			/>
		</ModalForm>
	);
};

export default InventoryForm;