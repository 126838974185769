import * as types from 'actions/action-types';

var initialState = {
    inventory: null,
    inventory_pending: false,
    inventory_save_pending: false,
    inventory_unsubscribe: false,
    
    inventory_item: null,
    inventory_item_pending: null,
    inventory_item_save_pending: false,
    inventory_item_unsubscribe: false,

    inventory_item_activity_log: [],
    inventory_item_activity_log_pending: false,
    inventory_item_activity_log_fulfilled: false,
    inventory_item_activity_log_unsubscribe: false,

    purchase_order: {},
    purchase_order_saved: null,
    purchase_order_pending: false,
    purchase_order_save_pending: false,
    purchase_order_unsubscribe: false,

    purchase_orders: [],
    purchase_orders_saved: null,
    purchase_orders_pending: false,
    purchase_orders_save_pending: false,
    purchase_orders_unsubscribe: false,

};

const StockPartsReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.INVENTORY + '_PENDING': pending('inventory'); break;
        case types.INVENTORY + '_SAVE_PENDING': save_pending('inventory'); break;
        case types.INVENTORY + '_SAVE_FULFILLED': save_fulfilled('inventory'); break;
        case types.INVENTORY + '_FULFILLED': fulfilled('inventory'); break;
        case types.INVENTORY + '_CLEAR': clear('inventory', {}); break;

        case types.INVENTORY_ITEM + '_PENDING': pending('inventory_item'); break;
        case types.INVENTORY_ITEM + '_SAVE_PENDING': save_pending('inventory_item'); break;
        case types.INVENTORY_ITEM + '_SAVE_FULFILLED': save_fulfilled('inventory_item'); break;
        case types.INVENTORY_ITEM + '_FULFILLED': fulfilled('inventory_item'); break;

        case types.INVENTORY_ITEM + '_' + types.ACTIVITY_LOG + '_PENDING': pending('inventory_item_activity_log'); break;
        case types.INVENTORY_ITEM + '_' + types.ACTIVITY_LOG  + '_FULFILLED': fulfilled('inventory_item_activity_log'); break;
        case types.INVENTORY_ITEM + '_' + types.ACTIVITY_LOG  + '_CLEAR': clear('inventory_item_activity_log', {}); break;

        case types.PURCHASE_ORDER + '_PENDING': pending('purchase_order'); break;
        case types.PURCHASE_ORDER + '_SAVE_PENDING': save_pending('purchase_order'); break;
        case types.PURCHASE_ORDER + '_SAVE_FULFILLED': save_fulfilled('purchase_order'); break;
        case types.PURCHASE_ORDER + '_FULFILLED': fulfilled('purchase_order'); break;
        case types.PURCHASE_ORDER + '_CLEAR': clear('purchase_order', {}); break;

        case types.PURCHASE_ORDERS + '_PENDING': pending('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_SAVE_PENDING': save_pending('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_SAVE_FULFILLED': save_fulfilled('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_FULFILLED': fulfilled('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_CLEAR': clear('purchase_orders', {}); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        };
    }
};
export default StockPartsReducer;


