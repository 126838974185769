import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { APPS, WORKORDERS } from 'components/constants';
import { navNotice } from 'components/common/toolbox';
import { Table } from 'enspire-manager-framework';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const ResultsWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const workOrders = useSelector((store) => store.workOrders);

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_work_orders.clearDashboardWorkOrders());
        } else {
            dispatch(actions_work_orders.subDashboardWorkOrders(params.handle, params.appId, params.widget_id));
        }
        
        return () => {
			const unsubscribe = workOrders.work_orders_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => {
        
        var work_orders = [];
        workOrders.work_orders_dashboard?.forEach((workOrder) => {
            
            let status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });

            work_orders.push({ ...workOrder,
                status: status?.name,
                _stripe_color: status?.color,
            });
        });
        setData(work_orders);
    }, [workOrders.work_orders_dashboard]);

    /* Actions ----------------------------*/

    const openWorkOrder = async (workOrder) => {

        if (numPanels(1) && !user.dismissed?.navNotice) {
            navNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'navNotice'));
            }, () => redirect());
        } else redirect();
        
        function redirect() {
            let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            let location = toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`, 9);
            history.push({ pathname: location });
            setTimeout(() => {
                location = toolbox.modifyPath( location, 10, `${workOrder.id}/items`);
                history.push({ pathname: location });
            }, 300);
        }
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Start', field: 'startDate', type: 'timestamp', format: 'MMM Do', width: 25 },
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 75 }] : [],
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 75 }] : [],
        { name: 'Summary', field: 'description' },
    ];

    return (

        <div className="bg-white px-3 py-1">
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.tab2_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={ openWorkOrder }
                chevron={ true }
            >
            </Table>
        </div>
    );
};

export default ResultsWorkOrders;