import React from 'react';
import { useLocation } from 'react-router-dom';

export default function Breadcrumbs(props) {

    const routeLocation = useLocation();
	const pathname = routeLocation.pathname;
	const segments = pathname.split('/').length;

    const crumbs = props.breakpoints.map((item, index) => {
        if (item.includes && item.segments) {
            return (pathname.includes(item.includes) && segments >= item.segments) 
                ?   <span key={ index } className="breadcrumb-wrap"> <i className="fas fa-angle-right text-muted px-1"></i>
                        {item.name}
                    </span> 
                :   null;
        } else if (item.segments) {
            return (segments >= item.segments) 
                ?   <span key={ index } className="breadcrumb-wrap"> <i className="fas fa-angle-right text-muted px-1"></i>
                        {item.name}
                    </span> 
                :   null;
        } else {
            return <span key={ index } className="breadcrumb-first">{ item.name }</span>
        }
    });

    return (
        <h2 className="mt-2 mb-3 breadcrumbs" style={{ width: (props.columnState?.panelWidth) ? props.columnState?.panelWidth - 30 : '100%' }}>
            { crumbs }
        </h2>
        // <>
        //     { window.flutter 
        //         ?   <div className="mb-3"></div>
        //         :   <h2 className="mb-2" style={{ width: (props.columnState?.panelWidth) ? props.columnState?.panelWidth - 30 : '100%', backgroundColor: '#dddddd', padding: '4px 10px', borderRadius: '10px' }}>
        //                 { crumbs }
        //             </h2>
        //     }
        // </>
    );
};
