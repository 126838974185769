import * as actions_users from 'actions/users-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { APPS, REQUESTS } from 'components/constants';
import { ConditionalWrapper, navNotice, numPanels } from 'components/common/toolbox';
import { Ibox, ModalAlert, Table } from 'enspire-manager-framework';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const ResultsServiceRequests = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const serviceRequests = useSelector((store) => store.serviceRequests);;

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_service_requests.clearDashboardServiceRequests());
        } else {
            dispatch(actions_service_requests.subDashboardServiceRequests(params.handle, params.appId, params.widget_id));
        }
        
        return () => {
			const unsubscribe = serviceRequests.service_requests_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => {
        
        var service_requests = [];
        serviceRequests.service_requests_dashboard?.forEach((serviceRequest) => {
            
            let status = _.find(Object.values(REQUESTS), { id: serviceRequest.statusId });

            service_requests.push({ ...serviceRequest,
                status: status?.name,
                _stripe_color: status?.color,
            });
        });
        setData(service_requests);
    }, [serviceRequests.service_requests_dashboard]);

    /* Actions ----------------------------*/

    const openServiceRequest = async (serviceRequest) => {
        let entityId = (params.appId == APPS.SERVICE.id) ? serviceRequest.customerId : serviceRequest.assetId;
        toolbox.listLink(history, routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${serviceRequest.id}/tab`, null, 9)
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Requested', field: 'requestDate', type: 'timestamp', format: 'MMM Do', width: 25 },
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 75 }] : [],
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 75 }] : [],
        { name: 'Summary', field: 'description' },
    ];

    return (

        <div className="bg-white px-3 py-1">
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.tab2_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={ openServiceRequest }
                chevron={ true }
            >
            </Table>
        </div>
    );
};

export default ResultsServiceRequests;