import React, { useEffect, useState } from 'react';
import { Avatar, FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { confirmDelete } from 'components/common/toolbox';
import { add_item_form_layout } from './add-item-form-layout';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

var _ = require('lodash');

const AddItemForm = (props) => {

	/* Hooks --------------------------*/
	
	const history = useHistory();
	
	const admin = useSelector((store) => store.admin);
	const inventory = useSelector((store) => store.inventory);
	const partsCategories = useSelector((store) => store.settings.settings_parts_categories);
    const user_permissions = useSelector(store => store.users?.user_permissions);

	const [focusedInput, setFocused] = useState(null);
	const [original, setOriginal] = useState({});
	const [state, setState] = useState({
		search: '',
		form_error: null,
		item: {},
	});
	
	/* Effects --------------------------*/

	useEffect(() => {
		setOriginal(props.item);
		let item = (props.existing) ? { ...props.item, priceOverride: props.item.price } : props.item
		setState(prev => ({ ...prev, item: item }));
	}, [props.item]);
		
	/* Handlers --------------------------*/

	const handleChange = (e, value) => {
		let newValue = (value) ? value : e.target.value;
		let form_error = _.filter(state.form_error, (o) => { return o.field !== e.target.name; });
		setState(prev => ({ ...prev, form_error, item: { ...prev.item, [e.target.name]: newValue } }));
	};

	/* Actions --------------------------*/

	const submitForm = () => {
		var form_error = ValidateForm(state.item, form_layout);
		setState(prevState => ({ ...prevState, form_error }));

		if (!form_error.length) {
			if (props.existing) props.deleteItem(original);
			setTimeout(() => {
				props.addItem({ ...state.item });
				history.goBack();
			}, 250);
		}
	};

	/* Constants --------------------------*/

	var form_layout = add_item_form_layout(props.item.typeId);

	const categories = props.item?.categoryIds?.map((id) =>
		<span className="badge mr-1 mb-1">{ partsCategories.find((o) => o.id == id)?.name }</span>
	);

	return (

		<ModalForm {...props}
			history={history}
			style={1}
			size={'md'}
			modal_header={(props.existing) ? 'Edit Item' : 'Add Item'}
			cancel_button_title="Cancel"
			save_button_title={(props.existing) ? 'Update Item' : 'Add Item'}
			delete_button_title={'Delete'}
			delete_button_disabled={ !user_permissions.WORK_ORDERS_EDIT }
			delete_button_callback={(props.existing) ? () => confirmDelete('Item', () => {
				props.deleteItem(props.item);
				history.goBack();
			}) : null}
			submitFormHandler={() => submitForm()}
			show_spinner={inventory?.inventory_item_save_pending}
			focusedInput={(window.flutter) ? focusedInput : null}
			platform={admin.flutter_platform}
			keyboardPadding={admin.flutter_keyboard}
		>
			<div className="col-12">
				<div className="profile-image">
					<Avatar
						className="mx-auto"
						color="white"
						bgColor="saddleBrown"
						size={90}
						name={props.item.name}
						image={props.item.imageUrl}
						border="4px solid white"
					/>
				</div>
				<div className="profile-info pt-4">
					<h1 className="mt-0">{props.item.name}</h1>
					<h4>{categories}</h4>
				</div>
			</div>
			<div style={{ clear: 'both' }}></div>

			<FormBuilder
				callbacks={{
					focus: (event) => setFocused(event.target),
					text: handleChange,
					select: handleChange,
				}}
				form_error={state.form_error}
				form={form_layout}
				record={state.item}
			/>
		</ModalForm>
	);
};

export default AddItemForm;