import * as actions_timeclock from 'actions/timeclock-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import _ from 'lodash';
import { APPS, CHECKIN, WORKORDERS, WORKORDERS_CHECKED_IN } from 'components/constants';
import { Ibox, Table } from 'enspire-manager-framework';
import { ConditionalWrapper, navNotice, numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'enspire-manager-framework';

const moment = require('moment');

const CheckinWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
	const seg = 4;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const users = useSelector((store) => store.users);
    const user = useSelector((store) => store.users.user);
    const employees = useSelector((store) => store.employees.employees);
    const settings = useSelector((store) => store.settings);
    const techWorkOrders = useSelector((store) => store.workOrders.work_orders_technician);
    const workOrders = useSelector((store) => store.workOrders);

    const [filter, setFilter] = useState('today');
    const [data, setData] = useState([]);

    const isTechnician = (props.source == 'technician');
	const segments = routeLocation.pathname.split('/').length;

    /* UseEffect --------------------------*/

    // useEffect(() => {
    //     dispatch(actions_work_orders.subTechnicianWorkOrders(params.handle, user?.profileId, params.appId));
        
    //     return () => {
	// 		const unsubscribe = workOrders.work_orders_technician_unsubscribe;
	// 		if (typeof unsubscribe == 'function') unsubscribe();
	// 	};
    // }, [params.handle, params.appId, user.id]);

    useEffect(() => {     
        var work_orders = [];
        var filteredWorkOrders = (filter == 'today')
            ?   _.filter(techWorkOrders, (workOrder) => moment(workOrder.startDate.seconds, 'X').startOf('day') == moment().startOf('day'))
            :   (filter == 'week')
                ?   _.filter(techWorkOrders, (workOrder) => 
                        moment(workOrder.startDate.seconds, 'X').startOf('day') >= moment().startOf('week') &&
                        moment(workOrder.startDate.seconds, 'X').endOf('day') <= moment().endOf('week')
                    )
                :   techWorkOrders;

        filteredWorkOrders?.forEach((workOrder) => {
            
            let status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });
            let employee = employees?.find((o) => o.id == workOrder.technicianId);

            work_orders.push({ ...workOrder,
                name: ((employee)
                    ? employee?.contact?.firstName + ' ' + employee?.contact?.lastName
                    : (workOrder.statusId == WORKORDERS.UNASSIGNED.id) ? 'Unassigned' : 'Unknown'),
                status: status?.name,
                checkin: (WORKORDERS_CHECKED_IN.includes(workOrder?.statusId))
                    ?   <>
                            <StatusSelect
                                selectedStatusId={workOrder.statusId}
                                statusTypes={Object.values(WORKORDERS)}
                                statusTable={'CHECKIN'}
                                id={workOrder.id}
                                // onClick={handleCheckin}
                                static={ true }
                            />
                        </>
                    :   <>
                            { workOrder?.offsite
                                ?   <StatusSelect
                                        selectedStatusId={workOrder.statusId}
                                        statusTypes={[ CHECKIN.ENROUTE, CHECKIN.ONSITE ]}
                                        statusTable={'CHECKIN'}
                                        id={workOrder.id}
                                        onClick={handleCheckin}
                                    />
                                :   <button className="btn btn-info btn-sm" onClick={(e) => {
                                        e.stopPropagation();
                                        handleCheckin('CHECKIN', CHECKIN.INPROGRESS.id, workOrder.id);
                                    }}>Check In</button>
                            }
                        </>,
                _stripe_color: status?.color,
            });
        });
        setData(work_orders);
    }, [techWorkOrders, filter]);

    /* Actions ----------------------------*/

    const handleCheckin = (table, checkinId, workOrderId) => {
        const workOrder = _.find(techWorkOrders, { id: workOrderId });
        dispatch(actions_work_orders.workOrderCheckin(params.handle, workOrder, user, checkinId, () => {
            dispatch(actions_timeclock.clockIn(params.handle, users));
            props.closeCheckinForm();
        }));
    };
    const openWorkOrder = (workOrder) => {
        let num = (segments >= 12) ? -2 : (segments >= 10) ? -1 : 0;
		if (num) history.go(num);

        if (numPanels(1) && !user.dismissed?.navNotice) {
            navNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'navNotice'));
            }, () => redirect());
        } else redirect();

        function redirect() {
            let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            let location = toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`, 9);
            history.push({ pathname: location });
            setTimeout(() => {
                location = toolbox.modifyPath( location, 10, `${workOrder.id}/items`);
                history.push({ pathname: location });
            }, 300);
        }

    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Start', field: 'startDate', type: 'timestamp', format: 'MMM Do', width: 25 },
        { name: 'Asset', field: '_name', width: 40 },
        { name: 'Check-In', field: 'checkin', type: 'jsx', width: 35 },
        { name: 'Summary', field: 'description' },
    ];

    const title = <div>
        <Select
            className="mx-3"
            noLabel={true} 
            name={'assetTypeId'}
            value={filter}
            onChange={ (e) => setFilter(e.target.value)}
        >
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="all">All</option>
        </Select>
    </div>;

    return (

        <ConditionalWrapper
            condition={props.source == 'dashboard'}
            wrapper={ children => 
                <Ibox className="mt-3 mb-0" title={title} show_spinner={workOrders.work_orders_pending} no_fade={true}>
                    { children }
                </Ibox>
            }
        >
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.tab2_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={(props.source == 'dashboard') ? openWorkOrder : null}
                chevron={ props.source == 'dashboard' }
            >
            </Table>
        </ConditionalWrapper>
    );
};

export default CheckinWorkOrders;