import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Authorize(props) {

    const params = useParams();
    const user = useSelector(store => store.users?.user);
    const user_permissions = useSelector(store => store.users?.user_permissions);

    const hasPermission = (props.roleId)
        ? user.roles?.includes(props.roleId)
        : user_permissions[props.perm.key];
    const appAuthorized = (props.appId) ? params.appId == props.appId : true;

    var authorized = appAuthorized && hasPermission;

    return (
        <> { authorized
                ?   <>
                        { props.children }
                    </>
                :   <>
                        { props.show403 &&
                            <div className="middle-box text-center animated fadeInDown">
                                <h1>403</h1>
                                <h3 className="font-bold">Unauthorized</h3>

                                <div className="error-desc">
                                    Sorry, but you are not authorized for this resource
                                </div>
                            </div>
                        }
                    </>
            }
        </>
    );
};
