import * as types from 'actions/action-types';

var initialState = {
	work_order: null,
	work_order_pending: false,
	work_order_save_pending: false,
	work_order_unsubscribe: false,

	work_order_activity_log: [],
    work_order_activity_log_pending: false,
    work_order_activity_log_save_pending: false,
    work_order_activity_log_fulfilled: false,
    work_order_activity_log_unsubscribe: false,

	work_order_attachments: [],
    work_order_attachments_pending: false,
    work_order_attachments_save_pending: false,
    work_order_attachments_fulfilled: false,
    work_order_attachments_unsubscribe: false,

	work_order_external_invoices: [],
    work_order_external_invoices_pending: false,
    work_order_external_invoices_save_pending: false,
    work_order_external_invoices_fulfilled: false,
    work_order_external_invoices_unsubscribe: false,

	work_order_items: [],
	work_order_items_pending: false,
	work_order_items_unsubscribe: false,
	
	work_orders: [],
	work_orders_pending: false,
	work_orders_save_pending: false,
	work_orders_unsubscribe: false,
	
	work_orders_dashboard: [],
	work_orders_dashboard_pending: false,
	work_orders_dashboard_save_pending: false,
	work_orders_dashboard_unsubscribe: false,

	work_orders_incomplete: [],
	work_orders_incomplete_pending: false,
	work_orders_incomplete_save_pending: false,
	work_orders_incomplete_unsubscribe: false,

	work_orders_open: [],
	work_orders_open_pending: false,
	work_orders_open_save_pending: false,
	work_orders_open_unsubscribe: false,

	work_orders_technician: [],
	work_orders_technician_pending: false,
	work_orders_technician_save_pending: false,
	work_orders_technician_unsubscribe: false,

	work_order_popup_pending: false,
	new_work_order_pending: false,

	new_service_dragging: {},
	new_service_dragging_active: false,

	service_dragging_active: false,
	service_resize_dragging_active: false,

	checkin: [],
	checkin_pending: false,
	checkin_unsubscribe: false,
};

const workOrdersReducer = (state = initialState, action) => {

	switch(action.type) {
		case types.WORK_ORDER + '_PENDING': pending('work_order'); break;
		case types.WORK_ORDER + '_SAVE_PENDING': save_pending('work_order'); break;
        case types.WORK_ORDER + '_SAVE_FULFILLED': save_fulfilled('work_order'); break;
		case types.WORK_ORDER + '_FULFILLED': fulfilled('work_order'); break;

		case types.WORK_ORDER + '_' + types.ACTIVITY_LOG + '_PENDING': pending('work_order_activity_log'); break;
        case types.WORK_ORDER + '_' + types.ACTIVITY_LOG + '_FULFILLED': fulfilled('work_order_activity_log'); break;
        case types.WORK_ORDER + '_' + types.ACTIVITY_LOG + '_CLEAR': clear('work_order_activity_log', []); break;

		case types.WORK_ORDER + '_' + types.ATTACHMENTS + '_PENDING': pending('work_order_attachments'); break;
        case types.WORK_ORDER + '_' + types.ATTACHMENTS + '_SAVE_PENDING': save_pending('work_order_attachments'); break;
        case types.WORK_ORDER + '_' + types.ATTACHMENTS + '_FULFILLED': fulfilled('work_order_attachments'); break;
        case types.WORK_ORDER + '_' + types.ATTACHMENTS + '_SAVE_FULFILLED': save_fulfilled('work_order_attachments'); break;
        case types.WORK_ORDER + '_' + types.ATTACHMENTS + '_CLEAR': clear('work_order_attachments', []); break;
		
		case types.WORK_ORDER + '_' + types.EXTERNAL_INVOICES + '_PENDING': pending('work_order_external_invoices'); break;
        case types.WORK_ORDER + '_' + types.EXTERNAL_INVOICES + '_SAVE_PENDING': save_pending('work_order_external_invoices'); break;
        case types.WORK_ORDER + '_' + types.EXTERNAL_INVOICES + '_FULFILLED': fulfilled('work_order_external_invoices'); break;
        case types.WORK_ORDER + '_' + types.EXTERNAL_INVOICES + '_SAVE_FULFILLED': save_fulfilled('work_order_external_invoices'); break;
        case types.WORK_ORDER + '_' + types.EXTERNAL_INVOICES + '_CLEAR': clear('work_order_external_invoices', []); break;

		case types.WORK_ORDER_ITEMS + '_PENDING': pending('work_order_items'); break;
		case types.WORK_ORDER_ITEMS + '_FULFILLED': fulfilled('work_order_items'); break;
		case types.WORK_ORDER_ITEMS + '_CLEAR': state = { ...state, work_order_items: [] }; break;
		
		case types.WORK_ORDERS + '_PENDING': pending('work_orders'); break;
		case types.WORK_ORDERS + '_SAVE_PENDING': save_pending('work_orders'); break;
		case types.WORK_ORDERS + '_FULFILLED': fulfilled('work_orders'); break;
		case types.WORK_ORDERS + '_CLEAR': state = { ...state, work_orders: [] }; break;
		
		case types.WORK_ORDERS_DASHBOARD + '_PENDING': pending('work_orders_dashboard'); break;
		case types.WORK_ORDERS_DASHBOARD + '_SAVE_PENDING': save_pending('work_orders_dashboard'); break;
		case types.WORK_ORDERS_DASHBOARD + '_FULFILLED': fulfilled('work_orders_dashboard'); break;
		case types.WORK_ORDERS_DASHBOARD + '_CLEAR': state = { ...state, work_orders_dashboard: [] }; break;
		
		case types.WORK_ORDERS_INCOMPLETE + '_PENDING': pending('work_orders_incomplete'); break;
		case types.WORK_ORDERS_INCOMPLETE + '_SAVE_PENDING': save_pending('work_orders_incomplete'); break;
		case types.WORK_ORDERS_INCOMPLETE + '_FULFILLED': fulfilled('work_orders_incomplete'); break;
		case types.WORK_ORDERS_INCOMPLETE + '_CLEAR': state = { ...state, work_orders_incomplete: [] }; break;
		
		case types.WORK_ORDERS_OPEN + '_PENDING': pending('work_orders_open'); break;
		case types.WORK_ORDERS_OPEN + '_SAVE_PENDING': save_pending('work_orders_open'); break;
		case types.WORK_ORDERS_OPEN + '_FULFILLED': fulfilled('work_orders_open'); break;
		case types.WORK_ORDERS_OPEN + '_CLEAR': state = { ...state, work_orders_open: [] }; break;
		
		case types.WORK_ORDERS_TECHNICIAN + '_PENDING': pending('work_orders_technician'); break;
		case types.WORK_ORDERS_TECHNICIAN + '_SAVE_PENDING': save_pending('work_orders_technician'); break;
		case types.WORK_ORDERS_TECHNICIAN + '_FULFILLED': fulfilled('work_orders_technician'); break;
		case types.WORK_ORDERS_TECHNICIAN + '_CLEAR': state = { ...state, work_orders_technician: [] }; break;
		
		case types.CHECKIN + '_PENDING': pending('checkin'); break;
		case types.CHECKIN + '_SAVE_PENDING': save_pending('checkin'); break;
		case types.CHECKIN + '_FULFILLED': fulfilled('checkin'); break;
		
		case types.WORK_ORDER_DRAG_START: 
			state = { ...state, service_dragging_active: true };
			break;
		case types.WORK_ORDER_DRAG_END: 
			state = { ...state, service_dragging_active: false };
			break;
		case types.WORK_ORDER_POPUP + '_PENDING': 
			state = { ...state, work_order_popup_pending: true };
			break;
		case types.WORK_ORDER_POPUP + '_FULFILLED': 
			state = { ...state, work_order_popup_pending: false };
			break;
		case types.NEW_WORK_ORDER + '_PENDING': 
			state = { ...state, 
				new_work_order_pending: true,
				new_service_dragging: {
					id: 0,
					deleted: false,
					statusId: 'dragging',
					dates: [],
					_displayName: 'Dispatch',
					startDate: { seconds: action.data.startDate?.format('X') },
					endDate: { seconds: action.data.endDate?.format('X') },
					technicianId: action.data.technicianId,
					completed: true,
				}
			};
			break;
		case types.NEW_WORK_ORDER + '_FULFILLED': 
			state = { ...state, 
				new_work_order_pending: false,
			};
			break;
		case types.NEW_WORK_ORDER + '_CANCEL': 
			state = { ...state, 
				new_work_order_pending: false,
				new_service_dragging: {},
			};
			break;
		case types.NEW_WORK_ORDER_DRAG_START: 
			state = { ...state, 
				new_service_dragging_active: true,
				new_service_dragging: {
					deleted: false,
					statusId: 'dragging',
					dates: [],
					_displayName: 'Joel Dispatch',
					startDate: { seconds: action.data.startDate?.format('X') },
					endDate: { seconds: action.data.startDate?.format('X') },
					technicianId: action.data.technicianId,
					completed: false,
				}
			};
			break;
		case types.NEW_WORK_ORDER_DRAG_MOVE: 
			console.log(action.data.endDate?.format('MM-DD-YY H:mm A'))
			state = { ...state,
				new_service_dragging: {
					...state.new_service_dragging,
					endDate: { seconds: action.data.endDate?.format('X') },
				}
			};
			break;
		case types.NEW_WORK_ORDER_DRAG_END:
			console.log(action.data.endDate?.format('MM-DD-YY H:mm A'))
			state = { ...state,
				new_service_dragging_active: false,
				new_service_dragging: { ...state.new_service_dragging,
					endDate: { seconds: action.data.endDate?.format('X') },
					completed: true,
				}
			};
			break;


		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {
		let unsubscribe = state[table + '_unsubscribe']

		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = { ...state,
			[table + '_save_pending']: false,
		};
	}
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        };
    }
}
export default workOrdersReducer;


