import firebase from './firebase'; // THIS MUST BE FIRST
import "react-multi-date-picker/styles/layouts/mobile.css";

import App from 'components/app';
import React from 'react';
import metadata from './metadata.json';
import store from 'store';
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
import { RegisterCompany } from 'components/authentication/register-company';
import { VerifyHandle } from 'components/authentication/verify-handle';
import { createRoot } from 'react-dom/client';

window.build = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;
window.toastr.options.preventDuplicates = true;

const container = document.getElementById('root');
const root = createRoot(container);

// DeprecationWarning: 'onAfterSetupMiddleware' option is deprecated:
// https://www.youtube.com/watch?v=ifSTp9WEHpo


root.render(
	<Provider store={ store }>
		<Router>
			<Switch>
				<Route exact path={ '/' } render={ (route_props) =>
					<VerifyHandle firebase={firebase} dispatch={store.dispatch} {...route_props} />
				} />
				<Route exact path={ '/:handle' } render={ (route_props) =>
					<Redirect to={`/${route_props.match.params.handle}/1/login`} />
				} />
				<Route exact path={ '/:handle/register_company' } render={(route_props) =>
					<RegisterCompany handle={route_props.match.params.handle} registrationCallback={async (data) => {

						// Create new company and new user
						try {
							// firebase.functions().useEmulator('localhost', 5001);
							await firebase.functions().httpsCallable('registerNewCompany')(data);
							localStorage.setItem('mobileTrackHandle', route_props.match.params.handle);
							if (window.flutter) window.flutter.callHandler('handle', route_props.match.params.handle);

							firebase.auth().createUserWithEmailAndPassword(data.email, data.password).then((_) => {
								window.location.href = `#/${data.handle}/dashboard`; // history & redirect won't work top level
							}).catch((error) => {
								// If user already exists display warning
								if (error.code == 'auth/email-already-in-use') {
									firebase.auth().signInWithEmailAndPassword(data.email, data.password).then((_) => {
										window.location.href = `#/${data.handle}/dashboard`; // history & redirect won't work top level
									}).catch((error) => {
										window.toastr.error('Error attempting to log into existing account. ' + error.message, 'Existing User');
										window.location.href = `#/${data.handle}/login`; // history & redirect won't work top level
									});
								} else if (error) {
									window.toastr.error(error.message)
								}
							});
						} catch(error) {
							window.toastr.error(error.message)
						}
						
					}} />
				} />
				<Route path={ '/:handle/:appId' } render={(route_props) =>
					<ProSidebarProvider>
						<App />
					</ProSidebarProvider>
				} />
			</Switch>
		</Router>
	</Provider>
);
