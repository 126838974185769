import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { Ibox, Table } from 'enspire-manager-framework';
import { LOG, ACTION } from 'components/constants';
import { animateScroll as scroll } from 'react-scroll';
import { confirmDelete, imageSize } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AttachmentsTab = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    
	const admin = useSelector((store) => store.admin);
    const table = useSelector((store) => store[props.store]);
    const user_permissions = useSelector(store => store.users?.user_permissions);

    const [focusedInput, setFocused] = useState(null);
    
    const field = (props.field) ? props.field : 'attachments';
    const attachments = table?.[props.singular + '_' + field] ?? [];

    useEffect(() => {
		dispatch(actions_attachments.subAttachments(params.handle, props.id, props.source, props.prefix, { field }));
		return () => {
			const unsubscribe = table[props.singular + '_attachments_unsubscribe'];
			if (typeof unsubscribe == 'function') unsubscribe();
		};
	}, [params.handle, props.id, props.source]);

    useEffect(() => {
		if (focusedInput) {
			var pos = (document.getElementById(props.columnId).scrollTop + focusedInput.getBoundingClientRect().top) - 160;
			scroll.scrollTo(pos, { duration: 500, delay: 0, smooth: 'easeInOutQuart', containerId: props.columnId });
		}
	}, [focusedInput]);

    /* Handlers --------------------------*/

    const handleAddNew = () => ({ name: '', created: new Date() });

    const handleSaveNew = (newItem) => {
        saveAttachments([ ...(attachments), newItem ], ACTION.CREATED.key, newItem);
    };
    const handleEdit = (field, item, newValue) => {
        let attach = _.filter(attachments, (o) => o.name != item.name);
        let updatedAttachment = _.find(attachments, { name: item.name });
        updatedAttachment[field] = newValue;
        saveAttachments([ ...attach, updatedAttachment ], ACTION.MODIFIED.key, updatedAttachment);
    };
    const handleEditDropzone = (results, item) => {
        let attach = _.filter(attachments, (o) => o.name != item.name);
        let updatedAttachment = _.find(attachments, { name: item.name });

        let imageUrls = updatedAttachment.imageUrls ?? [];
        let index = imageUrls.length;
        for (var result of results) {
            var [field, downloadUrl] = result;
            imageUrls.push({ position: index, downloadUrl});
            index++;
        }
        updatedAttachment.imageUrls = imageUrls;
        saveAttachments([ ...attach, updatedAttachment ], ACTION.MODIFIED.key, updatedAttachment);
    }
    const handleDelete = (item) => {
        let attach = _.filter(attachments, (o) => o.name != item.name);
        saveAttachments([ ...attach ], ACTION.DELETED.key, attach);
    };
    const handleValidation = async (newItem) => {
        let existing = _.find(attachments, (o) => { return o.name?.toLowerCase() == newItem.name?.toLowerCase() });
        if (existing) {
            return ({ error: true, message: "An Attachment already exists with this name (perhaps archived)" });
        }
    };
    
    /* Actions --------------------------*/
    
    const toggleLightbox = (field, image) => {
        let images = _.orderBy(image, ['position'], ['asc']).map((img, index) => ({ src: imageSize(img.downloadUrl, 'lg') }));
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources(images));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}
    const saveAttachments = (attachmentsArray, action, item) => {
        dispatch(actions_attachments.saveAttachment(params.handle, props.id, attachmentsArray, props.source, props.prefix, (assetId) => {
            dispatch(actions_admin.saveActivity(params.handle, props.prefix, action, props.id, `Attachment: ${item.name}`));
        }, { field }));
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Name', field: 'name', width: (props.source != 'work-orders' || (props.source == 'work-orders' && field != 'external_invoices'))?'80':'50', edit: { 
            valid: ['required'], 
            type: 'text', 
            callback: handleEdit, 
        }},
        ...(props.source == 'assets') ? [{ name: 'Expiration', field: 'expiration', type: 'date', format: 'MMM Do, YYYY', width: '30', edit: { 
            type: 'date',
            callback: handleEdit, 
        }}] : [],
        ...(props.source == 'work-orders' && field == 'external_invoices') ? [{ name: 'Cost', field: 'price', type: 'number', format: 'usd', width: '30', edit: { 
            type: 'usd',
            callback: handleEdit, 
        }}] : [],
        { name: '', field: 'imageUrls', width: '20', edit: { 
            bin: params.handle,
            buttonTitle: 'View',
            callback: handleEditDropzone, 
            camera: true,
            directory: `attachments/${props.store}${props.id}`,
            firebase: firebase,
            lightboxCallback: toggleLightbox,
            multiple: true,
            type: 'dropzone',
            uploadTitle: <strong className="text-nowrap">+ <i className={'fa fa-camera'}></i></strong>,
            viewTitle: <i className={'fa fa-camera'}></i>,
        }},
    ];

    return (
        <Ibox title={(props.title) ? props.title : "Attachments" }>

            <Table id="attachments-tab"
                data={ attachments }
                button={(props.button) ? props.button : '+ Attachment'}
                button_callback={handleAddNew}
                button_in_ibox={true}
                button_disabled={!user_permissions.ASSETS_CREATE && !user_permissions.ASSETS_EDIT}
                columns={columns}
                delete={true}
                on_delete={(item) => confirmDelete("item", () => handleDelete(item))}
                on_focus={(target) => setFocused(target)}
                order_by={{ fields: ['name'], direction: ['asc'] }}
                save_new_callback={handleSaveNew}
                style={{ minHeight: '330px' }}
                validation_callback={handleValidation}
            >
            </Table>

            { admin.flutter_keyboard &&
                <div style={{ paddingBottom: '55vh' }}></div>
            }
        </Ibox>
    );
};

export default AttachmentsTab;