import ServiceItemsOption from 'components/line-items/service-items-option';
import * as quotes_actions from 'actions/quotes-actions';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Checkbox } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import { QUOTES } from 'components/constants';

const LineItemsAccordion = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const dispatch = useDispatch();

    const quotes = useSelector((store) => store.quotes);
    const quote = quotes?.quote;
    const options = props.options;

    const [open, setOpen] = useState('0');

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (index) => {
        setOpen(index);
    };

    if (quote?.statusId == QUOTES.ACCEPTED.id || quote?.statusId == QUOTES.CHANGES_REQUESTED.id) {
        options = options?.filter((option) => option.index != quote.approvedOption);
        options?.unshift(quote.approvedOption);
    }

    return (

        <>
            <Accordion defaultActiveKey={'0'}>
                <>
                    { options?.map((option, index) => (
                        <Card key={index} className="card-table">
                            <Accordion.Toggle className="py-3" as={Card.Header} 
                                eventKey={index.toString()} 
                                onClick={() => handleClick(index.toString())}
                                style={{ cursor: 'pointer', ...(open == index.toString()) ? { pointerEvents: 'none' } : {} }} 
                            >
                                <h4 className='d-inline mr-3 align-middle'>{option.name}</h4>
                                <span className='float-right'>
                                    <i className={`fa fa-chevron-${(open == index.toString()) ? 'up' : 'down'}`}></i>
                                </span>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body>
                                    <ServiceItemsOption option={option} handleApproval={props.handleApproval} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </>
            </Accordion>
        </>
    );
};

export default LineItemsAccordion;