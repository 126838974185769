import * as types from 'actions/action-types';

var initialState = {
	service_request: null,
	service_request_pending: false,
	service_request_save_pending: false,
	service_request_unsubscribe: false,

	service_request_activity_log: [],
    service_request_activity_log_pending: false,
    service_request_activity_log_fulfilled: false,
    service_request_activity_log_unsubscribe: false,

	service_request_attachments: [],
    service_request_attachments_pending: false,
    service_request_attachments_fulfilled: false,
    service_request_attachments_unsubscribe: false,

	service_request_invoices: [],
    service_request_invoices_pending: false,
    service_request_invoices_fulfilled: false,
    service_request_invoices_unsubscribe: false,

	service_requests: [],
	service_requests_pending: false,
	service_requests_save_pending: false,
	service_requests_unsubscribe: false,
	
	service_requests_dashboard: [],
	service_requests_dashboard_pending: false,
	service_requests_dashboard_save_pending: false,
	service_requests_dashboard_unsubscribe: false,
	
	service_requests_map_coordinates: null,
	service_requests_map_coordinates_pending: false,
};

const serviceRequestsReducer = (state = initialState, action) => {

	switch(action.type) {
		case types.SERVICE_REQUEST + '_PENDING': pending('service_request'); break;
		case types.SERVICE_REQUEST + '_SAVE_PENDING': save_pending('service_request'); break;
		case types.SERVICE_REQUEST + '_SAVE_FULFILLED': save_fulfilled('service_request'); break;
		case types.SERVICE_REQUEST + '_FULFILLED': fulfilled('service_request'); break;
		case types.SERVICE_REQUEST + '_CLEAR': clear('service_request', {}); break;
		
		case types.SERVICE_REQUEST + '_' + types.ACTIVITY_LOG + '_PENDING': pending('service_request_activity_log'); break;
        case types.SERVICE_REQUEST + '_' + types.ACTIVITY_LOG + '_FULFILLED': fulfilled('service_request_activity_log'); break;
        case types.SERVICE_REQUEST + '_' + types.ACTIVITY_LOG + '_CLEAR': clear('service_request_activity_log', []); break;
		
		case types.SERVICE_REQUEST + '_' + types.ATTACHMENTS + '_PENDING': pending('service_request_attachments'); break;
        case types.SERVICE_REQUEST + '_' + types.ATTACHMENTS + '_FULFILLED': fulfilled('service_request_attachments'); break;
        case types.SERVICE_REQUEST + '_' + types.ATTACHMENTS + '_CLEAR': clear('service_request_attachments', []); break;
		
		case types.SERVICE_REQUEST + '_' + types.INVOICES + '_PENDING': pending('service_request_invoices'); break;
        case types.SERVICE_REQUEST + '_' + types.INVOICES + '_FULFILLED': fulfilled('service_request_invoices'); break;
        case types.SERVICE_REQUEST + '_' + types.INVOICES + '_CLEAR': clear('service_request_invoices', []); break;
		
		case types.SERVICE_REQUESTS + '_PENDING': pending('service_requests'); break;
		case types.SERVICE_REQUESTS + '_SAVE_PENDING': save_pending('service_requests'); break;
		case types.SERVICE_REQUESTS + '_FULFILLED': fulfilled('service_requests'); break;
		case types.SERVICE_REQUESTS + '_CLEAR': clear('service_requests', []); break;

		case types.SERVICE_REQUESTS_DASHBOARD + '_PENDING': pending('service_requests_dashboard'); break;
		case types.SERVICE_REQUESTS_DASHBOARD + '_SAVE_PENDING': save_pending('service_requests_dashboard'); break;
		case types.SERVICE_REQUESTS_DASHBOARD + '_FULFILLED': fulfilled('service_requests_dashboard'); break;
		case types.SERVICE_REQUESTS_DASHBOARD + '_CLEAR': clear('service_requests_dashboard', []); break;

		case types.SERVICE_REQUEST_MAPBOX_SEARCH + '_PENDING': pending('service_requests_map_coordinates'); break;
		case types.SERVICE_REQUEST_MAPBOX_SEARCH + '_FULFILLED': fulfilled('service_requests_map_coordinates'); break;

		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {
		let unsubscribe = state[table + '_unsubscribe']

		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function save_fulfilled(table) {
		state = { ...state,
			[table + '_save_pending']: false,
		};
	}
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        }
    }
}
export default serviceRequestsReducer;


