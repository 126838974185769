import React, { memo } from 'react';
import { createRipples } from 'react-ripples';

export const WidgetTask =  memo(function WidgetTask(props) {

	var cursor = (props.onClick) ? 'pointer' : 'auto';

	const MyRipples = createRipples({
		color: (props.active || props.color_number == 8) ? 'lightGrey' : 'white',
		during: 1800,
	});

	return (
		<>
			<div className={ props.grid }>
				{ !!props.badge &&
					<span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '10px', top: '10px', borderRadius: '.5rem' }}>
						{props.badge}
					</span>
				}
				<MyRipples>
					<div className={ `m-0 ${props.className} ${(!props.active) ? 'bg-white' : 'bg-primary' }`} 
						style={{ cursor: cursor, height: '130px', overflow: 'hidden', borderRadius: '5px',
						...(props.active) ? { color: 'white' } : { color: '#666666'  },
						...(!props.active) ? (props.backgroundColor) ? { backgroundColor: props.backgroundColor } : {} : {}, 
						}} 
						onClick={ () => {
							setTimeout(() => {
								props.onClick(props.value) 
							}, 250);
						}}
					>
						<div className="px-2 py-3">
							<div className={`text-center mb-2`}>
								{ React.isValidElement(props.icon) &&
									<>{ props.icon }</>
								}
							</div>
							
							<div className="text-center line-clamp-3" style={{ 
								height: '60px',  
								fontSize: '12px',
								display: '-webkit-flex',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
								<span>{ props.text }</span>
							</div>
						</div>
					</div>
				</MyRipples>
			</div>
		</>
	);
});
