import * as actions_assets from 'actions/assets-actions';
import * as actions_inventory from 'actions/stock-parts-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import AddItemForm from 'components/line-items/add-item-form/add-item-form';
import React, { useEffect, useRef, useState } from 'react';
import StockPartsForm from 'components/inventory/inventory-form/inventory-form';
import _ from 'lodash';
import { APPS, SERVICE_ITEMS } from 'components/constants';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ModalForm, elasticSearch, ModalAlert, Table } from 'enspire-manager-framework';
import { sortByField, toProperCase } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

const LineItemsBuilder = (props) => {
    
    /* Hooks -----------------------------------------------------------------------------------------------------------------------*/
    
	const maxFilters = 6;
    const params = useParams();
    const history = useHistory();
    const tableRef = useRef();
    const partRef = useRef();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    const seg = 14;

    const asset = useSelector((store) => store.assets.assets)?.find((asset) => asset.id == params.asset_id);
    const assetTypes = useSelector((store) => store.settings.settings_asset_types);
    const partsCategories = useSelector((store) => store.settings.settings_parts_categories);
    const settings = useSelector((store) => store.settings.settings);
    const invoice = useSelector((store) => store.invoices.invoice);
    const purchaseOrder = useSelector((store) => store.inventory.purchase_order);
    const recentItems = useSelector((store) => store.users.user_recent_items);
    const inventory = useSelector((store) => store.inventory);
    const user = useSelector((store) => store.users.user);
    const workOrders = useSelector((store) => store.workOrders);
	const inventoryIndex = useSelector((store) => store.settings.settings?.values?.inventoryIndex);

    const assetType = _.find(assetTypes, { id: params.asset_type_id });

    const categoriesByType = (params.appId == APPS.SERVICE.id)
    ?	_.filter(partsCategories, { type: 'service' })
    :	_.filter(partsCategories, { type: 'stock' });

	const categoriesFiltered = (parseInt(params.asset_type_id))
		?	[ ..._.filter(categoriesByType, (o) => assetType?.categoryIds?.includes(o.id))]
		:	[ ...categoriesByType];

    const uncategorizedOption = (categoriesFiltered.length < maxFilters)
		?	{ name: <i title="Uncategorized" className="fas fa-ban"></i>, id: '-' }
		:	{ name: 'Uncategorized', id: '-' };

	const categoryFilters = [ ...categoriesFiltered, uncategorizedOption];


	// const categoryFilters = [ ..._.filter(categories, (o) => assetType?.categoryIds?.includes(o.id)), { name: <i title="Uncategorized" className="fas fa-ban"></i>, id: '-' }];

    const [typeId, setTypeId] = useState();
    const [category, setCategory] = useState(null);
    const [search, setSearch] = useState('');
    const [changes, setChanges] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [state, setState] = useState({
        hits: [],
        search: '',

        lineItems: [],
        openIndex: null,
        container_height: 0,
        show_non_stock_form: false,
    });

    const segments = routeLocation.pathname.split('/');
    const isAssets = (params.appId == APPS.ASSETS.id);
    const isPurchaseOrder = props.source == 'vendors';
    // const isWorkOrders = (props.source == 'work-orders');
    // const isQuotes = (props.source == 'quotes');
    // const isInvoice = props.source == 'invoice';
    // const isWorkOrder = props.source == 'work-order';

    /* Effects ---------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        setCategory(null);
        tableRef.current?.setFilterButton(null);
        tableRef.current?.stopNewItem();
        if (typeId != SERVICE_ITEMS.LABOR.id && typeId != SERVICE_ITEMS.TRAVEL.id) updateSearch(search, typeId, null);
    }, [typeId]);    
    
    useEffect(() => {
        updateSearch(search, typeId, category);
    }, [recentItems]);    
    
    useEffect(() => {
        setTimeout(() => {
            if (typeId) updateSearch(search, typeId, category);
		}, 500);
	}, [inventoryIndex, inventory]);
    
    // This useEffect should be last to override the initial value for typeId from the previous useEffects
    useEffect(() => {
        if (isAssets) {
            setTypeId(SERVICE_ITEMS.STOCK_PARTS.id);
        } else {
            setTypeId(SERVICE_ITEMS.SERVICE_ITEMS.id);
        } 
    }, [isAssets]);

    /* Handlers ------------------------ --------------------------------------------------------------------------------------------*/

    const handleSearch = (search) => {
        setSearch(search);
        updateSearch(search, typeId, category);
    };
    const handleFilter = (filter) => {
        updateSearch(search, typeId, filter);
        setCategory(filter);
    };
    const handleEditHit = (field, updatedItem, newValue) => {
        let newHits = state.hits.map((item, index) => {
            if (item.id == updatedItem.id) {
                return { ...item, [field]: newValue };
            } else {
                return item;
            }
        });
        setState(prev => ({ ...prev, hits: newHits }));
        setChanges(true);
    };

    /* Actions ---------------------------------------------------------------------------------------------------------------------*/

    const addLabor = () => {
        let newItem = {
            id: "0000",
            type: SERVICE_ITEMS.LABOR.id,
            name: 'Labor',
            description: '',
            count: 1,
            price: settings.invoices?.defaultLaborRate ?? 0,
            calculatedPrice: (settings.invoices?.defaultLaborRate) ? parseFloat(settings.invoices?.defaultLaborRate) : 0,
        };    
        setState(prev => ({ ...prev, hits: [newItem] }));
    };  
    const addTravel = () => {
        let newItem = {
            id: "0000",
            type: SERVICE_ITEMS.TRAVEL.id,
            name: 'Travel',
            description: '',
            count: 1,
            price: settings.invoices?.defaultTravelRate ?? 0,
            calculatedPrice: (settings.invoices?.defaultTravelRate) ? parseFloat(settings.invoices?.defaultTravelRate) : 0,
        };    
        setState(prev => ({ ...prev, hits: [newItem] }));
    };  
    const finishBuilder = () => {
        if (changes) {
            ModalAlert({
                title: 'Close Line Items?',
                html: `You have not added (+) items updated on this page`,
                icon: 'warning',
                confirm_color: '#8FBC8B',
                confirm_text: 'Yes, close',
                show_cancel: true,
                callback_success: () => { history.goBack(); }
            });
        } else {
            history.goBack();
        }
    } 
    const openInventoryForm = (id) => {
        let location = toolbox.modifyPath(routeLocation?.pathname, seg, `add_parts${(id) ? `/${id}` : ''}`);
        history.push({ pathname: location });
    };
    const openAddItemForm = (item) => {
        setSelectedItem(item);
        let location = toolbox.modifyPath(routeLocation?.pathname, seg, `add_item`);
        history.push({ pathname: location });
    };
    const addItem = (item) => {
        setChanges(false);
        if (!item.description) item.description = '';
        props.addItem(item);
    } 
    const linkToAsset = (id) => {
		dispatch(actions_assets.saveStockPartToAsset(params.handle, params.asset_id, id, false));
    }    
    const movePart = (id, typeId) => {
        dispatch(actions_inventory.saveStockPart(params.handle, { type: typeId }, id, (stockPartId) => {
            // let action = (parseInt(partId)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
            // dispatch(actions_admin.saveActivity(params.handle, LOG.STOCK_PART.key, action, stockPartId, state.stockPart.name, state.stockPart ));
        }));
    };
    const updateSearch = async (search, type, category) => {

        try {
            const assetHasStockparts = type == SERVICE_ITEMS.STOCK_PARTS.id && !search && !category && asset?.parts?.length;
            const isRecentlyUsed = type == SERVICE_ITEMS.NON_STOCK_PARTS.id && !search && !category && Object.keys(recentItems?.items?.recent ?? []).length;

			const indexHandle = 'mt-' + params.handle + ((isDev) ? '-dev' : '');
            let config = {
                table: indexHandle + '-stock-parts',
                fields: ['name', 'partNumber', 'manufacturer', 'description', 'type'],
                filter: [
                    ...(parseInt(params.asset_type_id)) ? [{ field: 'assetTypeIds', value: params.asset_type_id }] : [],
                    { field: 'type', value: type },
                    { field: 'categoryIds', value: category },
                    { field: 'vendorIds', value: (isPurchaseOrder) ? params.profile_id : null },
                ],
            };

            // Sort recentItems and trim list over 50
            if (assetHasStockparts) config.ids = asset.parts;
            if (isRecentlyUsed) {
                var recentIds = sortByField(Object.keys(recentItems.items.recent)?.map((key) => ({ id: key, date: recentItems.items.recent[key] })), 'date', true);
                if (recentIds.length > 50) {
                    dispatch(actions_users.trimRecent(params.handle, recentIds, type, user.id));
                }
                recentIds = recentIds.slice(0, 50);
                config.ids = recentIds.map((o) => o.id);
            }

            // Add category names
            let hits = await elasticSearch(search, config);
            hits = hits?.map((hit) => ({ ...hit, 
                count: 1,
                categoryList: hit?.categoryIds?.map((id) => ({ value: partsCategories.find((o) => o.id == id)?.name }))
            }));

            // Add group headings
            if (isPurchaseOrder) {
                hits = hits?.map((hit) => ({ ...hit, groupHeading: 'Stock Parts for this Vendor' }));
            } else if (assetHasStockparts) {
                hits = hits?.map((hit) => ({ ...hit, groupHeading: 'linked to this Asset' }));
            } else if (isRecentlyUsed) {
                hits = hits?.map((hit) => ({ ...hit, groupHeading: 'Recently Used Items' })).sort((a, b) => recentItems.items?.recent[b.id] - recentItems.items.recent[a.id]);
            }

            setState(prev => ({ ...prev, hits, search }));
            return false; // prevent <Enter> key from reloading
        } catch (error) {
            window.toastr.error(error.message);
        }
    };

    /* Constants -------------------------------------------------------------------------------------------------------------------*/

    const data = state.hits.map((hit) => ({
        ...hit,
        inventory: hit.currentInventory ?? '' + ((parseInt(hit.pendingInventory)) ? ` (${hit.pendingInventory})` : ''),
        ...(asset?.parts?.includes(hit.id)) ? { _hide_actions: [1] } : {},
    }));

    var filterButtons = [
        ...(isAssets)
        ? [<Button key={SERVICE_ITEMS.STOCK_PARTS.id} variant={(typeId == SERVICE_ITEMS.STOCK_PARTS.id) ? 'primary' : "default"} onClick={() => { setTypeId(SERVICE_ITEMS.STOCK_PARTS.id); }} >Stock Parts</Button>]
        : [<Button key={SERVICE_ITEMS.SERVICE_ITEMS.id} variant={(typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) ? 'primary' : "default"} onClick={() => { setTypeId(SERVICE_ITEMS.SERVICE_ITEMS.id); }} >Service Items</Button>],
        <Button key={SERVICE_ITEMS.NON_STOCK_PARTS.id} variant={(typeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) ? 'primary' : "default"} onClick={() => { setTypeId(SERVICE_ITEMS.NON_STOCK_PARTS.id); }} >Non-Stock Parts</Button>,
        <Button key={SERVICE_ITEMS.LABOR.id} variant={(typeId == SERVICE_ITEMS.LABOR.id) ? 'info' : "default"} onClick={() => { setTypeId(SERVICE_ITEMS.LABOR.id); addLabor()}}>Labor</Button>,
        <Button key={SERVICE_ITEMS.TRAVEL.id} variant={(typeId == SERVICE_ITEMS.TRAVEL.id) ? 'info' : "default"} onClick={() => { setTypeId(SERVICE_ITEMS.TRAVEL.id); addTravel()}}>Travel</Button>,
    ];

    var columns =[];

    if (typeId == SERVICE_ITEMS.LABOR.id) columns = [
        { name: 'Name', field: 'name', width: 45 },
        { name: 'Hours', field: 'count', width: 20, edit: { type: 'text', callback: handleEditHit }},
        { name: 'Rate', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: 20, edit: { type: 'usd', callback: handleEditHit }},
        { name: 'Add', field: 'id', type: 'button', button: { name: <i className="fas fa-plus"></i>, className: 'btn-primary btn-xs', callback: (item) => { addLabor(); openAddItemForm(item); }}, width: 15 },
        // { name: 'Description', field: 'description', width: 65, edit: { type: 'text', placeholder: '(description)', callback: handleEditHit }},
    ];

    if (typeId == SERVICE_ITEMS.TRAVEL.id) columns = [
        { name: 'Name', field: 'name', width: 45 },
        { name: 'Hours', field: 'count', width: 20, edit: { type: 'text', callback: handleEditHit }},
        { name: 'Rate', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: 20, edit: { type: 'usd', callback: handleEditHit }},
        { name: 'Add', field: 'id', type: 'button', button: { name: <i className="fas fa-plus"></i>, className: 'btn-primary btn-xs', callback: (item) => { addLabor(); openAddItemForm(item); }}, width: 15 },
        // { name: 'Description', field: 'description', width: 65, edit: { type: 'text', placeholder: '(description)', callback: handleEditHit }},
    ];

    if (typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) columns = [
        { name: 'Name', field: 'name', width: 70 },
        { name: 'Category', field: 'categoryList', type: 'label', width: 20 },
        { name: 'Add', field: 'id', type: 'button', button: { name: <i className="fas fa-plus"></i>, className: 'btn-primary btn-xs', callback: addItem }, width: 10 },
        // { name: 'Add', field: 'id', type: 'button', button: { name: <i className="fas fa-plus"></i>, className: 'btn-primary btn-xs', callback: openAddItemForm }, width: 10 },
        { name: '', field: 'id', type: 'actions', width: 5, button: {
            title: 'More Options',
            name: <span className="material-icons">more_vert</span>,
            className: 'p-0 text-muted',
            links: [{ name: 'Edit Service Items', callback: (item) => openInventoryForm(item.id)}]
        }},
    ];

    if (typeId == SERVICE_ITEMS.STOCK_PARTS.id) columns = [
        { name: 'Name', field: 'name', width: 50 },
        { name: 'Category', field: 'categoryList', type: 'label', width: 10 },
        { name: 'Add', field: 'id', type: 'button', button: { name: <i className="fas fa-plus"></i>, className: 'btn-primary btn-xs', callback: openAddItemForm }, width: 5 },
        { name: '', field: 'id', type: 'actions', width: 5, button: {
            title: 'More Options',
            name: <span className="material-icons">more_vert</span>,
            className: 'p-0 text-muted',
            links: [
                { name: 'Edit Stock part', callback: (item) => openInventoryForm(item.id)},
                { name: 'Link to this Asset', callback: (item) => linkToAsset(item.id)},
            ]
        }},
    ];

    if (typeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) columns = [
        { name: 'Name', field: 'name', width: 75 },
        { name: 'Count', field: 'count', text_align: 'center', width: 5, edit: { type: 'text', callback: handleEditHit }},
        { name: 'Price', field: 'averageCost', type: 'number', format: 'usd', text_align: 'right', width: 10, edit: { type: 'usd', callback: handleEditHit }},
        { name: 'Add', field: 'id', type: 'button', button: { name: <i className="fas fa-plus"></i>, className: 'btn-primary btn-xs m-0', callback: addItem }, width: 5 },
        { name: '', field: 'id', type: 'actions', multiple: true, width: 5, button: {
            title: 'More Options',
            name: <span className="material-icons">more_vert</span>,
            className: 'p-0 text-muted',
            links: [
                { name: 'Edit Non-Stock part', callback: (item) => openInventoryForm(item.id)},
                ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Move to Service Items', callback: (item) => movePart(item.id, SERVICE_ITEMS.SERVICE_ITEMS.id)}] : [],
                ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Move to Stock Parts', callback: (item) => movePart(item.id, SERVICE_ITEMS.STOCK_PARTS.id)}] : [],
            ]
        }},
    ];
        
    /* Render ----------------------------------------------------------------------------------------------------------------------*/

    return (
        <>
            <ModalForm {...props}
                history={history}
                modal_header={'Add Line Items'}
                save_button_title={'Finished'}
                show_spinner={inventory.inventory_save_pending}
                no_fade={true}
                bodyClassName={'py-3'}
                size={'md'}
                style={1}
                no_padding={ true }
                confirm_button_callback={ finishBuilder }
                visible={ segments[seg-2] != 'add_parts' && segments[seg-2] != 'add_item' }
            >
                <div className="col-12" style={{ height: '100%' }}>

                    { !isPurchaseOrder &&
                        <div className="mb-3" style={{ padding: '12px 20px 5px', backgroundColor: '#eeeeee' }}>
                            { typeId != SERVICE_ITEMS.LABOR.id && typeId != SERVICE_ITEMS.TRAVEL.id &&
                                <button className={`btn btn-primary btn-sm float-right ml-2 mb-2`} onClick={ () => openInventoryForm(null) }>
                                    { (typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) && `+ ${SERVICE_ITEMS.SERVICE_ITEMS.singular}` }
                                    { (typeId == SERVICE_ITEMS.STOCK_PARTS.id) && `+ ${SERVICE_ITEMS.STOCK_PARTS.singular}` }
                                    { (typeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) && `+ ${SERVICE_ITEMS.NON_STOCK_PARTS.singular}` }
                                </button>
                            }
                            <ButtonGroup size="sm" className={'mb-2'} >
                                {filterButtons}
                            </ButtonGroup>
                        </div>
                    }

                    <Table id={'line-items-builder'}
                        ref={tableRef}
                        // table_style={{ minWidth: '600px', overflowX: 'scroll' }}
                        data={data}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        active_id={params.purchase_order_id}
                        active_field={'id'}
                        search_query={state.search}
                        search_callback={handleSearch}
                        second_line={'description'}
                        save_new_callback={props.addItem}
                        columns={columns}
                        filters={((typeId == SERVICE_ITEMS.SERVICE_ITEMS.id || typeId == SERVICE_ITEMS.STOCK_PARTS.id) && !isPurchaseOrder) && {
                            name: 'Category',
                            field: 'categoryIds',
                            limit: 6,
                            buttons: categoryFilters?.map((category) => ({ name: category.name, value: category.id })) ?? [],
                            filter_callback: handleFilter
                        }}
                        group_by={{ fields: ['groupHeading'], direction: ['asc'] }}
                    />
                </div>
            </ModalForm>

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.action == 'add_parts' &&
                <StockPartsForm addItem={ props.addItem } typeId={typeId} source="line-items" appId={ params.appId } />
            }
            { params.action == 'add_item' && selectedItem &&
                <AddItemForm addItem={ props.addItem } item={ selectedItem } typeId={typeId} source="line-items" appId={ params.appId } />
            }
        </>
    
    );
};

export default LineItemsBuilder;
