import React, { useState } from 'react';
import { FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { useSelector } from 'react-redux';
import { email_fields, email_form_layout } from './send-email-form-layout';

var _ = require('lodash');

const SendEmailForm = (props) => {

	/* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const contacts = props.contacts;
	
	const company = useSelector(store => store.company.company);

	const [state, setState] = useState({
		populated: false,
		form_error: null,
		email: email_fields(contacts, props.type, props.id, company), //, serviceAddress, quote, invoice),
		expand: false,
		isLoading: false,
		search: '',
		options: [],
		isEndDateSet: false,
	});

	/* Handlers ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const handleChange = (event) => {
		let form_error = _.filter(state.form_error, (o) => { return o.field !== event.target.name; });
		setState(prev => ({ ...prev, form_error, email: { ...prev.email, [event.target.name]: event.target.value } }));
	};
	const handleMultiSelect = (value, target) => {
		let form_error = _.filter(state.form_error, (o) => { return o.field !== target.name; });
		var values = value;

		setState(prevState => ({ ...prevState, form_error, email: { ...prevState.email, [target.name]: values } }));
	};

	/* Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const submitForm = (email_form_layout) => {
		var form_error = ValidateForm(state.email, email_form_layout);
		setState(prev => ({ ...prev, form_error }));

		if (!form_error.length) {
			props.submit_callback(state.email);
		}
	};

	/* Constants ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	var email_layout = email_form_layout(contacts, state.email);

	return (

		<ModalForm {...props}
			modal_header={'Send Email Attachment'}
			cancel_button_title="Cancel"
			save_button_title={'SEND EMAIL'}
			submitFormHandler={() => submitForm(email_layout)}
			cancel_button_callback={props.cancel_button_callback}
			// show_spinner={quotes.quote_save_pending}
		>
			<FormBuilder
				callbacks={{
					text: handleChange,
					select: handleChange,
					multiselect: handleMultiSelect,
				}}
				form_error={state.form_error}
				form={email_layout}
				record={state.email}
			/>
		</ModalForm>
	);
};

export default SendEmailForm;
