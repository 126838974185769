import React, { Fragment } from 'react';
import _ from 'lodash';
import { toProperCase } from 'components/common/toolbox';

export function receive_payment_fields() {
	return (
		{
			method: '',
			amount: '',
		}
	);
}

export function receive_payment_form_layout(record) {


	const method_options = ['check', 'cash', 'quickbooks', 'stripe', 'credit card'];

	return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Payment Information</h3>
					</Fragment>,
				layout: [{
					grid: (record.method == 'check') ? 'col-6' : 'col-12',
					label: 'Method',
					type: 'select',
					field: 'method',
					valid: ['required'],
					options:
						<Fragment>
							<option value="">Choose...</option>
							{method_options.map((method, index) => <option key={method} value={method.replace(' ', '')}>{toProperCase(method)}</option>)}
						</Fragment>,
				}, {
					grid: 'col-6',
					label: 'Check #',
					field: 'checkNumber',
					type: (record.method == 'check') ? 'text' : 'skip',
					valid: ['required'],
				}, {
					grid: 'col-12',
					label: 'Amount',
					field: 'amount',
					type: 'usd',
					prepend: '$',
					valid: ['required', 'numeric'],
				}],
			},]
		}]
	);
}