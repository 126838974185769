import * as toolbox from 'components/common/toolbox';
import ActivityLog from 'components/activity-log/activity-log';
import AttachmentsTab from 'components/attachments/attachments-tab';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import LineItemsTab from 'components/line-items/line-items-tab/line-items-tab';
import React from 'react';
import { APPS, LOG, PERMISSIONS } from 'components/constants';
import { numPanels } from 'components/common/toolbox';
import { useParams, useRouteMatch, Link, Route } from 'react-router-dom';

export default function WorkOrderTabs(props) {

    const match = useRouteMatch();
    const params = useParams();
    const seg = 11;

    function tab_active(value) {
        return (params.tab === value) ? ' active' : ''; // returns 'active' if value tab is active
    }
    function tab_url(value) {
        return toolbox.modifyPath(props.location.pathname, seg, value, seg);
    }

    return (
        <>
            <Route path={match.path} render={(route_props) =>

                <div className="tabs-container mt-3">
                    <ul className={ `nav nav-tabs underline ${(numPanels(1))?'scroll-tabs':''}` } role="tablist">
                        <li><Link className={'nav-link' + tab_active('items')} to={tab_url('items')} replace={true}> Line Items &nbsp;</Link></li>
                        <li><Link className={'nav-link' + tab_active('external-invoices')} to={tab_url('external-invoices')} replace={true}> External Invoices &nbsp;</Link></li>
                        <li><Link className={'nav-link' + tab_active('attachments')} to={tab_url('attachments')} replace={true}> Attachments &nbsp;</Link></li>
                        <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                            <li><Link className={'nav-link' + tab_active('activity')} to={tab_url('activity')} replace={true}>Activity</Link></li>
                        </Authorize>
                    </ul>

                    <div className="tab-content">
                        <div role="tabpanel" className={'tab-pane' + tab_active('items')}>
                            <div className="panel-body">
                                <Route path={match.path} render={(route_props) =>
                                    <CatchErrors>
                                        <LineItemsTab {...route_props} source={'work-orders'} />
                                    </CatchErrors>
                                } />
                            </div>
                        </div>
						<div role="tabpanel" className={'tab-pane' + tab_active('external-invoices')}>
							<div className="panel-body">
								<Route path={match.path} render={(route_props) =>
									<CatchErrors>
										<AttachmentsTab {...route_props} 
											columnId="column4"
                                            button="+ Ext Invoice"
                                            field="external_invoices"
                                            id={ params.work_order_id } 
                                            prefix="WORK_ORDER" 
                                            singular="work_order" 
                                            source="work-orders" 
                                            store="workOrders" 
                                            title="Ext Invoices"
                                        />
									</CatchErrors>
								} />
							</div>
						</div>
						<div role="tabpanel" className={'tab-pane' + tab_active('attachments')}>
							<div className="panel-body">
								<Route path={match.path} render={(route_props) =>
									<CatchErrors>
										<AttachmentsTab {...route_props} 
											columnId="column4"
                                            id={ params.work_order_id } 
                                            prefix="WORK_ORDER"
                                            singular="work_order" 
                                            source="work-orders" 
                                            store="workOrders" 
                                        />
									</CatchErrors>
								} />
							</div>
						</div>
                        <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                            <div role="tabpanel" className={'tab-pane' + tab_active('activity')}>
                                <div className="panel-body">
                                    <Route path={props.match.path} render={(route_props) =>
                                        <CatchErrors>
                                            <ActivityLog {...route_props} 
                                                id="work_order_id" 
                                                prefix="WORK_ORDER" 
                                                singular="work_order"
                                                store="workOrders" 
                                                type={ `${LOG.WORK_ORDER.key}` } 
                                            />
                                        </CatchErrors>
                                    } />
                                </div>
                            </div>
                        </Authorize>
                    </div>
                </div>

            } />
        </>
    );
};

